import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import BlogEditView from "../../../components/Blog";
import {editPost, addMultimedias, deleteMultimedia, deletePostById, getPostById} from "services/services";
import { BLOG } from "../../../navigation/CONSTANTS";
import { humanizeProps } from "helpers/humanizeResponse";

export function BlogEditContainer({ location }) {
  const { id, lang } = useParams();
  const [post, setPost] = useState({});
  const [message, setMessage] = useState({text: location.state?.message ? location.state.message : ""});

  const history = useHistory();

  useEffect(() => {
    if (id) fetchPost(id, lang);
  }, [id]);

  function fetchPost(postId, postLang) {
    getPostById(postId, postLang)
      .then((data) => {
        if (!data) return history.push(BLOG);
        setPost(data);
      })
      .catch(error => {
        console.warn(error);
        history.push(BLOG);
      });
  }

  function savePost(article) {
    if (article?.id) {
      editPost(article)
        .then(() => {
            let msg = "Datos procesados.";
            if(article.newMultimedia){
              msg = msg + " Subiendo imagen...";
              saveImage(article.newMultimedia);
            }
            setMessage({ text: msg });
        })
        .catch((err) =>
          setMessage({
            text: humanizeProps(err?.message ? err?.message : err.response?.message ? err.response?.message : undefined),
            msgType: 'msgError' })
        );
    }
  }

  function saveImage(files) {
    const imgPostTypes = ["IMG", "IMG_POS_CROP"];
    let replace = {};
    //Check if multimedia needs to be replaced
    if (post.multimedia.length > 0) {
      replace[imgPostTypes[1]] = post.multimedia.find((pic) => pic.type.code===imgPostTypes[1]);
      replace[imgPostTypes[0]] = post.multimedia.find((pic) => pic.type.code===imgPostTypes[0]);
    }
    let owners = [{ owner: id, ownerType: "pos" }];

    const formData = new FormData();
    const metadatas = [];
    if (files && id) {
      files.forEach((fileObject, i) => {
        const fileName = fileObject.name;
        formData.append(fileName, fileObject);
        let oMultimedia = {
          name: fileName,
          type: imgPostTypes[i],
          owners: owners,
        };
        if (replace[imgPostTypes[i]]) {
          oMultimedia.replace = replace[imgPostTypes[i]].id;
        }
        metadatas.push(oMultimedia);
      });
      formData.append("metadata", JSON.stringify(metadatas));

      addMultimedias(formData)
        .then(() => {
            setMessage({ text: "Datos e imagen guardados exitosamente" });
            fetchPost(id, lang);
        })
        .catch((err)=>
          setMessage({
            text: "Error al enviar archivos. Intente luego por favor. "
              + humanizeProps(err.response?.data?.message),
            msgType: 'msgError'
          })
        );
    }
  }

  function deleteBlog(article) {
    if (lang=== "es" && article.otherLanguages.length > 0) {
      setMessage({ text: "Elimine primero los idiomas asociados antes de eliminar este. ", msgType: 'msgError' });
    } else if (article.id) {
      deletePostById(article.id)
        .then(() => {
            deleteImages();
            history.push("/blog");
        })
        .catch((err) =>
          setMessage({
            text: humanizeProps(err.response?.data?.message),
            msgType: 'msgError'
          })
        );
    }
  }

  function deleteImages() {
   if (post.multimedia.length > 0) {
     let mediaDelete = post.multimedia.map(media => {
       return { "multimedia_id": media.id, "owner": id, "ownerType": "POS" };
       //TODO: agregar grupo
     });

     deleteMultimedia(mediaDelete)
        .then()
        .catch(console.warn);
    }
  }

  function handleBackBlog() {
    history.push("/blog");
  }

  return (
    <div style={{ padding: 20 }}>
      <BlogEditView
        post={post}
        savePost={savePost}
        handleBackBlog={handleBackBlog}
        saveImage={saveImage}
        message={message}
        deleteBlog={deleteBlog}
        lang={lang}
      />
    </div>
  );
}
