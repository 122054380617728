import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  paper: {
  display: "flex"
  },
  col1: {
    width: "70%"
  },
  col2: {
    width: "30%"
  },
  box: {
    border: "1px solid #f3f3f3",
    borderRadius: "5px",
    padding: "5px",
    position: "relative",
    backgroundColor: "#fff"
  },
  boxMedia: {
    display: "flex",
    fontSize: ".8em"
  },
  active: {
    borderRadius: "5px",
    padding: "5px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    marginRight: "5px",
    color: "#55bee7"
  },
  mediaItem: {
    borderRadius: "5px",
    padding: "5px",
    cursor: "pointer",
    marginRight: "5px",
    color: "#c5c5c5"
  },
  item: {
    padding: "5px",
    margin: "0 5px 5px 0",
    borderRadius: "5px",
    backgroundColor: "rgba(0, 0, 0, 0.09)",
    //color: "#155724",
    minHeight: "1em",
  },
  itemTitle: {
    width: "80%"
  },
  itemDesc: {
    minHeight: "3em",
  },
  btnEdit: {
    position: "absolute",
    right: 0,
    fontSize: ".5em",
    width: "32px",
    height: "32px",
    cursor: "pointer",
    "& :hover": {
      color: "#e7c26d"
    }
  }
});
