import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import Button from "../Button";
import Modal from '@material-ui/core/Modal';
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Audiotrack from "@material-ui/icons/Audiotrack";
import { addOneMultimedia } from "services/services";

export function PrayerMultimediaStyled(props) {
  const {
    prayerMultimedia,
    handleMultimediaClose,
    handlerPrepareMultimedia,
  } = props;
  const [multimedia, setMultimedia] = useState({});
  const [others_image, setOtherImg] = useState({});
  const [others_audio, setOtherAud] = useState({});

  useEffect(() => {
    setMultimedia(prayerMultimedia);
  }, [prayerMultimedia]);
  const classes = useStyles();

  const handleSave = () => {
    let ownerTypeCode = 'RPY'; // prayer
    if (prayerMultimedia.stepType === 'mystery') ownerTypeCode = 'MYS';
    if (prayerMultimedia.stepType === 'group') ownerTypeCode = 'MYG';

    if (multimedia.image?.newFile?.name) {
      const formImage = makeForm('IMG');
      addOneMultimedia(formImage)
        .then((id_image)=> {
          const key = `${prayerMultimedia.stepType}:${prayerMultimedia.step}:${prayerMultimedia.index}`;
          let prayOld = others_image[key] || []
          let prayActual =[ ...prayOld, prayerMultimedia.image?.id, id_image].filter(Boolean)
          setOtherImg({ ...others_image, [key]:prayActual })
          handlerPrepareMultimedia({ 
            ...prayerMultimedia, 
            multimedia_id: id_image,
            oldMultimedia: prayActual.slice(0, prayActual.length-1),
            ownerType: ownerTypeCode,
            m: { file: multimedia.image.newFile, _type: 'IMG' },
          })
        })
    }

    if (multimedia.audio?.newFile?.name) {
      const formImage = makeForm('AUD');
      addOneMultimedia(formImage)
        .then((id_audio)=> {
          const key = `${prayerMultimedia.stepType}:${prayerMultimedia.step}:${prayerMultimedia.index}`;
          let prayOld = others_audio[key] || []
          let prayActual = [...prayOld, prayerMultimedia.audio?.id, id_audio].filter(Boolean)
          setOtherAud({ ...others_audio, [key]: prayActual })
          handlerPrepareMultimedia({ 
            ...prayerMultimedia, 
            multimedia_id: id_audio,
            oldMultimedia: prayActual.slice(0, prayActual.length-1),
            ownerType: ownerTypeCode,
            m: { file: multimedia.audio.newFile, _type: 'AUD' },
          });
          
        })
    }

  }

  const makeForm = (type) => {
    const prop = type === 'AUD' ? 'audio' : 'image';
    const form = new FormData();
    const fileName = multimedia[prop].newFile.file.name;
    form.append('name', fileName);
    form.append('type', type);
    form.append(fileName, multimedia[prop].newFile.file);
    return form;
  }

  const handleAudioChange = (event) => {
    let file = event.target.files[0];
      if (file) {
        let reader = new FileReader();
        reader.onload = function () {
          const newFile = {
            //owner: owner,
            name: file.name,
            file: file,
            type: "AUD",
          };

          setMultimedia({ ...multimedia, audio: { ...multimedia.audio, original_name: file.name, newFile } });
        };
        reader.readAsArrayBuffer(file);
      }
  }

  const handleImageChange = (event) => {
      if (event.target.files[0]) {
        let reader = new FileReader();
        reader.onload = function () {
          const url = URL.createObjectURL(event.target.files[0]);
          const newFile = {
            name: url,
            file: event.target.files[0],
            type: "IMG",
          };

          setMultimedia({ ...multimedia, image: { ...multimedia.image, saved_name: url, newFile } });
        };
        reader.readAsDataURL(event.target.files[0]);
      }
  }

  return (
    <>
      <Modal
        open={prayerMultimedia.open}
        onClose={handleMultimediaClose}
        aria-labelledby="prayer-multimedia-title"
        aria-describedby="prayer-multimedia-description"
      >
        <div className={classes.paper}>
          <div className={classes.col1}>
            <input
              accept="audio/mpeg"
              className={classes.hide}
              id="multimedia-prayer-audio"
              type="file"
              onChange={(event) =>
                handleAudioChange(event)
              }
            />
            <label htmlFor="multimedia-prayer-audio">
              <Button
                variant="contained"
                color="default"
                component="span"
                size="small"
                css={classes.btnUpload}
                startIcon={<Audiotrack />}
              >
                Seleccionar Audio
              </Button>
            </label>
            <div>{multimedia?.audio?.original_name || "Ningún elemento de audio seleccionado."}</div>
          </div>
          <div className={classes.col2}>
            <input
              accept=".png, .jpg, .jpeg, .svg"
              className={classes.hide}
              id="multimedia-prayer-image"
              type="file"
              onChange={(event) =>
                handleImageChange(event)
              }
            />
            <label htmlFor="multimedia-prayer-image">
              <Button
                variant="contained"
                color="default"
                component="span"
                size="small"
                css={classes.btnUpload}
                startIcon={<PhotoCamera />}
              >
                Seleccionar imagen
              </Button>
            </label>
            <div className={classes.wrapImage}>{multimedia?.image?.original_name || "Ninguna imagen seleccionada."}
              <br/>
              <img src={multimedia?.image?.saved_name}/>
            </div>
          </div>
          <div className={classes.col3}>
            <Button
              text={"Guardar"}
              type="primary"
              data-testid="btn-prayer-multimedia-save"
              onClick={handleSave}
            />
            <Button
              text={"Cerrar"}
              type="secondary"
              data-testid="btn-prayer-multimedia-close"
              onClick={handleMultimediaClose}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
