import React, { useState } from "react";
import MaterialTable from "material-table";
import Button from "../../components/Button";
import { useStyles } from "./styles";
import {banUser, getUsers, unbanUser} from "../../services/services";
import classnames from "classnames";
import { Modal, Paper } from "@material-ui/core";
import tableIcons from "components/TableIcons";
import {Link} from "react-router-dom";

const TranslatorView = () => {

  const classes = useStyles();
  const [open, setOpenModal] = useState(false);
  const [modalMessage, setMessage] = useState("");
  const [userToChange, setUserChange] = useState({});
  const [filter, setFilter] = useState({});
  const [role, setRole] = useState(2);
  const tableRef = React.createRef();

  const openModal = (message) => {
    setMessage(message);
    setOpenModal(true);
  };

  const closeModal = () => {
    setMessage("");
    setOpenModal(false);
    setUserChange({});
  };

  const data = (query) =>
    new Promise((resolve) => {
      let first_name, last_name, email;
      if (query.filters.length > 0) {
        first_name = query.filters.find(
          (field) => field.column.field === "person.first_name"
        );
        last_name = query.filters.find(
          (field) => field.column.field === "person.last_name"
        );
        email = query.filters.find((field) => field.column.field === "email");
      }
      getUsers(query.pageSize, query.page + 1, {
        first_name: first_name?.value,
        last_name: last_name?.value,
        email: email?.value,
        id_rol: role,
      })
        .then((result) => {
          setFilter({
            first_name: first_name?.value,
            last_name: last_name?.value,
            email: email?.value
          });
          resolve({
            data: renderUsers(result.data),
            page: result.current_page - 1,
            totalCount: result.total,
          });
        });
    });
    const changeRol = () => {
        let rol = role === 2 ? 3 : 2;
        setRole(rol);
        tableRef.current && tableRef.current.onQueryChange();
    }
  function renderUsers(arrUsers) {
    arrUsers.forEach((user, inx) => {
      user.actions = user.deleted_at ? (
        <Button
          key={inx}
          variant="contained"
          color="default"
          css={classnames(classes.btnSmall, classes.btnEdit)}
          onClick={() => {
            setUserChange({ user, action: "unban" });
            openModal(
              `¿Habilitar nuevamente a ${user.person.first_name} ${user.person.last_name}?`
            );
          }}
        >
          Rehabilitar
        </Button>
      ) : (
        <Button
          key={inx}
          variant="contained"
          color="secondary"
          css={classnames(classes.btnSmall, classes.btnEdit)}
          onClick={() => {
            setUserChange({ user, action: "ban" });
            openModal(
              `¿Suspender cuenta a ${user.person.first_name} ${user.person.last_name}?`
            );
          }}
        >
          Suspender
        </Button>
      );
    });
    return arrUsers;
  }

  function onConfirm() {
    if (userToChange.user.id) {
      let action = [];
      if (userToChange.action === "ban") {
        action.push(banUser(userToChange.user.id));
      } else {
        action.push(unbanUser(userToChange.user.id));
      }
      Promise.all(action).then(() => {
        tableRef.current && tableRef.current.onQueryChange();
        closeModal();
      }).catch((err) => {
        console.log(err)
      })
    } else {
      closeModal();
    }
  }

  return (
    <div>
        <div
            style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "10px 20px",
            }}
        >
            <Link to="/useradd" style={{
                padding: "0.75em 1em",
                borderRadius: "0.375em",
                backgroundColor: "#e0e0e0",
                color: "rgba(0, 0, 0, 0.87)",
                textDecoration: "none",
                fontSize: ".9em"
            }}>Nuevo</Link>

            <Button style={{marginLeft: '5px'}} onClick={changeRol}>Ver {role === 2 ? 'Administradores' : 'Traductores' }</Button>
        </div>

        <MaterialTable
          title={role === 2 ? 'Traductores' : 'Administradores' }
          icons={tableIcons}
          columns={[
            { title: "Id", field: "id", filtering: false, width: "10%"},
            { title: "Nombre", field: "person.first_name", defaultFilter: filter?.first_name },
            { title: "Apellido", field: "person.last_name", defaultFilter: filter?.last_name },
            { title: "Email", field: "email", defaultFilter: filter?.email },
            {
              title: "Acciones",
              field: "actions",
              filtering: false,
              render: (rowData) => <div>{rowData.actions}</div>,
            },
          ]}
          data={data}
          tableRef={tableRef}
          options={{
            search: false,
            actionsColumnIndex: -1,
            filtering: true,
            sorting: false,
            pageSize: 50,
            headerStyle: {
              backgroundColor: "#596386",
              color: "#FFF",
              padding: "5px 10px",
            },
            cellStyle: {
              fontSize: ".9em",
              padding: "5px 10px",
            },
            filterCellStyle: { padding: "2px" },
          }}
        />
      <Modal open={open} onClose={closeModal}>
        <Paper className={classnames(classes.paper, classes.confirmation)}>
          <p>{modalMessage}</p>
          <div className={classes.btnRow}>
            <Button variant="contained" color="default" onClick={closeModal}>
              Cancelar
            </Button>
            <Button variant="contained" color="secondary" onClick={onConfirm}>
              Confirmar
            </Button>
          </div>
        </Paper>
      </Modal>
    </div>
  );
};

export default TranslatorView;
