import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Search from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Clear from "@material-ui/icons/Clear";
import { useStyles } from "./styles";
import { ROSARY_ADD } from "../../navigation/CONSTANTS";


const RosaryView = (props) => {
  const { rosaries, message } = props;

  const tableIcons = {
    Edit: EditIcon,
    Delete: DeleteIcon,
    SortArrow: KeyboardArrowUpIcon,
    Add: AddCircleIcon,
    PreviousPage: ChevronLeft,
    NextPage: ChevronRight,
    Search: Search,
    LastPage: LastPage,
    FirstPage: FirstPage,
    Filter: FilterList,
    Clear: Clear,
  };

  const [error, setError] = useState({ message });

  const classes = useStyles();

  useEffect(() => {
    if (message) {
      setError(message);
    }
  }, [message]);
  return (
    <div>
      <div data-testid="rosary_list_view"
           style={{
             display: "flex",
             justifyContent: "flex-end",
             padding: "10px 20px",
           }}
      >
        <a
          href={`${ROSARY_ADD}/es`}
          data-testid="btn_add_rosary"
          style={{
            padding: "0.75em 1em",
            borderRadius: "0.375em",
            backgroundColor: "#e0e0e0",
            color: "rgba(0, 0, 0, 0.87)",
            textDecoration: "none",
            fontSize: ".9em"
          }}
        >
          Nuevo
        </a>

      </div>
      <div>
        {error?.text && (
          <div className={`${classes.messageBox} ${classes[error.msgType]}`}>
            {error.text}
          </div>
        )}
        <MaterialTable
          title="ROSARIOS"
          icons={tableIcons}
          columns={[
            { title: "Id", field: "id", hidden: true },
            { title: "TITULO", field: "title" },
            { title: "MOSTRAR", field: "show_date", width: "6%" },
            {
              title: "LENGUAJES",
              field: "langs",
              filtering: false,
              render: (rowData) => <div>{rowData.langs}</div>,
            },
          ]}
          data={rosaries}
          options={{
            search: false,
            actionsColumnIndex: -1,
            filtering: true,
            pageSize: 20,
            headerStyle: {
              backgroundColor: "#596386",
              color: "#FFF",
              padding: "5px 10px",
            },
            cellStyle: {
              fontSize: ".8em",
              padding: "5px 10px",
            },
            filterCellStyle: { padding: "2px" },
          }}
          localization={{
            header: {
              actions: "Acciones",
            },
          }}
        />
      </div>
    </div>
  );
};

export default RosaryView;
