import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import Grid from "@material-ui/core/Grid";
import SchoolCard from "../../components/SchoolCard";
import SearchCampaign from "../../components/SearchCampaign";
import Select from "components/Select";
import Button from "../../components/Button";

const SchoolView = (props) => {
  const {
    message,
    sections,
    langs,
    selectedLang,
    handleLangChange,
    handleItemSelection,
    handlerSave,
    deleteCampaign
  } = props;
  const [error, setError] = useState({ message });
  const [search, setSearch] = useState({ open: false, lang: "es" });
  const sectionName = {
    es: [
      "Home",
      "Propuestas de oración",
      "Profundizar la oración",
      "Click To Pray eRosary",
    ],
    en: ["Home", "Prayers proposal", "Deepen your prayer", "eRosaries"],
    fr: [
      "Home",
      "Propositions de prière",
      "Pour prier en profondeur",
      "eRosaries",
    ],
    it: ["Home", "Proposte di preghiera", "Per pregare in profondità", "eRosaries"],
    de: ["Home", "Gebetsvorschläge", "Vertiefe deine Gebete", "eRosaries"],
    zh: ["Home", "祈禱文建議", "加深您的祈禱", "eRosaries"],
    pt: ["Home", "Propostas de oração", "Aprofundar a oração", "eRosaries"],
  };
  const classes = useStyles();

  useEffect(() => {
    if (message) {
      setError(message);
    }
  }, [message]);

  const searchCampaign = (section, order) => {
    setSearch({
      open: true,
      lang: selectedLang,
      section: section,
      order: order,
    });
  };

  const handleSearchClose = () => {
    setSearch({
      open: false,
      lang: selectedLang,
      section: undefined,
      order: undefined,
    });
  };

  const selectItem = (item) => {
    handleItemSelection(
      { ...item, order: search.order, is_hidden: false },
      search.section,
      search.order
    );
    setSearch({
      open: false,
      lang: selectedLang,
      section: undefined,
      order: undefined,
    });
  };

  const section = sections.map((section, idx) => {
    return (
      <div key={`section${ idx }`} className={classes.sectionBox}>
        {idx === 1 && (<h2>Tab: Escuela de oración</h2>)}
        <h3>{sectionName[selectedLang][idx]}</h3>
        <Grid key={idx} container spacing={2}>
          {section.map((card, ind) => {
            return (
              <Grid item xs={2} key={ind}>
                {card?.id && card?.is_hidden === false ? (
                  <SchoolCard
                    card={card}
                    order={card.order}
                    sectionIdx={idx}
                    searchCampaign={searchCampaign}
                    deleteCampaign={deleteCampaign}
                  />
                ) : (
                  <SchoolCard
                    order={ind}
                    sectionIdx={idx}
                    searchCampaign={searchCampaign}
                  />
                )}
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  });
  return (
    <div data-testid="school_view" className={classes.viewBox}>
      {error?.text && (
        <div className={`${classes.messageBox} ${classes[error.msgType]}`}>
          {error.text}
        </div>
      )}
      <Grid container>
        <Grid item xs={10}>
          <Select
            items={langs}
            handlerOnChange={handleLangChange}
            defaultValue={selectedLang}
          />
        </Grid>
        <Grid item xs={2}>
          <Button text="Guardar" type="primary" onClick={handlerSave} />
        </Grid>
      </Grid>
      {section}
      <SearchCampaign
        search={search}
        handleSearchClose={handleSearchClose}
        selectItem={selectItem}
      />
    </div>
  );
};

export default SchoolView;
