import _ from "lodash";
import moment from "moment";

export const addedRequireDates = async (arrayToApi, { year, month, day }) => {
  month = moment(month, 'MM').format('MM'); //2 digits format
  day = day.toString().length < 2 ? '0' + day.toString() : day.toString(); //2 digits format
  const arrIntentions = _.filter(arrayToApi, ["entity", "intention"]);
  const arrPrayers = _.filter(arrayToApi, ["entity", "prayer"]);
  const arrAttitudes = _.filter(arrayToApi, ["entity", "attitude"]);
  const arrBishop = _.filter(arrayToApi, ["entity", "bishop"]);
  const date = moment(`${year}-${month}-${day}`);
  arrIntentions.forEach((intention) => {
    if (!intention.date_from)
      intention.date_from = date.clone().startOf("month").format("YYYY-MM-DD");
    if (!intention.date_to)
      intention.date_to = date.clone().endOf("month").format("YYYY-MM-DD");
  });

  arrPrayers.forEach((prayer) => {
    if (["mon", "off"].includes(prayer.type))
      prayer.show_date = date.clone().startOf("month").toISOString();

    if (["mor", "aft", "eve"].includes(prayer.type))
      prayer.show_date = `${year}-${month}-${day}T00:00:00.000Z`;
  });

  arrAttitudes.forEach((attitude) => {
    attitude.intention = attitude.id_intention;
  });

  arrPrayers.forEach((prayer) => {
    if (["mon", "mor", "aft", "eve"].includes(prayer.type))
      prayer.intention = prayer.id_intention;
  });

  arrBishop.forEach((conference) => {
    conference.date_from =
      conference.date_from ||
      date.clone().startOf("month").format("YYYY-MM-DD");
    conference.date_to =
      conference.date_to || date.clone().endOf("month").format("YYYY-MM-DD");
    conference.country = conference.country?.id;
  });
};
