import React, { useState, useEffect } from "react";
import _ from "lodash";
import 'moment/locale/es';
import moment from "moment";
import { Grid, Chip, TextField } from "@material-ui/core";
import Button from "components/Button";
import { useStyles } from "./styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Select from "components/Select";
import Table from "components/Table";
import LANG from "global/lang";
import { convertDataToObjects } from "helpers/convertTable";
import ColumnSelectorStyled from "../ColumnSelector";
import PrayerAudioStyled from "../PrayerAudio/PrayerAudioStyled";

export function DailyPrayerExcelStyled(props) {
  const {
    css,
    date,
    prayers,
    intentions,
    handlerChangeDate,
    onUpdateTable,
    messageOK = '',
    userLangs = [],
    isAdmin,
    onSaveButton
  } = props;

  const classes = useStyles();
  moment.locale('es');
  const [DATE, setDate] = useState(checkDay(date));
  const [maxDays, setMaxDays] = useState(getMaxDays(date));
  const [notPrevDays, setPrevDays] = useState(getPrevDay(date));
  const [notNextDays, setNextDays] = useState(getNextDay(date));
  const [errorSubmit, setErrorSubmit] = useState({ isValid: true });
  const [columnVisible, setColumnVisible] = useState(
    new Array(LANG.length).fill(true)
  );

  useEffect(()=> {
    setErrorSubmit( prev=> ({ ...prev, isValid: true, text: messageOK.split('$')[0] }))
  }, [messageOK])

  const columnas = [
    {
      title: "Item",
      field: "row_name",
      width: "5%",
      editable: "never"
    },
  ].concat(
    LANG.map((l, i) => ({
      title: l.display,
      field: String(l.code),
      hidden: !columnVisible[i],
      editComponent: (p) => (
        <TextField
          label={intentions[1].id[l.code] ? l.display
            : `Cree una Intención para "${l.display}" para editar este campo.`}
          fullWidth
          multiline
          disabled={!(intentions[1].id[l.code]) || (!isAdmin && !userLangs.includes(l.code.toUpperCase())) || (!isAdmin && (intentions[0].data?.[l.code] && intentions[0].data[l.code].status !== "draft")) }
          rows={20}
          defaultValue={p.value || ""}
          onChange={(e) => p.onChange(e.target.value)}
        />
      ),
    }))
  );

  function errorSubmitVisto() {
    if (!errorSubmit.isValid) setErrorSubmit({ isValid: true });
  }

  function getMaxDays(fecha) {
    return moment(moment(fecha).format("YYYY-MM"), "YYYY-MM").daysInMonth();
  }

  function getPrevDay(selectedDate) {
    const day = moment(selectedDate).format("DD");
    return parseInt(day) === 1;
  }

  function getNextDay(selectedDate) {
    const day = moment(selectedDate).format("DD");
    return parseInt(day) === maxDays;
  }

  function betterDate(str) {
    const [yy, mm, dd] = str.split('-')
    return `${yy} - ${_.upperFirst(mm)} - ${dd}`
  }

  function checkDay(dateCheck) {
    return dateCheck !== undefined ? moment(dateCheck) : moment().startOf("month");
  }

  function handlerChangeDay(n) {
    const selectedDate = moment(date);
    const dateToSearch =
      typeof n === "number" ? selectedDate.clone().add(n, "day") : selectedDate.clone().date(n);
    const year = moment(dateToSearch).format("YYYY"),
      month = moment(dateToSearch).format("MM"),
      day = moment(dateToSearch).format("DD");
    handlerChangeDate(year, month, day);
    setDate(dateToSearch);
    setMaxDays(getMaxDays(dateToSearch));

    setPrevDays(getPrevDay(dateToSearch));
    setNextDays(getNextDay(dateToSearch));
    setErrorSubmit({ isValid: true, text: '' });
  }

  async function validate(arrPrayers) {
    const idsIntentions = _.transform(intentions[1].data, (r,v,k)=> r[k] = v.id, {});
    arrPrayers.forEach(
      (prayer) => (prayer.id_intention = idsIntentions[prayer.language])
    );
    let isValid = true;
    const orphanPrayer = _.find(arrPrayers, { id_intention: undefined });
    const emptyPrayer = _.filter(arrPrayers, function(p) {
      return p.id && p.description === "";
    });
    let textWarning = "";
    if (orphanPrayer) {
      const lang = _.find(LANG, { code: orphanPrayer.language });
      textWarning = `Las oraciones para "${lang.display}" no se guardarán ya que no existe una Intencion para ello. 
          Primero guarde una Intención para "${lang.display}" en la primera tabla.`;
    }

    if (emptyPrayer.length > 0) {
      let notSave = _.map(emptyPrayer, "language");
      textWarning += `Las oraciones para "${notSave.join(", ").toUpperCase()}" no se guardarán porque no pueden quedar vacías`;
    }

    if (orphanPrayer || emptyPrayer) {
      setErrorSubmit({
        isValid: false,
        title: "Not exist Intention",
        text: textWarning,
      });
    } else {
      setErrorSubmit({ isValid: true, text: '' })
    }

    return isValid;
  }

  async function prepareToUpdate(tableName, newData, oldData = []) {
    await validate(await convertDataToObjects(newData));

    onUpdateTable(tableName, newData, oldData);
  }

  useEffect(() => {
    setMaxDays(getMaxDays(date));
    setDate(checkDay(date));
    setPrevDays(getPrevDay(date))
  }, [date]);
  return (
    <Grid container data-testid="daily-prayer-excel">
      <Grid container item spacing={0}>
        <Grid item xs={6} lg={2}>
          <Button
              data-testid="btn_prev_day"
              css={classes.button}
              disabled={notPrevDays}
              iconLeft={<ArrowBackIosIcon/>}
              onClick={() => handlerChangeDay(-1)}
              size="small"
          />
          <Select
            items={new Array(maxDays).fill(1).map((dd, i) => ({
              value: String(dd + i),
              display: dd + i,
            }))}
            defaultValue={parseInt(DATE.format("DD"))}
            handlerOnChange={(e) => handlerChangeDay(String(e.target.value))}
          />
          <Button
            data-testid="btn_next_day"
            css={classes.button}
            disabled={notNextDays}
            iconRight={<ArrowForwardIosIcon />}
            onClick={() => handlerChangeDay(1)}
            size="small"
            text={notNextDays}
          />
        </Grid>
        <Grid container item xs={6} alignItems="center">
          <Chip
            className={classes.rootChip}
            label={betterDate(DATE.format("YYYY-MMMM-DD"))}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div onClick={errorSubmitVisto}>
          <ColumnSelectorStyled handlerOnChange={setColumnVisible}/>
          <Table
            css={css}
            onUpdateTable={prepareToUpdate}
            onUpdateTableName="prayerTable"
            columnSchema={columnas}
            data={prayers}
          />
          <Grid
            container
            justify="flex-end"
            style={{color: errorSubmit.isValid ? "green": "red", marginTop: "1em"}}
          >
            {errorSubmit.text}
          </Grid>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Button
          text="Guardar"
          type="primary"
          css={classes.buttonSave}
          onClick={onSaveButton}
        />
      </Grid>
      <Grid item xs={12}>
        <PrayerAudioStyled prayersData={prayers} updatePrayers={() => handlerChangeDay(moment(date).format("DD"))}/>
      </Grid>
    </Grid>
  );
}
