import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  container: (props) => {
    let style = {
      backgroundColor: "#f4f6f8",
      boxShadow: "rgba(0, 0, 0, 0.05) -4px -4px 10px, rgba(0, 0, 0, 0.1) 4px 4px 10px",
      padding: ".2em 1em ",
      display: "flex",
      "& >div": {
        display: "flex",
        alignItems: "left",
        justifyContent: "flex-start",
        "& p": {
          fontFamily: "Nunito Sans",
          fontSize: "1em",
          lineHeight: "1.5em",
          margin: "0 0 0 0 ",
          cursor: "pointer",
        },
        "& nav": {
          marginLeft: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          "& ul": {
            padding: 0,
            listStyleType: "none",

            "& li": {
              marginRight: "1.5em",
              float: "left",
              "& a": {
                fontFamily: "Nunito Sans",
                color: "#444c67",
                textDecoration: "none",
              },
              "& a:hover": {
                color: "#e7c26d",
              },
            },
          },
        },
      },
    };
    if (props.mediaQuerySm) {
      style.padding = "1.18em 2em";
    }
    return style;
  },
  button: {
    marginRight: "1.5em",
  },
  select: {
    "& button": {
      padding: "0.75em 0px ",
      "& div": {
        color: "white",
      },
    },
  },
  options: {
    "& button": {
      "& span": {
        color: "#444C67",
      },
      "& img": {
        width: "15px",
        height: "15px",
        marginRight: "10px",
      },
    },
  },
  mode: (props) => {
    switch (props.mode) {
      case "dark":
        return {
          "& div:nth-child(2)": {
            "& p": {
              color: "#FFFFFF",
            },
            "& nav": {
              "& ul": {
                "& li": {
                  "& a": {
                    color: "#FFFFFF",
                  },
                },
              },
            },
          },
        };
      case "light":
        return {
          "& div:nth-child(2)": {
            "& p": {
              color: "#444C67",
            },
            "& nav": {
              "& ul": {
                "& li": {
                  "& a": {
                    color: "#444C67",
                  },
                },
              },
            },
          },
        };
    }
  },
  contentMenu: {
    justifyContent: "center",
  },
  contentHeaderMenu: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0.625em 1.25em",
  },
  contentButton: {
    textAlign: "center",
    padding: "0.625em 1.25em",
    boxShadow: "0px -1px 0px #E7ECF0",
  },
  buttonStyle: {
    width: "100%",
    padding: "1em 0",
  },
  contentSumary: {
    flexBasis: "100%",
    "& p": {
      fontFamily: "Nunito Sans",
      fontWeight: 600,
      fontSize: "1em",
      lineHeight: "1.5em",
      textTransform: "none",
      color: "rgb(68, 76, 103)",
    },
  },
  logo: {
    marginTop: "8px"
  },
  avatar:{
    display:'flex',
    alignItems:'center',
    '&>p':{
      margin:'0px 10px 0px 0px !important'
    },
    cursor:'pointer'
  }
});
