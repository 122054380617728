import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  box: {
    border: "1px solid #f3f3f3",
    borderRadius: "10px",
    padding: "5px",
    position: "relative",
    backgroundColor: "#6385B1",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "90%",
    paddingBottom: "10px"
  },
  item: {
    padding: "5px",
    margin: "3px",
    borderRadius: "5px",
    backgroundColor: "#fff",
    //color: "#155724",
    minHeight: "10em",
    fontSize: ".8em",
    color: "#444C67",
    "& h4": {
      textAlign: "center",

    },
    "& p": {
      margin: 0
    },
    "& span": {
      fontSize: ".9em",
      color: "#333",
    }
  },
  btnActions: {
    width: "100%",
    textAlign: "center",
  },
  btnEdit: {
    fontSize: "1.5em",
    color: "#fff",
    cursor: "pointer",
    marginRight: "10px",
    "& :hover": {
      color: "#e7c26d"
    },
  }
});
