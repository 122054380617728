import React from "react";
import Grid from '@material-ui/core/Grid'
import { useStyles } from "./styles";
import Select from "components/Select";
import Button from "components/Button";
import Validator from "components/Validator";
import DailyPrayerExcel from "components/DailyPrayerExcel";
import BishopExcel from "components/BishopExcel";
import IntentionExcel from "components/IntentionExcel";
import Multimedia from "components/Multimedia";
import PopeVideoStyled from "../../components/PopeVideo";
import Footer from "../../components/Footer";

export default function DashboardView(props) {
  const {
    displayMonths,
    year,
    month,
    setYear,
    setMonth,
    intentions,
    intentionPrayers,
    prayers,
    bishop,
    prayersDate: prayers_date,
    attitudes,
    handlerButtonFilter,
    handlerPrayers,
    onUpdateTable,
    onSaveButton,
    sections,
    countries,
    avalibleCountries,
    handleSubmitMultimedia,
    intentionMultimedias,
    mgeIntentionOK,
    mgePrayersOk,
    mgeBishopOK,
    handleValidate,
    mgeValidate,
    closeMessage,
    userRole,
    userLangs,
    popeVideos,
    onChangeVideo,
    onSaveVideos,
    mgePopeVideo,
    mgeMultimedia
  } = props;

  const classes = useStyles();
  const thisYear = parseInt(year);
  const thisMonth = parseInt(month);
  const isAdmin = userRole === "ADMIN";
  const Contents = {
    intention: (
      <Grid container>
        <IntentionExcel
          year={year}
          month={month}
          intentions={intentions}
          intentionPrayers={intentionPrayers}
          attitudes={attitudes}
          //updateEntity={updateEntity}
          onUpdateTable={onUpdateTable}
          messageOK={mgeIntentionOK}
          userLangs={userLangs}
          isAdmin={isAdmin}
        />
        <Grid item container xs={12} justify="flex-end">
          <Button
            text="Guardar"
            type="primary"
            css={classes.button}
            onClick={onSaveButton}
          />
        </Grid>
      </Grid>
    ),

    prayers: (
      <Grid container>
        <DailyPrayerExcel
          date={prayers_date}
          prayers={prayers}
          intentions={intentions}
          handlerChangeDate={handlerPrayers}
          onUpdateTable={onUpdateTable}
          messageOK={mgePrayersOk}
          userLangs={userLangs}
          isAdmin={isAdmin}
          onSaveButton={onSaveButton}
        />
      </Grid>
    ),

    bishop: (
      <Grid container spacing={0}>
        <BishopExcel
          countries={countries}
          avalibleCountries={avalibleCountries}
          bishop={bishop}
          onUpdateTable={onUpdateTable}
          messageOK={mgeBishopOK}
          isAdmin={isAdmin}
        />
        <Grid item xs={12}>
          {isAdmin && (
            <Button
              text="Guardar"
              type="primary"
              css={classes.button}
              onClick={onSaveButton}
            />
          )}
        </Grid>
      </Grid>
    ),
    multimedia: (
      <Multimedia
        handleSubmit={handleSubmitMultimedia}
        filesProps={intentionMultimedias}
        isAdmin={isAdmin}
        messageOK={mgeMultimedia}
      />
    ),
    videos: (
      <>
        <PopeVideoStyled popeVideos={popeVideos} onChangeVideo={onChangeVideo} message={mgePopeVideo}/>
        <Grid item container xs={12} justify="flex-end">
          <Button
            text="Guardar"
            type="primary"
            css={classes.button}
            onClick={onSaveVideos}
          />
        </Grid>
      </>
    )
  };
  let pageSections = [...sections];
  if (!isAdmin) pageSections = sections.filter(section => section.keyContent !== "videos" && section.keyContent !== "bishop");
  return (
    <>
    <Grid container direction="column" data-testid="dashboard_view">
      <Grid container item direction="row">
        <Grid item xs={3}>
          <Select
            items={new Array(25).fill(thisYear - 1).map((yy, i) => ({
              value: yy + i,
              display: yy + i,
            }))}
            defaultValue={thisYear}
            handlerOnChange={(e) => setYear(e.target.value)}
          />
          <Select
            items={displayMonths.map((mm, i) => ({
              value: i + 1,
              display: mm,
            }))}
            defaultValue={thisMonth}
            handlerOnChange={(e) => setMonth(e.target.value)}
          />
          <Button
            text="Seleccionar"
            css={classes.button}
            type="secondary"
            onClick={() => handlerButtonFilter(year, month)}
            style={{float: "none"}}
          />
        </Grid>
        <Grid item xs={9}>
          <Validator intentions={intentions} handleValidate={handleValidate} isAdmin={isAdmin}/>
          {mgeValidate && (
            <div className={`${classes.msgBox} ${classes[mgeValidate.msgType]} `}>
              <span>{mgeValidate.text}</span>
              <div onClick={closeMessage} className={classes.msgClose}>X</div>
            </div>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {pageSections.map((section, key) => (
          <div key={key} style={{borderRadius: "5px", margin: "0 10px 1em 10px", padding: ".3em .8em 3em .8em", backgroundColor: "#f5f5f5"}}>
            <div><h3>{section.title}</h3></div>
            {Contents[section.keyContent]}
          </div>
        ))}
      </Grid>
    </Grid>
    <Footer/>
    </>
  );
}
