import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  box: {
    border: "1px solid #f3f3f3",
    borderRadius: "5px",
    padding: "5px",
    position: "relative",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "90%",
    paddingBottom: "10px"
  },
  modalBox: {
    position: "absolute",
    width: "80%",
    backgroundColor: "white",
    border: "2px solid #000",
    padding: "0.5em",
    top: "40%",
    left: "40%",
    transform: "translate(-40%, -40%)",
  },
});
