import React, {useState} from "react";
import { useStyles } from "./styles";
import {Formik, Form, Field} from "formik";
import Grid from "@material-ui/core/Grid";
import Input from "components/Input";
import Button from "components/Button";
import * as Yup from "yup";
import { pswRegex } from "helpers.js";
import {postSignUp} from "../../services/services";
import Select from "../../components/Select";
import LANG from "../../global/lang";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
export function SignUpFormStyled() {
  const [error, setError] = useState({text: ``, msgType: ''});

  async function handleSignUp(e) {
    if (e.languages.length < 1) {
      setError({
        text: "Seleccione un idioma ",
        msgType: "msgError",
      });
      return;
    }

    const body = {
      user: {
        email: e.email,
        password: e.password,
        languages: e.languages,
        role: e.role,
      },
      first_name: e.name,
      last_name: e.surname,
    };
    try {
      await postSignUp(body).then(r => {
        setError({text: `Usuario ${r.user.email} creado. Fue enviado al email un link para activar la cuenta.` })
      });
    } catch (error) {
      setError({
        text: "Error. " + error?.message,
        msgType: "msgError",
      });
    }
  }

  const classes = useStyles();
  const signUpScheme = Yup.object().shape({
    name: Yup.string()
        .required("Nombre requerido")
        .matches(/^(?!.*[/.:@%&()]).*/, "Formato incorrecto")
        .test(
            "required",
            "Nombre requerido",
            (val) => !!val?.trim().length
        )
        .max(50, "El campo no debe exceder los 50 caracteres"),
    surname: Yup.string()
        .required("Apellido es requerido")
        .matches(/^(?!.*[/.:@%&()]).*/, "Formato incorrecto")
        .test(
            "required",
            "Apellido es requerido",
            (val) => !!val?.trim().length
        )
        .max(50, "El campo no debe exceder los 50 caracteres"),
    country: Yup.object(),
    email: Yup.string()
        .email("Ingrese un mail válido")
        .required("Email is requerido"),
    password: Yup.string()
        .required("Contraseña requerida")
        .matches(pswRegex, "Formato incorrecto")
        .max(50, "El campo no debe exceder los 50 caracteres"),
    passwordConfirmation: Yup.string()
        .oneOf(
            [Yup.ref("password"), null],
            "Las contraseñas deben coincidir"
        )
        .required("Las contraseñas deben coincidir"),
  });
  const initialValueSignUp = {
    name: "",
    surname: "",
    country: "",
    email: "",
    password: "",
    passwordConfirmation: "",
    role: 2,
    languages: []
  };

  return (

      <div className={classes.signUpFormContent}>
        <div>
          <div className={classes.wrapperSignUpForm}>
            {error?.text !== '' && (
                <div className={`${classes.messageBox} ${classes[error.msgType]}`}>
                  {error.text}
                </div>
            )}
            <h3>Agregar usuario</h3>
            <Formik
                validationSchema={signUpScheme}
                initialValues={initialValueSignUp}
            >
              {({
                  dirty,
                  isValid,
                  errors,
                  values,
                  handleChange,
                  handleBlur,
                  touched,
                }) => {
                return (
                    <Form>
                      <Grid className={classes.gridForma} container>
                        <Grid
                            className={classes.rowInput}
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                        >
                          <Input
                              isSucess={!errors.name && touched.name}
                              isError={errors.name && touched.name}
                              name="name"
                              label="Nombre"
                              isRequired
                              css={classes.inputs}
                              id="txt_name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              message={errors.name}
                          />
                        </Grid>
                        <Grid
                            className={classes.rowInput}
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                        >
                          <Input
                              isSucess={!errors.surname && touched.surname}
                              isError={errors.surname && touched.surname}
                              name="surname"
                              label="Apellido"
                              isRequired
                              value={values.surname}
                              id="txt_surname"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              message={errors.surname}
                          />
                        </Grid>
                        <Grid
                            className={classes.rowInput}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                        >
                          <Input
                              isSucess={!errors.email && touched.email}
                              isError={errors.email && touched.email}
                              name="email"
                              label="Email"
                              id="txt_email"
                              value={values.email}
                              isRequired
                              onChange={handleChange}
                              onBlur={handleBlur}
                              message={errors.email}
                          />
                        </Grid>
                        <Grid
                            className={classes.rowInput}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={6}
                            xl={6}
                        >
                          <Input
                              type="password"
                              messageInfo="Al menos 8 caracteres, incluida una letra minúscula, una letra mayúscula y un número"
                              isSucess={!errors.password && touched.password}
                              isError={errors.password && touched.password}
                              value={values.password}
                              label="Contraseña"
                              isRequired
                              css={classes.inputs}
                              id="txt_password"
                              idButtonPassword="password"
                              name="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              message={errors.password}
                          />
                        </Grid>
                        <Grid

                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={6}
                            xl={6}
                        >
                          <Input
                              type="password"
                              isSucess={
                                  !errors.passwordConfirmation &&
                                  touched.passwordConfirmation
                              }
                              isError={
                                  errors.passwordConfirmation &&
                                  touched.passwordConfirmation
                              }
                              value={values.passwordConfirmation}
                              label="Repita la contraseña"
                              isRequired
                              id="txt_repeat_password"
                              idButtonPassword="repeat_password"
                              name="passwordConfirmation"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              message={errors.passwordConfirmation}
                          />
                        </Grid>
                        <Grid
                            className={classes.rowInput}
                            item
                            xs={12}
                            lg={4}
                        >
                          <InputLabel className={classes.label}>Rol</InputLabel>
                          <Select
                              items={[{value: 3, display: "Administrador"}, {value: 2, display: "Traductor"}]}
                              name="role"
                              handlerOnChange={handleChange}
                              defaultValue={values.role || 2}
                              label="Rol"
                          />
                        </Grid>
                        <Grid
                            className={classes.rowInput}
                            item
                            xs={12}
                            lg={8}
                        >
                          <InputLabel className={classes.label}>Idiomas</InputLabel>
                          {LANG && LANG.map((lang, key) =>
                              <Field
                                  type="checkbox"
                                  name="languages"
                                  value={lang.code}
                                  key={key}
                                  as={FormControlLabel}
                                  control={<Checkbox/>}
                                  checked={values.languages[lang.code]}
                                  label={lang.code.toUpperCase()}
                              />
                          )}
                        </Grid>
                      </Grid>
                      <div className={classes.contentRegister}>
                        <div>
                          <Button
                              data-testid="btn_signUpForm"
                              id="btn_signUpForm"
                              text="Guardar"
                              type="primary"
                              onClick={() => handleSignUp(values)}
                              disabled={!(isValid && dirty)}
                          />
                        </div>
                      </div>
                    </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>

  );
}
