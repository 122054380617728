import React from "react";
import { useStyles } from "./styles";
import { Grid } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import Audiotrack from "@material-ui/icons/Audiotrack";
import PhotoCamera from "@material-ui/icons/PhotoCamera";

export function PrayerCustomStyled(props) {
  const { step, custom, order, editCustomPrayer, type, editMultimedia } = props;

  const classes = useStyles();
  const media = ["AUD", "IMG"];
  let mediaBtn = media.map((mType) => {
    let itemMedia;
    if (custom?.multimedia) {
      itemMedia = custom.multimedia.find(
        (customMedia) => customMedia.type.code === mType
      );
    }
    let icon = mType !== media[0] ? <PhotoCamera /> : <Audiotrack />;
    return (
      <div
        key={mType}
        className={itemMedia ? classes.active : classes.mediaItem}
        onClick={() => editMultimedia(step, type, order, custom.multimedia)}
      >
        {icon} {itemMedia?.original_name}
      </div>
    );
  });

  return (
    <>
      {custom && (
        <Grid item xs={custom?.category ? 3 : 12} className={classes.box}>
          <div
            className={classes.btnEdit}
            onClick={() => editCustomPrayer(step, type, order, custom)}
          >
            <Edit />
          </div>
          <div className={`${classes.item} ${classes.itemTitle}`}>
            {custom?.title || ""}
          </div>
          <div
            className={`${classes.item} ${classes.itemDesc}`}
            dangerouslySetInnerHTML={{ __html: custom?.description || "" }}
          ></div>
          {type === "group" && (
            <div className={`${classes.item}`}>{custom?.video_url || ""}</div>
          )}
          <div className={classes.boxMedia}>{mediaBtn}</div>
        </Grid>
      )}
    </>
  );
}
