
import { matchSorter } from "match-sorter";
import {getCountries} from 'services/services'

export function onChangeSearch(elements, event) {
  return matchSorter(elements, event.target.value, { keys: ["value"] });
}


export async function getCountrys(setCountry, isAll, t) {
  const response = await getCountries();
  let countrys = response?.map((country) => {
    return { ...country, value: country.name };
  });
  if (isAll) countrys.unshift({ value: t("components.community.All") });

  setCountry(countrys);
}

export const pswRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[#?!@$%^&*-]?)([a-zA-Z0-9#?!@$%^&*-]{8,50})$/;
