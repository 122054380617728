import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createStore,combineReducers } from "redux";
import { authReducer } from "reducers/authReducer";
import { modalReducer } from "reducers/modalReducer";
import { Provider } from "react-redux";


const reducer = combineReducers({
  auth: authReducer,
  modal: modalReducer,
});

const store = createStore(reducer);


ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
