import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  label: {
    fontSize: ".7rem",
    fontWeight: "normal",
    lineHeight: 1,
    marginRight: ".8em",
    textTransform: "uppercase",
    color: "rgba(0, 0, 0, 0.54)",
    marginTop: "1.5em",
    display: "block"
  },
  txt: {
    color: '#697160'
  }
});
