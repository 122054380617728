import _ from "lodash";
import LANG from "global/lang";

const lang_codes = _.map(LANG, "code");

export const convertDataToObjects = async (arrayData) => {
  // incomming data is same incomingData of table
  // I need format [{ id: 1, title: 'foo' , desc: 'bar', ...},{...}] // normal object to send API n.n/
  const empty_ids = _.zipObject(
    lang_codes,
    new Array(lang_codes.length).fill()
  );
  const byId = {};
  arrayData.forEach((incomingRow) => {
    const row = { ...incomingRow };
    const entity = row.entity;
    const lang_and_id = _.toPairs(_.merge(row.id, empty_ids));
    lang_and_id.forEach(([lang, id], inx) => {
      const field = row.field;
      const typeCode = row.typeCode;
      let key_id = `${entity}_${typeCode}_${lang}_${String(id)}`;
      if (!id) {
        key_id = `${
          entity === "attitude" ? "att-" + inx : entity
        }_${typeCode}_${lang}_NEW`;
      }
      if (!byId[key_id]) {
        byId[key_id] = {
          key_id,
          ...row.data?.[lang],
          [field]: row[lang],
          type: row.typeCode,
          language: lang,
          entity,
        };
      }

      byId[key_id][field] = row[lang];
      byId[key_id].id_intention = row.id_intention?.[lang]; // solo para attitudes
      byId[key_id].order = row.order; // solo para attitudes
      row.data[lang] = { ...byId[key_id] };
    });
  });
  return _.filter(
    byId,
    (o) =>
      (o.title !== undefined && o.entity === "intention") ||
      o.description !== undefined
  );
};

export const convertSimpleDataToObject = (arrayData) => {
  arrayData.forEach((row) => {
    row.key_id = `${row.entity}_${row.id || row.code}`;
  });

  return arrayData;
};
