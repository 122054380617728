import React, { useEffect, useState } from "react";
import LANG from "global/lang";
import Grid from "@material-ui/core/Grid";
import { useStyles } from "./styles";
import { TextField } from "@material-ui/core";

export function PopeVideoStyled(props) {
  const { popeVideos, onChangeVideo, message = "" } = props;
  const classes = useStyles();
  const [errorSubmit, setErrorSubmit] = useState({ isValid: true });

  useEffect(() => {
    setErrorSubmit((prev) => ({ ...prev, text: message.split("$")[0] }));
  }, [message]);

  let videoFields = LANG.map((lang, key) => {
    const langCode = lang.code.toUpperCase();
    const videoLang = popeVideos[langCode] || undefined;
    if (videoLang) {
      return (
        <Grid item key={key} xs={6} className={classes.wrapper}>
          <label className={classes.label}>{String(langCode)}</label>
          <TextField
            id="outlined-margin-none"
            value={videoLang.video_url || ""}
            error={videoLang?.error}
            className={classes.textField}
            variant="outlined"
            margin="dense"
            fullWidth
            name={langCode}
            onChange={onChangeVideo}
          />
        </Grid>
      );
    } else {
      return (
        <Grid key={key} item xs={6} className={classes.wrapper}>
          <label className={classes.label}>{String(lang.code)}</label>
          <div className={classes.label2}>INTENCION NO CREADA</div>
        </Grid>
      );
    }
  });

  return (
    <>
      <Grid
        container
        className={classes.wrapper}
        data-testid="component_pope_video"
      >
        {videoFields}
      </Grid>
      <Grid
        container
        justify="flex-end"
        style={{
          color: errorSubmit.isValid ? "green" : "red",
          marginTop: "1em",
        }}
      >
        {errorSubmit.text}
      </Grid>
    </>
  );
}
