import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { useStyles } from "./styles";
import Button from "../Button";
import Modal from "@material-ui/core/Modal";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {validateLength} from "../../helpers/validate";

export function PrayerCustomEditStyled(props) {
  const { prayer, handleCustomClose, handleCustomSave } = props;
  const [title, setTitle] = useState(prayer.title);
  const [message, setMessage] = useState("");
  const [description, setDescription] = useState(prayer.description);
  const [video, setVideo] = useState(prayer.video_url);
  useEffect(() => {
    setTitle(prayer.title);
    setDescription(prayer.description);
    setVideo(prayer.video_url || '');
    setMessage("");
  }, [prayer]);
  const classes = useStyles();

  const handleSave = () => {
    if (validateLength(description, 2500)) {
      setMessage(`La descripción tiene ${description.length} caracteres. Debe contener menos de 2500`);
      return;
    }
    if (title !== "") {
      setMessage("");
      handleCustomSave(title, description, prayer.step, prayer.type, video);
    } else {
      setMessage("Titulo es requerido");
    }
  };

  return (
    <>
      <Modal
        open={prayer.open}
        onClose={handleCustomClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <Button
            text={"Guardar"}
            type="primary"
            data-testid="btn-custom-prayer-save"
            onClick={handleSave}
          />
          <Button
            text={"Cerrar"}
            type="secondary"
            data-testid="btn-custom-prayer-save"
            onClick={handleCustomClose}
          />
          {message && <div className={`${classes.messageBox}`}>{message}</div>}
          <TextField
            label="Titulo"
            name="title"
            disabled={prayer.type === "group"}
            required
            variant="filled"
            size="small"
            fullWidth
            margin="normal"
            value={title || ""}
            onChange={(e) => setTitle(e.target.value)}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputProps: {
                maxLength: 250
              }
            }}
          />
          <CKEditor
            editor={ClassicEditor}
            data={description || ""}
            config={{
              toolbar: ["bold", "italic", "blockQuote", "undo"],
              link: {
                addTargetToExternalLinks: true
              }
            }}
            name="desc"
            onReady={(editor) => {
              editor.editing.view.change((writer) => {
                writer.setStyle(
                  "height",
                  "300px",
                  editor.editing.view.document.getRoot()
                );
              });
            }}
            onChange={(event, editor) => {
              setDescription(editor.getData());
            }}
          />
          {prayer.type === "group" && (
            <TextField
              label="Youtube link"
              name="video_url"
              required
              variant="filled"
              size="small"
              fullWidth
              margin="normal"
              value={video || ""}
              onChange={(e) => setVideo(e.target.value || '')}
              InputLabelProps={{ shrink: true }}
            />
          )}
        </div>
      </Modal>
    </>
  );
}
