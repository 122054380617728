import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useStyles } from "./styles";
import Clear from "@material-ui/icons/Clear";
import { getStandarPrayers } from "services/services";

export function PrayerStandardStyled(props) {
  const {
    language = "es",
    prayer,
    qty,
    handlePrayerChange,
    order,
    section,
    handlePrayerDelete,
  } = props;

  const [listPrayers, setListPrayers] = useState([]);
  const [done, setDone] = useState();

  useEffect(() => {
    if (!done && !listPrayers.length) fetchStandardPrayers();
    setDone(true);
  });

  function fetchStandardPrayers() {
    getStandarPrayers(language).then((res) => setListPrayers(res.data));
  }

  const classes = useStyles();
  return (
    <>
      <div className={classes.paper}>
        <div className={classes.col1}>
          <FormControl
            variant="filled"
            className={classes.formSelect}
            size="small"
          >
            <InputLabel id="demo-simple-select-outlined-label">Oración Básica</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={prayer || ""}
              onChange={(e) => handlePrayerChange(e, order, section)}
              label="Básica"
              name="id"
            >
              <MenuItem value="">
                <em>Seleccionar</em>
              </MenuItem>
              {listPrayers.map((pray, inx) => (
                <MenuItem key={inx} value={pray.id}>
                  {pray.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.col2}>
          <TextField
            label="Cantidad"
            name="quantity"
            required
            variant="filled"
            size="small"
            fullWidth
            margin="normal"
            type="number"
            value={qty || ""}
            onChange={(e) => handlePrayerChange(e, order, section)}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputProps: {
                max: 30, min: 1
              }
            }}
          />
        </div>
        <div
          className={classes.btnClear}
          onClick={() => handlePrayerDelete(order, section)}
        >
          <Clear />
        </div>
      </div>
    </>
  );
}
