import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

export function TableStyled(props) {
  const {
    tableTitle,
    columnSchema = [],
    data: incomingData,
    css,
    onUpdateTable,
    onUpdateTableName,
    onAddedRowTable,
  } = props;

  /*
  incoming data format:
  [
    {
      row_name: 'title' (is a field of obj, title, description, etc)
      en: 'some text in inglish',
      es: 'some txt in spanish'
      id: { en: 1, es: 2 ...} // they're ids of objects
    }
    {...}
  ]
  */

  const [data, setData] = useState(incomingData);

  useEffect(() => {
    setData(incomingData);
  }, [incomingData]);

  columnSchema.forEach((c, i) => {
    c.headerStyle = {
      borderLeft: "1px solid #e0e0e0",
    };
    c.cellStyle = {
      borderLeft: "1px solid #e0e0e0",
      verticalAlign: "top",
      backgroundColor: i === 0 ? "#ebc772" : "",
      padding: "7px",
      lineHeight: "1.1"
    };
  });

  const tableIcons = {
    Check: CheckIcon,
    Clear: ClearIcon,
    Edit: EditIcon,
    Delete: DeleteIcon,
    SortArrow: KeyboardArrowUpIcon,
    Add: AddCircleIcon,
    // agregar aqui todos los iconos que se quiera ref: https://material-table.com/#/docs/all-props (#icons)
  };

  function onUpdateRowPromise(newData, oldData) {
    return new Promise((resolve) => {
      // los docs siempre usan 'setTimeout', no voy apreguntar porq y quiero evitarme problemas :v
      setTimeout(async () => {
        const dataUpdate = [...data];
        const index = oldData.tableData.id;
        dataUpdate[index] = newData;
        setData([...dataUpdate]);
        onUpdateTable(onUpdateTableName, [...dataUpdate], data, index);
        resolve();
      }, 1000);
    });
  }

  function onAddRowPromise(newData) {
    return new Promise((resolve) => {
      setTimeout(() => {
        onAddedRowTable(onUpdateTableName, newData, data);
        resolve();
      }, 1000);
    });
  }

  return (
    <div data-testid='material-table'>
    <MaterialTable
      icons={tableIcons}
      style={css}
      options={{
        search: false,
        paging: false,
        tableLayout: "auto",
        toolbar: Boolean(tableTitle),
        emptyRowsWhenPaging: false,
        padding: "dense",
        headerStyle: {
          backgroundColor: "#596386",
          color: "white",
          border: "1px solid #e0e0e0",
          padding: "5px 10px",
          fontFamily: " Nunito Sans",
        },
        rowStyle: {
          whiteSpace: "pre-line",
          lineHeight: 1
        },
      }}
      title={tableTitle}
      columns={columnSchema}
      data={data}
      editable={{
        onRowUpdate: onUpdateRowPromise,
        onRowAdd: onAddRowPromise,
      }}
    />
    </div>
  );
}
