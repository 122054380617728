import React from "react";
import { Select, MenuItem } from "@material-ui/core";
import { useStyles } from "./styles";

export function SelectStyled(props) {
  const { name, items, handlerOnChange, defaultValue, disabled = false } = props;
  const classes = useStyles();

  return (
    <Select
      className={classes.root}
      value={String(defaultValue)}
      name={name}
      displayEmpty
      autoWidth
      disabled={disabled}
      onChange={handlerOnChange}
      data-testid="btn_select"
    >
      {items &&
        items.map((i, key) => (
          <MenuItem key={key} value={String(i.value)}>
            {i.display}
          </MenuItem>
        ))}
    </Select>
  );
}
