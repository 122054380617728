import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DashboardView from "./DashboardView";
import {
  getCountries,
  getIntentionByLanguage,
  upsertIntentions,
  getAttitudes,
  upsertAttitude,
  getPrayers,
  upsertPrayers,
  getBishopConferences,
  upsertBishopConference,
  addMultimedias, setPublishStatus, resetIntentions,
} from "services/services";
import LANG from "global/lang";
import make_default from "global/default_data";
import { convertDataToObjects } from "helpers/convertTable";
import { addedRequireDates } from "helpers/normalizeObjects";
import { getUserStorage } from "services/authService";
import {youtubeValidUrl} from "../../helpers/validate";

export function DashboardContainer() {
  const lang_ids = _.map(LANG, "id");
  const meses = moment.months();
  const toUpdateDefault = {
    intention: [],
    attitude: [],
    prayer: [],
    bishop: [],
  };

  const [intentions, setIntention] = useState([]);
  const [intentionPrayers, setIntentionPrayers] = useState([]);
  const [intentionMultimedias, setIntentionMultimedias] = useState([]);
  const [id_intentions, setIdsIntentions] = useState([]);
  const [prayers, setPrayers] = useState([]);
  const [prayers_date, setPrayersDate] = useState();
  const [attitudes, setAttitudes] = useState([]);
  const [bishop, setBishop] = useState([]);

  const [year, setYear] = useState(moment().format("YYYY"));
  const [month, setMonth] = useState(moment().format("MM"));
  const [day, setDay] = useState(1);
  const [once, setOnce] = useState(0);
  const [countries, setCountries] = useState([]);
  const [toUpdate, setToUpdate] = useState(toUpdateDefault);
  const [mgges, setMessages] = useState({});
  const [popeVideos, setPopeVideos] = useState([]);
  const user = getUserStorage();

  const services = {
    intention: {
      UPSERT: upsertIntentions,
    },
    attitude: {
      UPSERT: upsertAttitude,
    },
    prayer: {
      UPSERT: upsertPrayers,
    },
    bishop: {
      UPSERT: upsertBishopConference,
    },
  };

  const section_states = [{
      title: "Intenciones",
      keyContent: "intention",
    },
    {
      title: "Oraciones diarias",
      keyContent: "prayers",
},
  {
    title: "Conferencias Episcopales",
    keyContent: "bishop",
  },
  {
    title: "Multimedia",
    keyContent: "multimedia",
  }
    ,
    {
      title: "Videos del Papa",
      keyContent: "videos",
    }]

  const userLangs = user?.languages ? _.map(user.languages, "code") : [];

  useEffect(async () => {
    if (!once) {
      fetchIntentions(year, month);
      setCountries(await getCountries());
      setOnce("done");
    }
  }, []);

  useEffect(()=> {
    fetchDailyPrayers(year, month, day);
    fetchMonAndOfferingPrayers(year, month);
    fetchAttitudes(id_intentions);
  }, [id_intentions])

  async function onUpdateTable(tableName, newData, oldData = []) {
    switch (tableName) {
      case "intentionTable":

        setIntention(_.filter(newData, { entity: "intention" }));
        setIntentionPrayers(_.filter(newData, { entity: "prayer" }));
        setAttitudes(_.filter(newData, { entity: "attitude" }));

        prepareToUpdate(
          await convertDataToObjects(newData),
          await convertDataToObjects(oldData)
        );
        break;
      case "prayerTable":
        setPrayers(newData);
        prepareToUpdate(
          await convertDataToObjects(newData),
          await convertDataToObjects(oldData)
        );
        break;
      case "bishopTable":
        setBishop(newData);
        prepareToUpdate(newData, oldData);
        break;
    }
  }

  function makeMultimedias(intention_response) {
    let multimediasOfIntentions = [];
    intention_response.data.forEach((intention) => {
      if (intention.multimedia?.length)
        multimediasOfIntentions = intention.multimedia;
    });

    multimediasOfIntentions.forEach((m) => (m.id_type = m.type.code));
    let multimediasOfIntentionsSorted = [
      "IMG_INT_HME_WEB", "IMG_INT_HME_MBL", "IMG_INT_MON_WEB", "IMG_INT_MON_MBL"
    ].map(id_type => multimediasOfIntentions.find(m => m.id_type === id_type)
      || { id_type: id_type, url: null })
    setIntentionMultimedias(multimediasOfIntentionsSorted);
  }

  function handleSubmitMultimedia(files) {
    const formData = new FormData();
    const metadatas = [];
    if (files.length > 4) {
      setMessages(prev => ({ ...prev, ['multimedia']: `Error de archivos. Recargue el mes y vuelva a intentar.$${Date.now()}` }));
      return;
    }

    // cuando se haga upsert de files quitar el filter, ahora evita un server 500
    files
      .filter((f) => f.file)
      .forEach((fileObject) => {
        const fileName = fileObject.file.name;
        const arrOwner = _.map(id_intentions, "id");
        const arrOwnerType = arrOwner.map((ownerId) => {
          return {"owner": ownerId, "ownerType": "int"};
        });

        formData.append(fileName, fileObject.file);
        metadatas.push({
          name: fileName,
          type: fileObject.id_type,
          owners: arrOwnerType,
          replace: fileObject.replace,
        });
      });
    formData.append("metadata", JSON.stringify(metadatas));
    if(metadatas.length > 0) {
      addMultimedias(formData)
        .then((oks) => {
          console.log(oks);
          updateIntentionsMultimedia(year, month).then();
          setMessages(prev => ({ ...prev, ['multimedia']: `Datos guardados.$${Date.now()}` }));
          resetCache();
        })
        .catch((error)=>{
          setMessages(prev => ({ ...prev, ["multimedia"]: {text: `Error. ${error.message}`, msgType: "msgError"}  }));
          updateIntentionsMultimedia(year, month).then();
        })
    }
  }

  function resetCache() {
    resetIntentions().then((res) => {
      console.log("cache renewed", res);
    })
      .catch(console.warn);
  }

  function onSaveButton() {
    _.forEach(toUpdate, async (value, key_entity) => {
      try {

        if (value.length) {
          const entityArray = value;
          await addedRequireDates(entityArray, { year, month, day });
          upsertFilterData(entityArray,key_entity)

          const upsertService = services[key_entity].UPSERT;
          if (!upsertService) throw `Not found service for '${key_entity}'`;

          await sendToAPI([entityArray], upsertService, key_entity);
        }
      } catch (e) {
        console.error(e);
      }
    });
  }

  function upsertFilterData(data,key_data){
    switch (key_data) {
      case 'intention':
       data.map((intention)=>{
           intention.video_url = intention?.video_url === null ? "" : intention.video_url;
           if(Object.prototype.hasOwnProperty.call(intention, "id")){
             delete intention.language
             delete intention.type
             delete intention.date_from
             delete intention.date_to
             return intention
           }else return intention
         })

        break;
      case 'prayer':
           data.map((prayer)=>{
           if(Object.prototype.hasOwnProperty.call(prayer, "id")){
             delete prayer.language
             delete prayer.type
             delete prayer.intention
             delete prayer.show_date
             return prayer
           }else return prayer
         })

        break
    }

  }

  function onPublishButton() {
    //TODO
  }

  //------- APIs

  function prepareToUpdate(newData, oldData) {
    const diff = _.differenceWith(newData, oldData, _.isEqual);
    const newState = { ...toUpdate };
    diff.forEach((obj) => {
      const indexToUpdate = _.findIndex(
        newState[obj.entity],
        (o) => o.key_id === obj.key_id
      );
      if (indexToUpdate < 0) newState[obj.entity].push(obj);
      else newState[obj.entity][indexToUpdate] = obj;
    });

    setToUpdate(newState);
    //console.log('el nuevo state', newState)
  }

  async function sendToAPI(arrObjToUpdate, service, key_entity) {

    Promise.all(arrObjToUpdate.map(service))
      .then(() => {
        setMessages(prev => ({ ...prev, [key_entity]: `Datos guardados.$${Date.now()}` }))
        fetchIntentions(year, month);
        setToUpdate(toUpdateDefault);
      })
      .catch(console.warn);
  }

  async function makeDataForExcel(byLang, row_names, rowOptions = {}) {
    // incoming data: { es: {id: 1, title: 'foo' ...}, en: {id: 1, title: 'foo' ...} ...}
    // I need data: [{row_name: 'title', es: 'inglish title', es:'spanish title'}...]

    return row_names.map((row, i) => {
      const prayerLang = _.mapValues(byLang, i);

      const zip = _.zipObject(
        Object.keys(_.mapValues(byLang, i)),
        _.map(prayerLang, row.field)
      );

      const zip_id = _.zipObject(
        Object.keys(_.mapValues(byLang, i)),
        _.map(prayerLang, "id")
      );

      return {
        row_name: row.display,
        data: prayerLang,
        field: row.field,
        entity: row_names.entity,
        typeCode: row.typeCode,
        order: row.order,
        id: zip_id,
        ...rowOptions,
        ...zip,
      };
    });
  }

  //----- intentions
  async function makeIntentionsForExcel(objIntentons = {}) {
    // incoming data: { es: {id: 1, title: 'foo' ...}, en: {id: 1, title: 'foo' ...} ...}
    // I need data: [{row_name: 'title', es: 'inglish title', es:'spanish title'}...]
    const row_names = [
      {
        field: "title",
        display: "Título",
        typeCode: "mon",
      },
      {
        field: "description",
        display: "Descripción",
        typeCode: "mon",
      },
    ];
    row_names.entity = "intention";

    return await makeDataForExcel(objIntentons, row_names);
  }

  async function fetchIntentions(yy, mm) {
    mm = moment(mm, 'MM').format('MM');
    const date_from = moment(`${yy}-${mm}`)
      .clone()
      .startOf("month")
      .format("YYYY-MM-DD");
    const date_to = moment(`${yy}-${mm}`)
      .clone()
      .endOf("month")
      .format("YYYY-MM-DD");

    getIntentionByLanguage({ date_from, date_to }, lang_ids)
      .then(async (response_intentions) => {
        if (!response_intentions || !response_intentions.data?.length)
          response_intentions = { data: make_default("intention") };

        const byLang = {},
          idsIntentionForAttitudes = [],
          popeVideos = {};

        response_intentions.data.forEach((intention) => {
          const lang = intention.language.code.toLowerCase();
          intention.entity = "intention";
          popeVideos[intention.language.code.toUpperCase()] = {id: intention.id, video_url: intention.video_url, title: intention.title, description: intention.description};
          byLang[lang] = [intention, intention]; // 1 para title, otro para desc
          idsIntentionForAttitudes.push({ id: intention.id, lang });
        });
        if (!idsIntentionForAttitudes[0]?.id) idsIntentionForAttitudes.length = 0;
        await fetchBishop(yy, mm);
        setIdsIntentions(idsIntentionForAttitudes);
        setIntention(await makeIntentionsForExcel(byLang));
        setPopeVideos(popeVideos);
        makeMultimedias(response_intentions);
      })
      .catch(console.warn);
  }

  async function updateIntentionsMultimedia(yy, mm) {
    mm = moment(mm, 'MM').format('MM');
    const date_from = moment(`${yy}-${mm}`)
      .clone()
      .startOf("month")
      .format("YYYY-MM-DD");
    const date_to = moment(`${yy}-${mm}`)
      .clone()
      .endOf("month")
      .format("YYYY-MM-DD");

    getIntentionByLanguage({ date_from, date_to }, lang_ids)
      .then(async (response_intentions) => {
        if (!response_intentions || !response_intentions.data?.length)
          response_intentions = { data: make_default("intention") };
        makeMultimedias(response_intentions);
      })
      .catch(console.warn);
  }
  //--- attitudes ---
  async function makeAttitudesForExcel(obj = {}) {
    // incomming data and need data similar like prayers....
    if (!id_intentions.length) {
      return [];
    }

    const { id_intention, ...objAttitudes } = obj;

    const row_names = new Array(5).fill().map((__, i) => ({
      field: "description",
      display: `Attitude ${i + 1}`,
      typeCode: `att${i + 1}`,
      order: i + 1,
    }));
    row_names.entity = "attitude";
    return await makeDataForExcel(objAttitudes, row_names, { id_intention });
  }

  async function fetchAttitudes(objIdsIntenions) {
    const ids = _.map(objIdsIntenions, "id");
    const langs = _.map(objIdsIntenions, "lang");
    Promise.all(ids.map((id) => getAttitudes(id)))
      .then(async (arrAttitudes) => {
        const byLang = { id_intention: {} };
        arrAttitudes.forEach((atts, inx) => {
          let r = [];
          for (let x = 1; x <= 5; x++) {
            let att = atts.find(a=> a.order === x) || make_default("attitude");
            r.push({ ...att, order: x })
          }
          atts = r;

          const lang = langs[inx];
          if (lang) {
            byLang[lang] = atts;
            byLang.id_intention[lang] = ids[inx];
          }
        });
        setAttitudes(await makeAttitudesForExcel(byLang));
      })
      .catch(console.warn);
  }

  // ---- prayeers ------
  async function makePrayersForExcel(obj = {}, typesDisplay = []) {
    // incoming data: {es: [{id: 1, desc: 'foo', type:'mor'},{...}], en: [{id: 6, desc: 'bar'},{...}] ...}
    // I need data: [{row_name: desc, es:'foo', en:'bar'}]
    const { id_intention, ...objPrayers } = obj;
    const idsByLang = _.transform(
      id_intentions,
      (result, value) => (result[value.lang] = value.id),
      {}
    );

    const rows_names = typesDisplay.map((type) => ({
      field: "description",
      display: type.display,
      typeCode: type.code,
    }));
    rows_names.entity = "prayer";

    return await makeDataForExcel(objPrayers, rows_names, {
      id_intention: _.merge(idsByLang, id_intention),
    });
  }

  async function fetchPrayers({ year, month, day }, codeTypes = []) {
    let date_from, date_to;
    month = moment(month, 'MM').format('MM');
    if (!day) {
      date_from = `${year}-${month}-01`;
      date_to = moment(`${year}-${month}`)
        .clone()
        .endOf("month")
        .format("YYYY-MM-DD");
    } else {
      day = String(day).padStart(2, '0');
      date_from = date_to = `${year}-${month}-${day}`;
    }

    return getPrayers({ date_from, date_to }, codeTypes, lang_ids)
      .then(async (response_prayers) => {
        if (!response_prayers || !response_prayers.data?.length) {
          if (id_intentions.length) {
            let data = []
            id_intentions.forEach(({ id, lang }) => {
              data.push(
                ...make_default(codeTypes).map(p=> {
                  p.intention = { id };
                  p.language.code = lang;
                  return p;
                })
              )
            })
            response_prayers = { data }
          } else response_prayers = { data: make_default(codeTypes) };
        }

        const byLang = { id_intention: {} },
          typesPrayers = _.transform(
            codeTypes,
            (res, val, inx) => (res[val] = inx),
            {}
          ); // aseguro el orden

        response_prayers.data.forEach((prayer) => {
          const lang = prayer.language.code.toLowerCase();
          const type = typesPrayers[prayer.type.code.toLowerCase()];
          const id_int = _.find(id_intentions, { lang })

          if (!byLang[lang])
            byLang[lang] = new Array(codeTypes.length).fill({});
          byLang[lang][type] = prayer;
          byLang.id_intention[lang] = prayer.intention?.id || id_int?.id;
        });

        return {
          prayers_date: date_from,
          prayersByLang: byLang,
        };
      })
      .catch(console.warn);
  }

  async function fetchDailyPrayers(year, month, day) {
    const types = [
      { code: "mor", display: "Mañana" },
      { code: "aft", display: "Tarde" },
      { code: "eve", display: "Noche" },
    ];
    const { prayers_date, prayersByLang } =
      (await fetchPrayers({ year, month, day }, _.map(types, "code"))) || {};
    setPrayers(await makePrayersForExcel(prayersByLang, types));
    setPrayersDate(prayers_date);
  }

  async function fetchMonAndOfferingPrayers(year, month) {
    const types = [
      { code: "mon", display: "Oración Mensual" },
      // { code: "off", display: "Offering Prayer" },
    ];
    const { prayersByLang } =
      (await fetchPrayers({ year, month }, _.map(types, "code"))) || {};
    setIntentionPrayers(await makePrayersForExcel(prayersByLang, types));
  }
  //----- bishop -------
  function fetchBishop(yy, mm) {
    const date_from = moment(`${yy}-${mm}`)
      .clone()
      .startOf("month")
      .format("YYYY-MM-DD");
    const date_to = moment(`${yy}-${mm}`)
      .clone()
      .endOf("month")
      .format("YYYY-MM-DD");
    getBishopConferences({ date_from, date_to })
      .then(async (response_bishop) => {
        if (response_bishop.length) {
          response_bishop.forEach((conference) => {
            conference.name = conference.country.name;
            conference.code = conference.country.alpha2_code;
            conference.entity = "bishop";
          });
        }
        setBishop(response_bishop);
      })
      .catch(console.warn);
  }

  function getFullDateFormat() {
    const strMonth = moment(month, 'MM').format('MM');
    const strDay = moment(day, 'DD').format('DD');
    return `${year}-${strMonth}-${strDay}`;
  }

  function validatePublish(intention, status, lang) {
    if (user?.role?.description === "ADMIN") {
      let newStatus = status === "draft" ? "validated" : status === "validated" ? "published" : "";
      if (intention && newStatus !== "") {
        setPublishStatus(intention, newStatus).then(r => {
          if (r && r === intention) {
            setMessages(prev => ({ ...prev, ["validate"]: {text: `Cambio completado para ${lang}`} }))
            fetchIntentions(year, month);
          }
        })
          .catch((error)=>{
            setMessages(prev => ({ ...prev, ["validate"]: {text: `Error. ${error.message}`, msgType: "msgError"}  }))
          })
      } else {
        setMessages(prev => ({ ...prev, ["validate"]: {text: `No se puede completar el proceso`, msgType: "msgError"} }));
      }
    }
  }

  function closeMessage() {
    setMessages(prev => ({ ...prev, ["validate"]: undefined }))
  }

  function onChangeVideo(event){
    setPopeVideos({
      ...popeVideos,
      [event.target.name]: {...popeVideos[event.target.name], video_url: event.target.value, edited: true},
    });
  }

  async function onSaveVideos() {
    let stateVideo = {...popeVideos}, errorSave = false;
    Object.keys(stateVideo).forEach((key) => {
      if (stateVideo[key].edited && stateVideo[key].video_url !== "" && stateVideo[key].video_url !== null
        && !youtubeValidUrl(stateVideo[key].video_url)) {
        stateVideo[key].error = true;
        errorSave = true;
      }
    });
    if (errorSave) {
      setPopeVideos(stateVideo);
      setMessages(prev => ({ ...prev, ["videos"]: `Ingresar links de YouTube válidos.$${Date.now()}` }));
      return;
    }

    let arrVideos = Object.values(popeVideos).filter(video => video?.id && video?.edited);
    try {
      if (arrVideos.length > 0) {
        await sendToAPI([arrVideos], upsertIntentions, "videos");
      }
    } catch (e) {
      setMessages(prev => ({ ...prev, ["videos"]: `Error. ${e?.message}$${Date.now()}` }));
    }
  }

  return (
    <DashboardView
      year={year}
      month={month}
      setYear={setYear}
      setMonth={setMonth}
      displayMonths={meses}
      handlerButtonFilter={(yy, mm) => {
        fetchIntentions(yy, mm);
        setDay(1);
      }}
      intentions={intentions}
      intentionPrayers={intentionPrayers}
      attitudes={attitudes}
      prayers={prayers}
      bishop={bishop}
      countries={countries}
      prayersDate={prayers_date || getFullDateFormat()}
      sections={section_states}
      handlerPrayers={(yy, mm, dd) => {
        fetchDailyPrayers(yy, mm, dd);
        setDay(dd);
      }}
      onUpdateTable={onUpdateTable}
      onSaveButton={onSaveButton}
      onPublishButton={onPublishButton}
      intentionMultimedias={intentionMultimedias}
      handleSubmitMultimedia={handleSubmitMultimedia}
      handleValidate={validatePublish}
      mgeIntentionOK={mgges.intention}
      mgePrayersOk={mgges.prayer}
      mgeBishopOK={mgges.bishop}
      mgeValidate={mgges.validate}
      closeMessage={closeMessage}
      userRole={user?.role?.description}
      userLangs={userLangs}
      popeVideos={popeVideos}
      onChangeVideo={onChangeVideo}
      onSaveVideos={onSaveVideos}
      mgePopeVideo={mgges.videos}
      mgeMultimedia={mgges.multimedia}
    />
  );
}
