import React, { useState } from 'react';
import { useStyles } from "./styles";
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Cropper from 'react-cropper';
import Grid from '@material-ui/core/Grid';
import cx from 'classnames';
import 'cropperjs/dist/cropper.css';
import crypto from 'crypto';

export function CropImageStyled(props) {
  const { submitImage, handleImageChange } = props;

  const [image, setImage] = useState("");
  const [upload, setUpload] = useState();
  const [cropper, setCropper] = useState();
  const styles = useStyles();
  const previewStyles = cx(styles.avatar, "img-preview");

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    if (files?.length) {
      reader.readAsDataURL(files[0]);
      setUpload(files[0]);
      handleImageChange(URL.createObjectURL(files[0]));
    }
  };

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      const inputURI = cropper.getCroppedCanvas().toDataURL('image/jpeg');

      const BASE64_MARKER = ';base64,';
      const mime = inputURI.split(BASE64_MARKER)[0].split(':')[1];

      const lastDot = upload ? upload.name.lastIndexOf('.') : 0;
      const filename = upload ? upload.name.substring(0, lastDot) + '-sm.' + upload.name.substring(lastDot + 1) : 
      'sample-' + crypto.randomBytes(20).toString('hex') + 'sm.jpg';
      const bytes = atob(inputURI.split(BASE64_MARKER)[1]);
      const writer = new Uint8Array(new ArrayBuffer(bytes.length));

      for (let i=0; i < bytes.length; i++) {
        writer[i] = bytes.charCodeAt(i);
      }
      return new File([writer.buffer], filename, { type: mime });
    }
  };

  const saveImage = () => {
    const imgCroppped = getCropData();
    let files = [];
    if (imgCroppped) {
      files.push(upload, imgCroppped)
      submitImage(files);
      setImage("");
      setUpload({});
    }
  };

  const resetCrop = () => {
    if (typeof cropper !== 'undefined') {
      cropper.reset();
    }
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <input
            accept="image/*"
            className={styles.hide}
            id="icon-button-file"
            type="file"
            onChange={onChange}
          />
          <label htmlFor="icon-button-file">
            <Button
              variant="contained"
              color="primary"
              size="small"
              component="span"
              className={styles.btnUpload}
              startIcon={<PhotoCamera />}
            >
              Subir imagen
            </Button>
          </label>

          { image && (
            <Box width="100%" height="100%">
              <label>Recorte para presentaci&oacute;n</label>
              <Cropper
                className={styles.cropper}
                initialAspectRatio={1}
                preview=".img-preview"
                src={image}
                viewMode={1}
                guides={false}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={true}
                scalable={false}
                cropBoxResizable={false}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
              />
            </Box>
            )}
        </Grid>
        {
          image && (
            <Grid item sm={12}>
              <div className={styles.box}>
                <div className={previewStyles} />
              </div>
              <Button variant="contained" color="primary" size="small" onClick={saveImage} style={{marginRight: 10}}>
              Seleccionar recorte
              </Button>
              <Button variant="contained" color="default" size="small" onClick={resetCrop}>
                Deshacer recorte
              </Button>
            </Grid>
          )
        }
      </Grid>
    </>
  );
}
