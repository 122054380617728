import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Search from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Clear from "@material-ui/icons/Clear";

export default {
  Edit: EditIcon,
  Delete: DeleteIcon,
  SortArrow: KeyboardArrowUpIcon,
  Add: AddCircleIcon,
  PreviousPage: ChevronLeft,
  NextPage: ChevronRight,
  Search: Search,
  LastPage: LastPage,
  FirstPage: FirstPage,
  Filter: FilterList,
  Clear: Clear,
};
