import React from "react";
import LANG from "global/lang";
import Grid from "@material-ui/core/Grid";
import { useStyles } from "./styles";
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import PublicIcon from '@material-ui/icons/Public';
import {Tooltip} from "@material-ui/core";

export function ValidatorStyled(props) {
  const { intentions, handleValidate, isAdmin } = props;
  const classes = useStyles();

  let langNav = LANG.map((lang, key) => {
    const intentionData = intentions?.[0]?.data;
    if (
      intentionData &&
      lang.code in intentionData &&
      "id" in intentionData[lang.code]
    ) {
      const intStatus = intentionData[lang.code].status;
      return (
        <div key={key} className={classes.wrapperItem}>
          <div className={classes.label}>{String(lang.code)}</div>
          {intStatus === "published" ? (
            <div className={classes.status}>Publicado</div>
          ) : (
            <div className={`${classes.status} ${classes[intStatus]}`}>
              {intStatus === "draft" ? "Borrador" : "Validado"}
              {isAdmin && (
                <Tooltip title={intStatus === "validated" ? "Publicar" : "Validar"} placement="top-start">
                <IconButton
                  color="primary"
                  aria-label="Change Status"
                  size="small"
                  edge="end"
                  onClick={() =>
                    handleValidate(
                      intentionData[lang.code].id,
                      intentionData[lang.code].status,
                      lang.code
                    )
                  }
                >
                  {(intStatus === "validated" && <PublicIcon />) || (
                    <CheckIcon />
                  )}
                </IconButton>
                </Tooltip>
              )}
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div key={key} className={classes.wrapperItem}>
          <div className={classes.label}>{String(lang.code)}</div>
          <div className={`${classes.status} ${classes.inactive}`}>-</div>
        </div>
      );
    }
  });
  return (
    <Grid
      container
      justify="flex-end"
      className={classes.wrapper}
      data-testid="component_lang_validator"
    >
      {langNav}
    </Grid>
  );
}
