import React, { useState, useEffect } from "react";
import { useStyles } from "./styles";
import { Avatar, Grid } from "@material-ui/core";
import { useFormik } from "formik";
import Button from "components/Button";
import Input from "components/Input";
import Icon from "components/Icon";
import ArrowRight from "assets/img/Chevron right.svg";
import ArrowBottom from "assets/img/chevron-down-dark.svg";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CloseAccount from "components/CloseAccount";
import * as Yup from "yup";
import _ from "lodash";

const ProfileView = (props) => {
  const [isChangePassword, setIsChangePassword] = useState(false);
  const {
    user,
    isLg,
    isMd,
    handleCloseModalAccount,
    isCloseAccount,
    handleCloseAccount,
    handleCloseSession,
    handleModifyPassword,
  } = props;
  const classes = useStyles({ mediaQueryMd: isMd, mediaQueryLg: isLg });

  const profilFormChangePasswordScheme = Yup.object().shape({
    currentPassword: Yup.string()
      .required("Contraseña requerida")
      // .matches(
      //   /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}$/,
      //   "Formato incorrecto"
      // )
      .max(50, "El campo no debe superar los 50 caracteres"),
    newpassword: Yup.string()
      .required("Contraseña requerida")
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}$/,
        "Formato incorrecto"
      )
      .notOneOf(
        [Yup.ref("currentPassword"), null],
        "La contraseña anterior no debe concidir con la nueva contraseña"
      )
      .max(50, "El campo no debe superar los 50 caracteres"),
    repeatNewPassword: Yup.string()
      .oneOf([Yup.ref("newpassword"), null], "Las contraseñas deben coincidir")
      .required("Las contraseñas deben coincidir"),
  });
  const initialValueProfilFormChangePassword = {
    currentPassword: "",
    newpassword: "",
    repeatNewPassword: "",
  };

  const formikFormChangePassword = useFormik({
    initialValues: initialValueProfilFormChangePassword,
    validationSchema: profilFormChangePasswordScheme,
  });
  useEffect(() => {
    setIsChangePassword(true);
  }, [isMd]);

  const formChangePassword = () => {
    return (
      <form>
        <Grid container>
          <Grid
            className={classes.profileInputs}
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <Input
              isSucess={
                !formikFormChangePassword.errors.currentPassword &&
                formikFormChangePassword.touched.currentPassword
              }
              isError={
                formikFormChangePassword.errors.currentPassword &&
                formikFormChangePassword.touched.currentPassword
              }
              type="password"
              value={formikFormChangePassword.values.currentPassword}
              label="Contraseña actual"
              isRequired
              id="txt_password_current"
              idButtonPassword="password_current"
              name="currentPassword"
              onChange={formikFormChangePassword.handleChange}
              onBlur={formikFormChangePassword.handleBlur}
              message={formikFormChangePassword.errors.currentPassword}
            />
          </Grid>
          <Grid
            className={classes.profileInputs}
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <Input
              isSucess={
                !formikFormChangePassword.errors.newpassword &&
                formikFormChangePassword.touched.newpassword
              }
              isError={
                formikFormChangePassword.errors.newpassword &&
                formikFormChangePassword.touched.newpassword
              }
              type="password"
              value={formikFormChangePassword.values.newpassword}
              label="Nueva contraseña"
              isRequired
              id="txt_password_new"
              idButtonPassword="password_new"
              name="newpassword"
              onChange={formikFormChangePassword.handleChange}
              onBlur={formikFormChangePassword.handleBlur}
              message={formikFormChangePassword.errors.newpassword}
            />
          </Grid>
          <Grid
            className={classes.profileInputs}
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <Input
              isSucess={
                !formikFormChangePassword.errors.repeatNewPassword &&
                formikFormChangePassword.touched.repeatNewPassword
              }
              isError={
                formikFormChangePassword.errors.repeatNewPassword &&
                formikFormChangePassword.touched.repeatNewPassword
              }
              value={formikFormChangePassword.values.repeatNewPassword}
              type="password"
              label="Repetir contraseña"
              isRequired
              id="txt_password_repeat"
              idButtonPassword="password_repeat"
              name="repeatNewPassword"
              onChange={formikFormChangePassword.handleChange}
              onBlur={formikFormChangePassword.handleBlur}
              message={formikFormChangePassword.errors.repeatNewPassword}
            />
          </Grid>
          <Grid
            className={classes.buttonContent}
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <p className={classes.passFormat}>
              <b>Formato de contraseña:</b><br/>
              -Al menos 8 caracteres, incluida una letra minúscula, una letra mayúscula y un número especial.<br/>
              -Caracteres especiales admitidos: #?!@$%^&*-.<br/>
              -El campo no debe superar los 50 caracteres.
            </p>
            <Button
              id="btn_update"
              css={classes.buttonUpdate}
              type="primary"
              text="Update"
              onClick={() => {
                formikFormChangePassword.resetForm();
                handleModifyPassword(formikFormChangePassword.values);
              }}
              disabled={
                !(
                  formikFormChangePassword.isValid &&
                  formikFormChangePassword.dirty
                )
              }
            />
          </Grid>
        </Grid>
      </form>
    );
  };
  const buttonsAccount = () => {
    return (
      <>
        <Button
          css={classes.buttonLogOutAccount}
          type="secondary"
          text="Cerrar sesión"
          id="btn_logOutAccount"
          onClick={handleCloseSession}
        />
      </>
    );
  };

  return (
    <div className={classes.profileContainer} data-testid={`profile_view`}>
      {isCloseAccount && (
        <CloseAccount
          open={isCloseAccount}
          isMd={isMd}
          handleClose={handleCloseModalAccount}
          handleCloseAccount={handleCloseAccount}
        />
      )}
      <div className={classes.profile}>
        <div className={classes.profileContentUser}>
          <Avatar
            className={classes.profileAvatar}
            alt="user profile"
            src={user?.avatar}
          />
          <div className={classes.profileUser}>
            <h1 data-testid="txt_user_name">
              {user?.person?.first_name + " " + user?.person?.last_name}
            </h1>
            <p data-testid="txt_description">{user?.languages ? _.map(user.languages, "code").join("-") : ""}</p>
          </div>
          {!isMd && (
            <>
              <div className={classes.profileButton}>
                {
                  <Button
                    type="cuaternary"
                    text="Cambiar contraseña"
                    iconRight={<Icon icon={ArrowRight} alt="ico_arrow" />}
                    onClick={() => {
                      // formikFormUser.resetForm();
                      setIsChangePassword(true);
                      // setIsFormUser(false);
                    }}
                    id="btn_change_password"
                  />
                }
              </div>
              {buttonsAccount()}
            </>
          )}
        </div>

        {!isMd ? (
          <div className={classes.profileForm}>
            {/* {isFormUser &&
              formUser(profilFormUsereScheme, initialValueProfileFormUser)} */}

            {formChangePassword()}
          </div>
        ) : (
          <div>
            {
              <div>
                <Accordion
                  onChange={() => {
                    if (isChangePassword) setIsChangePassword(false);
                    else setIsChangePassword(true);

                    // setIsFormUser(false);
                  }}
                  className={`${classes.contentAccordion}`}
                  expanded={isChangePassword}
                  data-testid={`acc_form_password`}
                >
                  <AccordionSummary
                    expandIcon={<Icon icon={ArrowBottom} />}
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                    className={classes.contentSumary}
                    data-testid="acc_sumamary_form_password"
                  >
                    <h1 data-testid="txt_change_password">
                      Cambiar contraseña
                    </h1>
                  </AccordionSummary>
                  <AccordionDetails
                    data-testid="acc_details_form_password"
                    className={classes.contentDetails}
                  >
                    {formChangePassword()}
                  </AccordionDetails>
                </Accordion>
              </div>
            }
          </div>
        )}
        {isMd && buttonsAccount()}
      </div>
    </div>
  );
};

export default ProfileView;
