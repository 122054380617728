const prayer_types = {
  mor: "Morning",
  mon: "Monthly",
  aft: "Afternoon",
  off: "Offering",
  eve: "Eveniong",
};
const def_obj = {
  title: "",
  description: "",
};

const defaults = {
  intention: [
    {
      ...def_obj,
      language: {
        code: "es",
        description: "Español",
      },
    },
  ],
  attitude: def_obj,
};
const make_default = (types) => {
  if (typeof types === "string") {
    return defaults[types] || {};
  }

  return types.map((type) => ({
    ...def_obj,
    type: {
      code: type,
      description: prayer_types[type],
    },
    language: {
      code: "es",
      description: "Español",
    },
  }));
};

export default make_default;
