import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  wrapper: {
    margin: "0 0 0 0",
    display: "flex",
    alignItems: "center",
    width: "90%"
  },
  label: {
    fontSize: "1.3em",
    marginRight: ".5em",
    textTransform: "uppercase",
    color: "#d5d5d5",
  },
  label2: {
    fontSize: "1em",
    marginLeft: "10px",
    textTransform: "uppercase",
    color: "#999898",
  },
  textField: {
    "& input": {
      fontSize: ".9em"
    },
    marginRight: "20px"
  },
});
