import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { useStyles } from "./styles";
import Button from "../Button";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Wallpaper from "@material-ui/icons/Wallpaper";
import Delete from "@material-ui/icons/Delete";
import Save from "@material-ui/icons/Save";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";

export function CampaignItemStyled(props) {
  const {
    prayer,
    order,
    handleItemChange,
    type,
    handleImageChange,
    handleDeleteItem,
    deleted,
    edited,
    handleSaveItem,
    handleItemChangeDate,
  } = props;
  const [item, setItem] = useState();
  useEffect(() => {
    setItem(prayer);
    if(!prayer.date && (type === "SCH" || type === "PRO" || type === "TIP" || type === "UNS")) handleItemChangeDate(order)(moment(item?.date).toISOString())
  }, [prayer]);

  const handleChange = () => {
    setItem({
      ...item,
      [event.target.name]: event.target.value,
      edited: true,
    });
  };

  const handleItemBlur = (orderIdx) => {
    let newData = {
      ...item,
      [event.target.name]: event.target.value,
      edited: true,
    };
    handleItemChange(newData, orderIdx);
  };

  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper className={deleted ? classes.wrapperDel : classes.wrapper}>
          <Grid container>
            <Grid item xs={2}>
              <div className={classes.label}>Imagen</div>
              {item?.multimedia[0] ? (
                <img className={classes.imageUpload} src={item?.multimedia[0]?.url} />
              ) : (
                <div className={classes.noImage}>
                  <Wallpaper className={classes.noImageIcon} />
                </div>
              )}

              <input
                accept="image/*"
                className={classes.hide}
                id={`icon-button-file-items-${order}`}
                type="file"
                onChange={(event) => handleImageChange(event, "CAP", order)}
              />
              <label htmlFor={`icon-button-file-items-${order}`}>
                <Button
                  variant="contained"
                  size="small"
                  css={classes.btnUpload}
                  startIcon={<PhotoCamera />}
                  component="span"
                >
                  Seleccionar imagen
                </Button>
              </label>
            </Grid>
            <Grid item xs={8}>
              {type !== "TIP" && (
                <div>
                  <TextField
                    data-index={order}
                    label="Titulo"
                    name="title"
                    required
                    variant="filled"
                    size="small"
                    fullWidth
                    margin="normal"
                    value={item?.title || ""}
                    onBlur={() => handleItemBlur(order)}
                    onChange={() => handleChange()}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputProps: {
                        maxLength: 250
                      }
                    }}
                  />
                </div>
              )}
              <div>
                <TextField
                  data-index={order}
                  label="Description"
                  name="description"
                  required
                  variant="filled"
                  size="small"
                  fullWidth
                  multiline
                  rows={6}
                  margin="normal"
                  value={item?.description || ""}
                  onBlur={() => handleItemBlur(order)}
                  onChange={() => handleChange()}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputProps: {
                      maxLength: 2500
                    }
                  }}
                />
              </div>
              {type === "PRO" && (
                <div>
                  <TextField
                    label="Link"
                    name="hashtag"
                    variant="filled"
                    size="small"
                    fullWidth
                    margin="normal"
                    value={item?.hashtag || ""}
                    onBlur={() => handleItemBlur(order)}
                    onChange={() => handleChange()}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputProps: {
                        maxLength: 250
                      }
                    }}
                  />
                </div>
              )}
            </Grid>
            <Grid item xs={2}>
              <Button
                text="Eliminar"
                onClick={() => handleDeleteItem(order)}
                id="btn_del_item"
                data-testid="btn_del_item"
                variant="text"
                color="secondary"
                icon={<Delete />}
                style={{ float: "right" }}
              />
              <Button
                text="Guardar"
                onClick={() => handleSaveItem(order)}
                id="btn_save_item"
                data-testid="btn_save_item"
                variant="text"
                color="secondary"
                disabled={!edited}
                icon={<Save />}
                style={{ float: "right" }}
              />
              {((type === "SCH" || type === "PRO" || type === "TIP" || type === "UNS") && (
                <div>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={esLocale}
                  >
                    <DatePicker
                      openTo="month"
                      clearable={true}
                      format="dd-MM-yyyy"
                      label="Mostrar"
                      views={["year", "month", "date"]}
                      value={moment(item?.date).toISOString() || null}
                      onChange={handleItemChangeDate(order)}
                      inputVariant="filled"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin="normal"
                      name="date_from"
                      style={{ width: "160px", margin: "42px 0 0 10px" }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                )) || <div className={classes.order}>{`#${order}`}</div>}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
