import React, { useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  ROOT,
  DEFAULT,
  BLOG,
  BLOG_EDIT,
  BLOG_ADD,
  CAMPAIGN,
  CAMPAIGN_EDIT,
  CAMPAIGN_ADD,
  ROSARY,
  ROSARY_EDIT,
  ROSARY_ADD,
  PROFILE,
  POPE_PROFILE,
  POPE_EDIT,
  MEDIATION,
  MEDIATION_EDIT,
  SCHOOL,
  USER,
  USER_ADD,
  TRANSLATOR
} from "./CONSTANTS";
import Home from "pages/Home";
import Blog from "pages/Blog";
import BlogEdit from "pages/Blog/BlogEdit";
import BlogAdd from "pages/Blog/BlogAdd";
import Campaign from "pages/Campaign";
import CampaignEdit from "pages/Campaign/CampaignEdit";
import CampaignAdd from "pages/Campaign/CampaignAdd";
import Rosary from "pages/Rosary";
import RosaryAdd from "pages/Rosary/RosaryAdd";
import RosaryEdit from "pages/Rosary/RosaryEdit";
import NotFound from "pages/NotFound";
import Profile from "pages/Profile";
import PopeProfile from "pages/PopeProfile";
import PopePrayerEdit from "pages/PopeProfile/PopeEdit";
import Mediation from "pages/Mediation";
import MediationEdit from "pages/Mediation/MediationEdit";
import School from "../pages/School";
import User from "../pages/User";
import MessageErrors from "components/MessageErrors";
import Header from "../components/Header";
import { getUserStorage, singIn } from "services/authService";
import { useDispatch, useSelector } from "react-redux";
import Auth from "components/Auth";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import SignUpForm from "../pages/SignUpForm";
import Translator from "../pages/Translator";

export const RouterConfig = () => {
  const mediaQueryLg = useMediaQuery("(max-width:1294px)");
  const mediaQueryMd = useMediaQuery("(max-width:960px");
  const dispatch = useDispatch();
  const modal = useSelector((store) => store.modal);
  const [openLogin, setOpenLogin] = useState(false);
  const user = getUserStorage();

  async function handleLogin(event) {
    try {
      await singIn({
        email: event.email,
        password: event.password,
        dispatch,
      });
      setOpenLogin(false);
    } catch (error) {
      dispatch({
        type: "@modal/error",
        payload: {
          open: true,
          message: {
            name: error?.name,
            messageError: error?.message,
            code: error?.code,
          },
        },
      });
      setOpenLogin(true);
    }
  }

  return (
    <div>
      <Header handleLogin={() => setOpenLogin(true)} />
      <Auth
        isOpen={openLogin}
        isMd={mediaQueryMd}
        isLg={mediaQueryLg}
        handleClose={() => setOpenLogin(false)}
        handleSubmitLogin={handleLogin}
      />
      <MessageErrors
        open={modal?.error?.open}
        title={modal?.error?.message?.name}
        messageError={modal?.error?.message?.messageError}
        handleClose={() => {
          dispatch({
            type: "@modal/error",
            payload: { open: false, message: "" },
          });
        }}
        isMd={mediaQueryMd}
      />
      <Switch>
        {/* List all public routes here */}
        <Route exact path={ROOT} component={Home} />
        <Route
          exact
          path={PROFILE}
          render={(props) =>
            user ? <Profile {...props} /> : <Redirect to={ROOT} />
          }
        />
        <Route
          exact
          path={BLOG}
          render={(props) =>
            user?.role?.description === "ADMIN" ? (
              <Blog {...props} />
            ) : (
              <Redirect to={ROOT} />
            )
          }
        />
        <Route
          exact
          path={`${BLOG_ADD}/:lang/:id?/:others?`}
          render={(props) =>
            user?.role?.description === "ADMIN" ? (
              <BlogAdd {...props} />
            ) : (
              <Redirect to={ROOT} />
            )
          }
        />
        <Route
          exact
          path={`${BLOG_EDIT}/:id/:lang`}
          render={(props) =>
            user?.role?.description === "ADMIN" ? (
              <BlogEdit {...props} />
            ) : (
              <Redirect to={ROOT} />
            )
          }
        />
        <Route
          exact
          path={CAMPAIGN}
          render={(props) =>
            user?.role?.description === "ADMIN" ? (
              <Campaign {...props} />
            ) : (
              <Redirect to={ROOT} />
            )
          }
        />
        <Route
          exact
          path={`${CAMPAIGN_ADD}/:type/:lang/:groupId?`}
          render={(props) =>
            user?.role?.description === "ADMIN" ? (
              <CampaignAdd {...props} />
            ) : (
              <Redirect to={ROOT} />
            )
          }
        />
        <Route
          exact
          path={`${CAMPAIGN_EDIT}/:id/:lang?`}
          render={(props) =>
            user?.role?.description === "ADMIN" ? (
              <CampaignEdit {...props} />
            ) : (
              <Redirect to={ROOT} />
            )
          }
        />
        <Route
          exact
          path={ROSARY}
          render={(props) =>
            user?.role?.description === "ADMIN" ? (
              <Rosary {...props} />
            ) : (
              <Redirect to={ROOT} />
            )
          }
        />
        <Route
          exact
          path={`${ROSARY_EDIT}/:id/:lang?`}
          render={(props) =>
            user?.role?.description === "ADMIN" ? (
              <RosaryEdit {...props} />
            ) : (
              <Redirect to={ROOT} />
            )
          }
        />
        <Route
          exact
          path={`${ROSARY_ADD}/:lang/:groupId?`}
          render={(props) =>
            user?.role?.description === "ADMIN" ? (
              <RosaryAdd {...props} />
            ) : (
              <Redirect to={ROOT} />
            )
          }
        />

        <Route
          exact
          path={POPE_PROFILE}
          render={(props) =>
            user?.role?.description === "ADMIN" ? (
              <PopeProfile {...props} />
            ) : (
              <Redirect to={ROOT} />
            )
          }
        />

        <Route
          exact
          path={`${POPE_EDIT}/:id`}
          render={(props) =>
            user?.role?.description === "ADMIN" ? (
              <PopePrayerEdit {...props} />
            ) : (
              <Redirect to={ROOT} />
            )
          }
        />

        <Route
          exact
          path={MEDIATION}
          render={(props) =>
            user?.role?.description === "ADMIN" ? (
              <Mediation {...props} />
            ) : (
              <Redirect to={ROOT} />
            )
          }
        />
        <Route
          exact
          path={`${MEDIATION_EDIT}/:id/:type`}
          render={(props) =>
            user?.role?.description === "ADMIN" ? (
              <MediationEdit {...props} />
            ) : (
              <Redirect to={ROOT} />
            )
          }
        />
        <Route
          exact
          path={SCHOOL}
          render={(props) =>
            user?.role?.description === "ADMIN" ? (
              <School {...props} />
            ) : (
              <Redirect to={ROOT} />
            )
          }
        />
        <Route
          exact
          path={USER}
          render={(props) =>
            user?.role?.description === "ADMIN" ? (
              <User {...props} />
            ) : (
              <Redirect to={ROOT} />
            )
          }
        />
          <Route
              exact
              path={TRANSLATOR}
              render={(props) =>
                  user?.role?.description === "ADMIN" ? (
                      <Translator {...props} />
                  ) : (
                      <Redirect to={ROOT} />
                  )
              }
          />
        <Route
          exact
          path={USER_ADD}
          render={(props) =>
              user?.role?.description === "ADMIN" ? (
                  <SignUpForm {...props} />
              ) : (
                  <Redirect to={ROOT} />
              )
          }
        />
        {/* List a generic 404-Not Found route here */}
        <Route path={DEFAULT} component={NotFound} />
      </Switch>
      {/* <section>
            <Footer></Footer>
        </section> */}
    </div>
  );
};
