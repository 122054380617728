/* eslint-disable */
import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
    messageBox: {
        width: "50%",
        padding: "5px 15px",
        borderRadius: "5px",
        backgroundColor: "#d4edda",
        color: "#155724",
        position: "sticky",
        top: "5px",
        right: 0,
        margin: "8px 0 -30px 15px",
        zIndex: 1000,
      },
      msgError: {
        backgroundColor: "rgb(253, 236, 234)",
        color: "rgb(136, 37, 30)",
      },
      msgWarn: {
        backgroundColor: "rgb(255 152 0 / 22%)",
        color: "black",
      },
      btnInput: {
        backgroundColor: '#e8e8e8',
        width: 'max-content',
        paddingLeft: 14,
        paddingRight: 14,
        '&:hover': {
          backgroundColor: 'rgb(202, 189, 235, 0.7)',
        },
      },
      btnQube: {
        padding: 6,
        borderRadius: 4,
        boxShadow:
          '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
      },
      adornedStart: {
        padding: '0 !important',
      },
      adornedEnd: {
        padding: '0 !important',
      },
      
      /*
      label: {
        fontSize: ".8rem",
        fontWeight: "normal",
        lineHeight: 1,
        marginRight: ".8em",
        color: "rgba(0, 0, 0, 0.54)",
        marginTop: "1.5em"
      },
      */
});
