import React, { useEffect, useState } from "react";
import _ from "lodash";
import { TextField } from "@material-ui/core";
import Table from "components/Table";
import LANG from "global/lang";
import { convertDataToObjects } from "helpers/convertTable";
import ColumnSelectorStyled from "../ColumnSelector";
import Grid from "@material-ui/core/Grid";

export function IntentionExcel(props) {
  const {
    intentions = [],
    intentionPrayers = [],
    attitudes = [],
    onUpdateTable,
    messageOK = '',
    userLangs = [],
    isAdmin
  } = props;
  const [errorSubmit, setErrorSubmit] = useState({ isValid: true });
  const [columnVisible, setColumnVisible] = useState(
      new Array(LANG.length).fill(true)
  );

  useEffect(()=> {
    setErrorSubmit( prev=> ({ ...prev, text: messageOK.split('$')[0] }))
  }, [messageOK])
  const columnas = [
    {
      title: "Item",
      field: "row_name",
      width: "7%",
      editable: "never"
    },
  ].concat(
    LANG.map((l, i) => ({
      title: l.display,
      field: String(l.code),
      hidden: !columnVisible[i],
      editComponent: (p) => (
        <TextField
          label={intentions[1].id[l.code] || (p.rowData.field === "title" || p.rowData.field === "description") ? l.display
            : `Guarde Título y Descripción antes de editar este campo.`}
          fullWidth
          multiline
          disabled={(!isAdmin && !userLangs.includes(l.code.toUpperCase())) || (!isAdmin && (intentions[0].data?.[l.code]?.status && intentions[0].data[l.code].status !== "draft")) || !(intentions[1].id[l.code] || (p.rowData.field === "title" || p.rowData.field === "description"))}
          rows={20}
          defaultValue={p.value || ""}
          onChange={(e) => p.onChange(e.target.value)}
        />
      ),
    }))
  );

  async function validateIntention(arrIntentions) {
    let isValid = true;
    const incomplete = arrIntentions.filter(
      (intention) =>
        (intention.title && !intention.description) ||
        (!intention.title && intention.description)
    );

    if (incomplete.length) {
      isValid = false;
      let langs = incomplete.map((intention) => {
        const lang = _.find(LANG, { code: intention.language });
        return lang.display
      })
      setErrorSubmit({
        isValid: false,
        text: `La Intención para "${langs.join(', ')}" no se guardará. 
        Complete primero Título y Descripción.`,
      });
    } else {
      setErrorSubmit({ isValid: true, text: '' })
    }
    return isValid;
  }

  async function prepareToUpdateTable(tableName, newData, oldData = []) {
    const objIntentions = _.filter(newData, ["entity", "intention"]);
    const arrIntentions = await convertDataToObjects(objIntentions);
    await validateIntention(arrIntentions);

    onUpdateTable(tableName, newData, oldData);
  }

  return (
    <Grid item xs={12}>
      <ColumnSelectorStyled handlerOnChange={setColumnVisible}/>
      <Table
        columnSchema={columnas}
        data={[...intentions, ...intentionPrayers, ...attitudes]}
        onUpdateTable={prepareToUpdateTable}
        onUpdateTableName="intentionTable"
      />
      <Grid container justify="flex-end" style={{color: errorSubmit.isValid ? "green": "red", marginTop: "1em"}}>
        {errorSubmit.text}
      </Grid>
    </Grid>
  );
}
