import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { useStyles } from "./styles";
import Button from "../../../components/Button";
import PrayerCustomEdit from "../../../components/PrayerCustomEdit";
import PrayerMultimedia from "../../../components/PrayerMultimedia";
import Prayer from "../../../components/PrayerStandard";
import PrayerCustom from "../../../components/PrayerCustom";
import Clear from "@material-ui/icons/Clear";
import TextField from "@material-ui/core/TextField";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import moment from "moment";
import Delete from "@material-ui/icons/Delete";
import _ from "lodash";
import DialogAlert from "../../../components/DialogAlert";
import LanguageNavStyled from "../../../components/LanguageNav";
import { ROSARY_EDIT } from "../../../navigation/CONSTANTS";
import {
  validateIsDate,
  validateLength,
  validateNumberMinMax,
  youtubeValidUrl,
  youtubeEmbedUrl,
} from "../../../helpers/validate";

export default function RosaryEditView(props) {
  let mysteryCols = ["joyful", "sorrowful", "glorious", "luminous"];
  let mysteryNames = {
    joyful: "Gozosos",
    sorrowful: "Dolorosos",
    glorious: "Gloriosos",
    luminous: "Luminosos",
  };
  const {
    currentRosary,
    handleBack,
    saveRosary,
    message,
    deleteRosary,
  } = props;
  const [rosary, setRosary] = useState({});
  const [mysteryGroup, setMysteryGroup] = useState({});
  const [steps, setSteps] = useState(
    Array.from({ length: 7 }, () => ({ prayers: [], mystery: [] }))
  );
  const [promisesMultimedia, setPromiseMultimedia] = useState({
    prayers: [],
    mystery: [],
    group: [],
  });
  const [custom, setCustom] = useState({
    open: false,
    title: "",
    description: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });
  const [error, setError] = useState({});
  const [saving, setSaving] = useState(false);
  const [require, setRequire] = useState(false);

  const [prayerMultimedia, setPrayerMultimedia] = useState({
    open: false,
    aud: {},
    img: {},
  });

  useEffect(async () => {
    setRosary(currentRosary);
    if (currentRosary.mysteryGroup && currentRosary.mysteryGroup.length > 0) {
      currentRosary.mysteryGroup.forEach((g) => {
        if (g.is_standard) {
          g.multimedia = g.multimedia.filter((m) => m.type?.code == "AUD");
          if (g.multimedia.length > 1) g.multimedia.length = 1;
        }
      });
      setMysteryGroup(_.keyBy(currentRosary.mysteryGroup, "category"));
    }
    if (currentRosary?.steps && currentRosary.steps.length > 0) {
      let fill = Array.from({ length: 6 - currentRosary.steps.length }, () => ({
        prayers: [],
        mystery: [],
      }));
      setSteps([...currentRosary.steps, ...fill]);
    }
  }, [currentRosary]);

  useEffect(() => {
    if (message) {
      setError(message);
      setSaving(false);
    }
  }, [message]);
  const classes = useStyles();

  const handleRosaryChange = (name, value) => {
    setRosary({ ...rosary, [name]: value });
    setError("");
  };

  const handleEditor = (event, editor) => {
    if (Object.keys(rosary).length > 0) {
      setRosary({
        ...rosary,
        description: editor.getData(),
      });
      setError("");
    }
  };

  const handlePickerChange = (name) => (date) => {
    setRosary({ ...rosary, [name]: moment(date).format("YYYY-MM-DD") });
  };

  const handleDeleteRosary = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    if (rosary.language.code === "ES" && rosary.otherLanguages.length > 0) {
      setError({
        text: "Elimine los otros idiomas antes de eliminar este ",
        msgType: "msgError",
      });
      return;
    }
    if (!id) {
      return;
    }
    deleteRosary(id);
  };

  const editCustomPrayer = (step, type, index, prayer) => {
    setError({});
    setCustom({
      open: true,
      title: prayer.title,
      description: prayer.description,
      video_url: prayer?.video_url || "",
      step: step,
      type: type,
      index: index,
    });
  };

  const editMultimedia = (step, stepType, index, multimedia) => {
    setPrayerMultimedia({
      open: true,
      step: step,
      stepType: stepType,
      index: index,
      image:
        multimedia.length > 0
          ? multimedia.find((media) => media.type.code === "IMG")
          : [],
      audio:
        multimedia.length > 0
          ? multimedia.find((media) => media.type.code === "AUD")
          : [],
    });
  };

  const handlerPrepareMultimedia = (prayerMultimed) => {
    const {
      index,
      step: section,
      stepType,
      multimedia_id,
      ownerType,
      m,
      oldMultimedia,
    } = prayerMultimed;
    const updatePromises = { ...promisesMultimedia };
    updatePromises[stepType].push({
      section,
      index,
      multimedia_id,
      ownerType,
      m,
      oldMultimedia,
    });

    setPromiseMultimedia(updatePromises);
    addTemporalMultimedia(
      {
        id: multimedia_id,
        type: { code: m._type },
        original_name: m.file.file.name,
        saved_name: m.file.name,
      },
      stepType,
      index,
      section
    );

    handleMultimediaClose();
  };

  const addTemporalMultimedia = (value, stepType, order, section) => {
    if (stepType === "group") {
      const editedGroup = { ...mysteryGroup[order] };

      if (!editedGroup.multimedia) editedGroup.multimedia = [];
      const inx = editedGroup.multimedia.findIndex(
        (m) => m.type.code === value.type.code
      );
      if (inx >= 0) editedGroup.multimedia[inx] = value;
      else editedGroup.multimedia.push(value);

      setMysteryGroup({ ...mysteryGroup, [order]: editedGroup });
    } else {
      const state = [...steps];
      const updated = [...state[section][stepType]];

      if (!updated[order].multimedia) updated[order].multimedia = [];
      const inx = updated[order].multimedia.findIndex(
        (m) => m.type.code === value.type.code
      );
      if (inx >= 0) updated[order].multimedia[inx] = value;
      else updated[order].multimedia.push(value);

      state[section] = { ...state[section], [stepType]: updated };
      setSteps(state);
    }
  };

  const handleMultimediaClose = () => {
    setPrayerMultimedia({
      open: false,
      step: null,
      stepType: null,
      index: null,
      image: [],
      audio: [],
    });
  };

  const handleCustomClose = () =>
    setCustom({ open: false, title: "", description: "" });

  const handlePrayerChange = (event, order, section) => {
    if (event.target.name === "quantity") {
      if (!validateNumberMinMax(event.target.value, 1, 30)) return false;
    }

    let state = [...steps];
    let prayerUpdate = [...state[section].prayers];
    prayerUpdate[order] = {
      ...prayerUpdate[order],
      [event.target.name]: event.target.value,
    };
    state[section] = { ...state[section], prayers: prayerUpdate };
    setSteps(state);
  };

  const handlePrayerDelete = (order, section) => {
    deletePrayer(section, order, 1);
  };

  //Delete 4 columns custom prayer
  const handleCustomDelete = (order, section) => {
    deletePrayer(section, order - 3, 4);
  };

  const deletePrayer = (section, position, count) => {
    let prayerUpdate = [...steps[section].prayers];
    if (prayerUpdate.length > count) {
      prayerUpdate.splice(position, count);
      let state = [...steps];
      state[section] = { ...steps[section], prayers: prayerUpdate };
      setSteps(state);
      setError({});
    } else {
      setError({
        text: "No se puede quitar la única oración de la sección  ",
        msgType: "msgError",
      });
    }
  };

  const addPrayer = (section) => {
    let state = [...steps];
    const count = state[section] ? state[section].prayers.length + 1 : 0;
    let newPrayer = {
      id: 1,
      title: "",
      description: "",
      multimedia: [],
      quantity: 1,
      order: count,
      is_standard: true,
    };
    state[section].prayers = [...state[section].prayers, newPrayer];
    setSteps(state);
  };

  const addCustom = (section, singleDay) => {
    let oPrayer = {
      title: "Nueva oración",
      description: "",
      multimedia: [],
      quantity: 1,
      is_standard: false,
    };
    let state = [...steps];
    let count = state[section] ? state[section].prayers.length + 1 : 0;
    let newCustom = [];
    if (singleDay) {
      newCustom.push({
        ...oPrayer,
        order: count++,
      });
    } else {
      newCustom = mysteryCols.map((mystery) => ({
        ...oPrayer,
        order: count++,
        category: mystery,
      }));
    }

    state[section].prayers = [...state[section].prayers, ...newCustom];
    setSteps(state);
  };

  const handleCustomSave = (title, description, section, type, video) => {
    if (type === "group") {
      let editedGroup = { ...mysteryGroup[custom.index] };
      editedGroup.title = title;
      editedGroup.description = description;
      if (video) {
        if (!youtubeValidUrl(video))
          setError({
            text: `Link YouTube inválido (referencia: "${video}")`,
            msgType: "msgError",
          });
        else editedGroup.video_url = youtubeEmbedUrl(video);
      }
      setMysteryGroup({ ...mysteryGroup, [custom.index]: editedGroup });
    } else {
      let state = [...steps];
      let newStep = [...state[section][type]];

      let editedMystery = { ...newStep[custom.index] };
      editedMystery.title = title;
      editedMystery.description = description;
      if (type === "mystery" && newStep[custom.index].is_standard) {
        delete editedMystery.id;
        editedMystery.is_standard = false;
      }
      newStep[custom.index] = editedMystery;
      state[section][type] = newStep;
      setSteps(state);
    }
    setCustom({ open: false, title: "", description: "" });
  };

  const handleSubmitRosary = () => {
    let contentMystery = steps
      .map((step) => step.mystery)
      .find((m) => m.title === "");
    let contentPrayer = steps
      .map((step) => step.prayers)
      .find((m) => m.title === "" && m.is_standard === false);
    if (
      contentPrayer ||
      contentMystery ||
      rosary.title === "" ||
      rosary.description === ""
    ) {
      setError({
        text: "Complete todos los campos requeridos por favor ",
        msgType: "msgError",
      });
      setRequire(true);
      return;
    } else {
      setRequire(false);
    }

    if (validateLength(rosary.description, 2500)) {
      setError({
        text: `La descripción tiene ${rosary.description.length} caracteres. Debe contener menos de 2500`,
        msgType: "msgError",
      });
      return;
    }

    if (!validateIsDate(rosary.show_date)) {
      setError({
        text: `Seleccione una fecha`,
        msgType: "msgError",
      });
      return;
    }

    steps.group = Object.values(mysteryGroup);
    setSaving(true);
    if (rosary.title) {
      saveRosary(rosary, steps, promisesMultimedia);
    }
  };

  let mysteryGroups = mysteryCols.map((colName, index) => {
    return (
      <PrayerCustom
        key={`g${colName}${index}`}
        order={colName}
        step={null}
        custom={mysteryGroup[colName]}
        editCustomPrayer={editCustomPrayer}
        editMultimedia={editMultimedia}
        type="group"
      />
    );
  });

  let sections = { s0: [], s1: [], s2: [], s3: [], s4: [], s5: [], s6: [] };
  //Mystery 4 cols
  if (steps.length > 0) {
    steps.forEach((step, indStep) => {
      if (step.mystery.length > 0) {
        //Mysteries
        let mysteryCol = [];
        mysteryCols.forEach((colName, index) => {
          let misterColData = step.mystery.find(
            (mistery) => colName === mistery.category
          );
          mysteryCol.push(
            <PrayerCustom
              key={`${colName}$(index)`}
              order={index}
              step={indStep}
              custom={misterColData}
              editCustomPrayer={editCustomPrayer}
              editMultimedia={editMultimedia}
              type="mystery"
            />
          );
        });
        sections["s" + indStep].push(
          <Grid container key={"section" + indStep}>
            {mysteryCol}
          </Grid>
        );
      }
      //Prayers
      if (step.prayers.length > 0) {
        let prayRow = [];
        let prayCol = [];
        step.prayers.forEach((pray, index) => {
          if (pray.is_standard) {
            if (prayCol.length > 3) {
              prayRow.push(
                <Grid container key={index}>
                  {prayCol}
                  <div
                    className={classes.col2}
                    onClick={() => handleCustomDelete(index, indStep)}
                  >
                    <Clear />
                  </div>
                </Grid>
              );
              prayCol = [];
            }
            prayRow.push(
              <Grid item xs={12} key={index}>
                <Prayer
                  language={rosary.language?.code}
                  prayer={pray.id}
                  qty={pray.quantity}
                  order={index}
                  section={indStep}
                  handlePrayerChange={handlePrayerChange}
                  handlePrayerDelete={handlePrayerDelete}
                />
              </Grid>
            );
          } else {
            if (pray?.category) {
              mysteryCols.forEach((colName) => {
                if (colName === pray.category) {
                  prayCol.push(
                    <PrayerCustom
                      key={`${colName}$(index)`}
                      order={index}
                      step={indStep}
                      custom={pray}
                      editCustomPrayer={editCustomPrayer}
                      editMultimedia={editMultimedia}
                      type="prayers"
                    />
                  );
                }
              });
            } else {
              //Custom same all columns
              prayRow.push(
                <Grid container key={index} className={classes.row}>
                  <PrayerCustom
                    key={`${pray.id}$(index)`}
                    order={index}
                    step={indStep}
                    custom={pray}
                    editCustomPrayer={editCustomPrayer}
                    editMultimedia={editMultimedia}
                    type="prayers"
                  />
                  <div
                    className={classes.clearCustom}
                    onClick={() => handlePrayerDelete(index, indStep)}
                  >
                    <Clear />
                  </div>
                </Grid>
              );
            }
          }
          if (prayCol.length > 3) {
            //Custom 4 columns
            prayRow.push(
              <Grid container key={index} className={classes.row}>
                {prayCol}
                <div
                  className={classes.clearCustom}
                  onClick={() => handleCustomDelete(index, indStep)}
                >
                  <Clear />
                </div>
              </Grid>
            );
            prayCol = [];
          }
        });
        sections["s" + indStep].push(
          <Grid container key={"section2" + indStep}>
            {prayRow}
          </Grid>
        );
      }
    });
  }

  let colHeaders = mysteryCols.map((name) => {
    return (
      <Grid key={name} item xs={3} className={classes.colName}>
        {mysteryNames[name]}
      </Grid>
    );
  });

  let rowSection = [
    "Introducción",
    "Misterio 1",
    "Misterio 2",
    "Misterio 3",
    "Misterio 4",
    "Misterio 5",
    "Final",
  ].map((sectionTitle, idx) => {
    return (
      <li key={idx} className={classes.section}>
        <h3>{sectionTitle} </h3>
        <Grid container>{colHeaders}</Grid>
        {sections["s" + idx]}
        <div className={classes.sectionBtn}>
          <Button onClick={() => addPrayer(idx)}>+ BASICA</Button>
          <Button onClick={() => addCustom(idx, true)}>
            + ORACION PARA LA SEMANA
          </Button>
          <Button onClick={() => addCustom(idx, false)}>
            + ORACION POR GRUPO
          </Button>
        </div>
      </li>
    );
  });
  return (
    <Grid container data-testid="rosary-edit-view">
      {error?.text && (
        <div className={`${classes.messageBox} ${classes[error.msgType]}`}>
          {error.text}
        </div>
      )}
      <Grid item xs={9} style={{ padding: 15 }}>
        <Grid container>
          <Grid item xs={2}>
            <h2>Rosario</h2>
          </Grid>
          <Grid item xs={8}>
            <LanguageNavStyled
              language={rosary.language}
              pathEdit={ROSARY_EDIT}
              otherLanguages={rosary.otherLanguages}
            />
          </Grid>
        </Grid>
        <div>
          <TextField
            label="Titulo"
            name="title"
            required
            variant="filled"
            size="small"
            fullWidth
            margin="normal"
            value={rosary?.title || ""}
            onChange={(e) => handleRosaryChange(e.target.name, e.target.value)}
            InputLabelProps={{ shrink: true }}
            error={!rosary.title && require}
            InputProps={{
              inputProps: {
                maxLength: 250,
              },
            }}
          />
          <div
            style={{
              border: require && !rosary.description ? "1px solid red" : "",
            }}
          >
            <CKEditor
              editor={ClassicEditor}
              data={rosary?.description || ""}
              config={{
                toolbar: ["bold", "italic", "blockQuote", "undo"],
                link: {
                  addTargetToExternalLinks: true
                }
              }}
              name="description"
              onReady={(editor) => {
                editor.editing.view.change((writer) => {
                  writer.setStyle(
                    "height",
                    "300px",
                    editor.editing.view.document.getRoot()
                  );
                });
              }}
              onChange={(event, editor) => handleEditor(event, editor)}
            />
          </div>
        </div>
      </Grid>
      <Grid item xs={3} style={{ padding: "30px 0" }}>
        <Button
          text={!saving ? "Guardar" : "Procesando...."}
          disabled={saving}
          type="primary"
          onClick={handleSubmitRosary}
          style={{ marginRight: 10 }}
          data-testid="btn-save-rosary-edit"
        />
        <Button
          text="Volver"
          onClick={handleBack}
          id="btn_back_rosary"
          data-testid="btn_back_rosary"
          variant="outlined"
        />
        <Button
          text="Eliminar"
          onClick={() => {
            setConfirmDialog({
              isOpen: true,
              title: "¿Desea eliminar este registro?",
              subtitle: "Eliminar",
              onConfirm: () => {
                handleDeleteRosary(rosary.id);
              },
            });
          }}
          id="btn_del_rosary"
          data-testid="btn_del_rosary"
          variant="text"
          color="secondary"
          icon={<Delete />}
          style={{ float: "right" }}
        />
        <div>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <DatePicker
              openTo="month"
              format="dd-MM-yyyy"
              label="Mostrar"
              views={["year", "month", "date"]}
              value={moment(rosary.show_date).toISOString() || null}
              onChange={handlePickerChange("show_date")}
              inputVariant="filled"
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              name="show_date"
              style={{ width: "160px", marginRight: "10px" }}
              required
            />
          </MuiPickersUtilsProvider>
        </div>
        <div>
          <FormControl component="fieldset">
            <FormLabel component="legend" className={classes.label}>
              Momento
            </FormLabel>
            <RadioGroup
              row
              aria-label="moment"
              name="moment"
              value={rosary?.moment || "MOR"}
              onChange={(event) =>
                handleRosaryChange(event.target.name, event.target.value)
              }
            >
              <FormControlLabel
                value="MOR"
                control={<Radio />}
                label="Mañana"
              />
              <FormControlLabel value="AFT" control={<Radio />} label="Tarde" />
              <FormControlLabel value="EVE" control={<Radio />} label="Noche" />
            </RadioGroup>
          </FormControl>
        </div>
        <div>
          <FormControl component="fieldset">
            <FormLabel component="legend" className={classes.label}>
              Estado
            </FormLabel>
          <FormControlLabel
            control={
              <Checkbox
                checked={rosary?.is_published || false}
                onChange={(event) =>
                  handleRosaryChange(event.target.name, event.target.checked)
                }
                name="is_published"
                color="primary"
              />
            }
            label="Publicado"
          />
          </FormControl>
        </div>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={rosary?.showing_home || false}
                onChange={(event) =>
                  handleRosaryChange(event.target.name, event.target.checked)
                }
                name="showing_home"
                color="default"
                size="small"
              />
            }
            label="Ver en home"
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={rosary?.showing_tab || false}
                onChange={(event) =>
                  handleRosaryChange(event.target.name, event.target.checked)
                }
                name="showing_tab"
                color="default"
                size="small"
              />
            }
            label="Ver en Tab"
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <ul className={classes.sectionBox}>
          <li className={classes.section}>
            <h3>Grupo de Misterios</h3>
            <Grid container>{mysteryGroups}</Grid>
          </li>
          {rowSection}
        </ul>
      </Grid>
      <PrayerCustomEdit
        prayer={custom}
        handleCustomClose={handleCustomClose}
        handleCustomSave={handleCustomSave}
      />
      <PrayerMultimedia
        prayerMultimedia={prayerMultimedia}
        handleMultimediaClose={handleMultimediaClose}
        handlerPrepareMultimedia={handlerPrepareMultimedia}
      />
      <DialogAlert
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Grid>
  );
}
