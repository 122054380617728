import React, {useEffect, useState} from 'react';
import Audiotrack from "@material-ui/icons/Audiotrack";
import { useStyles } from "./styles";
import Button from "../Button";
import {Grid, Modal} from "@material-ui/core";
import {addMultimedias, deleteMultimedia, resetPrayersCache} from "../../services/services";
import Delete from "@material-ui/icons/Delete";

const PrayerAudioStyled = (props) => {
  const {prayersData, updatePrayers} = props;
  const classes = useStyles();

  const [audioGrid, setAudioGrid] = useState([]);
  const [multimedia, setMultimedia] = useState({open: false, id: null});
  const [status, setStatus] = useState({loading: false, error: false, text: ""});

  useEffect(() => {
    if(prayersData) {
      setData(prayersData);
    }
  }, [prayersData]);

  const setData = (data) => {
    let tableRow = [];
    data.map((prayerRow, key) => {
      tableRow.push(
        <tr key={key}>
          <td className={classes.partial}>{prayerRow.row_name}</td>
          <td>{prayerRow.id['en'] && mediaBtn(prayerRow.data['en'])}</td>
          <td>{prayerRow.id['es'] && mediaBtn(prayerRow.data['es'])}</td>
          <td>{prayerRow.id['fr'] && mediaBtn(prayerRow.data['fr'])}</td>
          <td>{prayerRow.id['zh'] && mediaBtn(prayerRow.data['zh'])}</td>
          <td>{prayerRow.id['it'] && mediaBtn(prayerRow.data['it'])}</td>
          <td>{prayerRow.id['pt'] && mediaBtn(prayerRow.data['pt'])}</td>
          <td>{prayerRow.id['de'] && mediaBtn(prayerRow.data['de'])}</td>
        </tr>);
    });
    setAudioGrid(tableRow);
  }

  let mediaBtn = (pry) => {
    const multimedia = pry?.multimedia.filter(multimedia => multimedia.type.code === 'AUD');
    if (multimedia) {
      return (
        <div
          className={pry?.multimedia[0] ? classes.active : classes.media}
          onClick={() => addMultimedia(pry?.id, pry?.multimedia[0])}
        >
          <Audiotrack /> {pry?.multimedia[0]?.original_name}
        </div>
      );
    }
  };

  const addMultimedia = (id, oldMultimedia) => {
    setMultimedia({open: true, id: id, replace: oldMultimedia?.id, name: oldMultimedia?.original_name});
  }

  const handleAudioChange = (event) => {
    let file = event.target.files[0];
    if (file) {
      let reader = new FileReader();
      reader.onload = function () {
        const newFile = {
          name: file.name,
          file: file,
          type: "AUD",
        };
        setMultimedia({ ...multimedia, audio: { original_name: file.name, newFile } });
      };
      reader.readAsArrayBuffer(file);
    }
  }

  const handleSave = () => {
    if (multimedia.audio?.newFile?.name) {
      setStatus({text: "", error: false, loading: true});
      const formData = new FormData();
      const fileName = multimedia.audio.newFile.file.name;

      formData.append(fileName, multimedia.audio.newFile.file);
      let metadata = [{
        name: fileName,
        type: 'AUD',
        owners: [{'owner': multimedia.id, 'ownerType': 'pra'}],
      }];

      //Replace current multimedia
      if (multimedia?.replace) {
        metadata[0].replace = multimedia.replace;
      }
      formData.append("metadata", JSON.stringify(metadata));

      addMultimedias(formData)
        .then((res)=> {
          if (res?.inserted.length > 0) {
            setStatus({text: `Archivo subido: ${fileName}` , error: false, loading: false});
            setMultimedia({
              ...multimedia,
              id: null,
              audio: {},
              replace: undefined,
              name: ""
            });
            updatePrayers();
            resetCache();
          } else {
            displayError();
          }
        })
        .catch((err) => {
          console.warn(err);
          displayError()
        }
      );
    }
  }

  function displayError(msgError = ""){
    setStatus({error: true, text: `Error al procesar los datos. Intente luego. ${msgError}`, loading: false});
  }

  const handleDelete = () => {
    if (multimedia) {
      setStatus({text: "", error: false, loading: true});
      deleteMultimedia([{ "multimedia_id": multimedia.replace, "owner": multimedia.id, "ownerType": "PRA" }])
        .then(()=>{
          setStatus({text: "Archivo borrado", error: false, loading: false});
          setMultimedia({...multimedia,
            id: null,
            audio: {},
            replace: undefined,
            name: ""
          });
          updatePrayers();
          resetCache();
        })
        .catch((err) => {
          console.warn(err);
          displayError()
        });
    }
  }

  function resetCache() {
    resetPrayersCache().then((res) => {
      console.log("prayers cache deleted", res);
    })
      .catch(console.warn);
  }

  const handleMultimediaClose = () => {
    setMultimedia({
      open: false,
      id: null,
      audio: {},
      replace: undefined,
      name: ""
    });
    setStatus({text: "", error: false, loading: false});
  };

  return (
    <div>
      <table className={classes.grids}>
        <thead>
        <tr>
          <th>Audios</th>
          <th>EN</th>
          <th>ES</th>
          <th>FR</th>
          <th>ZH</th>
          <th>IT</th>
          <th>PT</th>
          <th>DE</th>
        </tr>
        </thead>
        <tbody>
          {audioGrid}
        </tbody>
      </table>
      <Modal
        open={multimedia.open}
        onClose={handleMultimediaClose}
        aria-labelledby="prayer-multimedia-title"
        aria-describedby="prayer-multimedia-description"
      >
        <div className={classes.paper}>
          <Grid container>
            {status?.text && (<div className={`${classes.messageBox} ${classes[status.error ? "msgError" : ""]}`}>{status?.text}</div>)}
            <Grid item xs={7}>
              <input
                accept="audio/mpeg"
                className={classes.hide}
                id="multimedia-prayer-audio"
                type="file"
                onChange={(event) =>
                  handleAudioChange(event)
                }
              />
              <label htmlFor="multimedia-prayer-audio">
                <Button
                  variant="contained"
                  color="default"
                  component="span"
                  size="small"
                  css={classes.btnUpload}
                  startIcon={<Audiotrack />}
                >
                  Seleccionar Audio
                </Button>
              </label>
              <div>{multimedia?.audio?.original_name || "Ningún elemento de audio seleccionado."}</div>
            </Grid>
            <Grid item xs={5} style={{ textAlign: "right" }}>
            {multimedia?.name && (
              <>
              <Button
                text="Eliminar"
                onClick={handleDelete}
                variant="text"
                color="secondary"
                icon={<Delete />}
                disabled={status?.loading}
              />
                <div style={{color: "#4292E0"}}>{multimedia?.name}</div>
              </>
            )}
            </Grid>
          </Grid>
          <div style={{ marginTop: "20px" }}>
            <Button
              text={status?.loading ? "Procesando..." : "Guardar"}
              disabled={status?.loading}
              type="primary"
              onClick={handleSave}
            />
            <Button
              text={"Cerrar"}
              type="secondary"
              onClick={handleMultimediaClose}
              style={{ marginLeft: "10px" }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PrayerAudioStyled;
