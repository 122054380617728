import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { useStyles } from "./styles";
import Button from "../../../components/Button";
import TextField from "@material-ui/core/TextField";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import moment from "moment";
import DialogAlert from "../../../components/DialogAlert";
import LanguageNavStyled from "../../../components/LanguageNav";
import { ROSARY_EDIT } from "../../../navigation/CONSTANTS";
import { validateLength, validateIsDate } from "../../../helpers/validate";

export default function RosaryAddView(props) {
  const { currentRosary, handleBack, saveRosary, message, language } = props;
  const [rosary, setRosary] = useState({
    title: "",
    description: "",
    moment: "MOR",
    show_date: moment().format("YYYY-MM-DD"),
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });
  const [error, setError] = useState({});
  const [saving, setSaving] = useState(false);
  const [require, setRequire] = useState(false);

  useEffect(async () => {
    setRosary({ ...rosary, ...currentRosary });
  }, [currentRosary]);

  useEffect(() => {
    if (message) {
      setError(message);
      setSaving(false);
    }
  }, [message]);
  const classes = useStyles();

  const handleRosaryChange = (name, value) => {
    setRosary({ ...rosary, [name]: value });
    setError("");
  };

  const handleEditor = (event, editor) => {
    if (Object.keys(rosary).length > 0) {
      setRosary({
        ...rosary,
        description: editor.getData(),
      });
      setError("");
    }
  };

  const handlePickerChange = (name) => (date) => {
    setRosary({ ...rosary, [name]: moment(date).format("YYYY-MM-DD") });
  };

  const handleSubmitRosary = () => {
    if (
      !rosary?.title ||
      rosary.title === "" || rosary.description === ""
    ) {
      setError({
        text: "Complete los campos requeridos por favor (aquellos marcados con *) ",
        msgType: "msgError",
      });
      setRequire(true)
      return;
    }
    if (validateLength(rosary.description, 2500)) {
      setError({
        text: `La descripción tiene ${rosary.description.length} caracteres. Debe contener menos de 2500`,
        msgType: "msgError",
      });
      return;
    }

    if (!validateIsDate(rosary.show_date)) {
      setError({
        text: `Seleccione una fecha`,
        msgType: "msgError",
      });
      return;
    }

    setRequire(false)
    setSaving(true);
    if (rosary.title) {
      saveRosary(rosary);
    }
  };

  return (
    <Grid container data-testid="rosary-edit-view">
      {error?.text && (
        <div className={`${classes.messageBox} ${classes[error.msgType]}`}>
          {error.text}
        </div>
      )}
      <Grid item xs={9} style={{ padding: 15 }}>
        <Grid container>
          <Grid item xs={2}>
            <h2>Rosario</h2>
          </Grid>
          <Grid item xs={8}>
            <LanguageNavStyled
              language={rosary.language || language}
              pathEdit={ROSARY_EDIT}
              otherLanguages={rosary.otherLanguages}
            />
          </Grid>
        </Grid>
        <div>
          <TextField
            label="Titulo"
            name="title"
            required
            variant="filled"
            size="small"
            fullWidth
            margin="normal"
            value={rosary?.title || ""}
            onChange={(e) => handleRosaryChange(e.target.name, e.target.value)}
            InputLabelProps={{ shrink: true }}
            error={require && !rosary.title}
          />
          <div style={{border: require && !rosary.description ? '1px solid red': ''}}>
            <CKEditor
              editor={ClassicEditor}
              data={""}
              config={{
                toolbar: ["bold", "italic", "blockQuote", "undo"],
                link: {
                  addTargetToExternalLinks: true
                }
              }}
              name="description"
              onReady={(editor) => {
                editor.editing.view.change((writer) => {
                  writer.setStyle(
                    "height",
                    "300px",
                    editor.editing.view.document.getRoot()
                  );
                });
              }}
              onChange={(event, editor) => handleEditor(event, editor)}
            />
        </div>
        </div>
      </Grid>
      <Grid item xs={3} style={{ padding: "30px 0" }}>
        <Button
          text={!saving ? "Guardar" : "Procesando...."}
          disabled={saving}
          type="primary"
          onClick={handleSubmitRosary}
          style={{ marginRight: 10 }}
          data-testid="btn-save-rosary-edit"
        />
        <Button
          text="Volver"
          onClick={handleBack}
          id="btn_back_rosary"
          data-testid="btn_back_rosary"
          variant="outlined"
        />

        <div>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <DatePicker
              openTo="month"
              format="dd-MM-yyyy"
              label="Mostrar"
              views={["year", "month", "date"]}
              value={moment(rosary.show_date).toISOString() || null}
              onChange={handlePickerChange("show_date")}
              inputVariant="filled"
              required
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              name="show_date"
              style={{ width: "160px", marginRight: "10px" }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div>
          <FormControl component="fieldset">
            <FormLabel component="legend" className={classes.label}>
              Momento
            </FormLabel>
            <RadioGroup
              row
              aria-label="moment"
              name="moment"
              value={rosary?.moment || "MOR"}
              onChange={(event) =>
                handleRosaryChange(event.target.name, event.target.value)
              }
            >
              <FormControlLabel
                value="MOR"
                control={<Radio />}
                label="Mañana"
              />
              <FormControlLabel value="AFT" control={<Radio />} label="Tarde" />
              <FormControlLabel value="EVE" control={<Radio />} label="Noche" />
            </RadioGroup>
          </FormControl>
        </div>
      </Grid>
      <DialogAlert
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Grid>
  );
}
