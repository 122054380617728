import React,{useEffect} from "react";
import { BrowserRouter } from "react-router-dom";
import { RouterConfig } from "navigation/RouterConfig";
import { getUser, updateReducer } from "services/authService";
import { useDispatch } from "react-redux";
 

function App() {
  const disptach =useDispatch()

  useEffect(()=>{
 if (getUser()) {
      updateReducer(disptach);
    }
  },[])
  return (
    <div  data-testid={`app`}>
      <BrowserRouter>
        <RouterConfig />
      </BrowserRouter>
    </div>
  );
}

export default App;
