import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { MEDIATION_EDIT } from "../../navigation/CONSTANTS";
import MediationView from "./MediationView";
import LANG from "global/lang";
import { getReported } from "../../services/services";
import _ from "lodash";
import moment from "moment";

export function MediationContainer({ location }) {
  const [reported, setReported] = useState([]);
  const [reportedStatus, setReportedStatus] = useState(false);
  const history = useHistory();
  const message = {
    text: location.state?.message || "",
  };
  useEffect(async () => {
    fetchReported();
  }, []);

  function fetchReported(status) {
    getReported(_.map(LANG, "code"), status ? undefined : 'unresolved')
      .then(async (response) => {
        if (response.length > 0) {
          setReported(setData(response));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function setData(reportedArr) {
    let data = [];
    reportedArr.forEach((reportedData) => {
      let oData = {};
      oData.id = reportedData.id;
      oData.type = reportedData.reported_type;
      oData.date = moment(reportedData.reported_at).format("DD-MM HH:mm");
      oData.user = reportedData.user?.first_name
        ? `${reportedData.user.first_name} ${reportedData.user.last_name}`
        : '--Usuario borrado--';
      oData.reporter = reportedData.reported_by?.first_name
        ? `${reportedData.reported_by.first_name} ${reportedData.reported_by.last_name}`
        : '--Usuario borrado--';

      oData.status = reportedData.reported_status;
      oData.commId =
        reportedData.reported_type === "comment" ? reportedData.id : "";
      //Se esta usando los type de amen_owner_types?
      oData.typeItem =
        reportedData.reported_type === "comment"
          ? Number(reportedData.prayer_type) === 9 || Number(reportedData.prayer_type) === 8
            ? "commentPope"
            : "commentComm"
          : "communityPrayer";
      oData.title = reportedData.title;
      data.push(oData);
    });
    return data;
  }

  function handleEdit(id, type) {
    history.push(`${MEDIATION_EDIT}/${id}/${type}`);
  }

  function handleReportedStatus(event){
    setReportedStatus(event.target.checked);
    fetchReported(event.target.checked);
  }

  return (
    <MediationView
      message={message}
      reported={reported}
      handleEdit={handleEdit}
      handleReportedStatus={handleReportedStatus}
      reportedStatus={reportedStatus}
    />
  );
}
