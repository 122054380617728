import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Search from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Clear from "@material-ui/icons/Clear";
import Button from "../../components/Button";
import { CAMPAIGN_ADD } from "../../navigation/CONSTANTS";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";
import { useStyles } from "./styles";
import _ from "lodash";
import CAMPAIGN_TYPES from "global/types_campaign";

const CampaignView = (props) => {
  const { campaigns, message } = props;

  const tableIcons = {
    Edit: EditIcon,
    Delete: DeleteIcon,
    SortArrow: KeyboardArrowUpIcon,
    Add: AddCircleIcon,
    PreviousPage: ChevronLeft,
    NextPage: ChevronRight,
    Search: Search,
    LastPage: LastPage,
    FirstPage: FirstPage,
    Filter: FilterList,
    Clear: Clear,
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [error, setError] = useState({ message });
  const types = _.zipObject(
    _.map(CAMPAIGN_TYPES, "id"),
    _.map(CAMPAIGN_TYPES, "description_es")
  );

  const history = useHistory();
  const handleNewBtn = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const classes = useStyles();

  useEffect(() => {
    if (message) {
      setError(message);
    }
  }, [message]);

  const handleNewOption = (typeId) => {
    setAnchorEl(null);
    if (typeId !== "") {
      history.push(`${CAMPAIGN_ADD}/${typeId}/es`);
    }
  };

  return (
    <div>
      <div data-testid="campaign_view"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "10px 20px",
        }}
      >
        <Button
          aria-controls="new-campaign-btn"
          aria-haspopup="true"
          onClick={handleNewBtn}
          data-testid="new-campaign-btn"
        >
          Nuevo
        </Button>
        <Menu
          id="new-campaign-menu"
          data-testid="new-campaign-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => handleNewOption("")}
        >
          <MenuItem onClick={() => handleNewOption("SCH")}>
            Calendarizada
          </MenuItem>
          <MenuItem onClick={() => handleNewOption("UNS")}>
            No Calendarizada
          </MenuItem>
          <MenuItem onClick={() => handleNewOption("TIP")}>Consejo</MenuItem>
          <MenuItem onClick={() => handleNewOption("PRO")}>Proyecto</MenuItem>
        </Menu>
      </div>
      <div>
        {error?.text && (
          <div className={`${classes.messageBox} ${classes[error.msgType]}`}>
            {error.text}
          </div>
        )}
        <MaterialTable
          title="Campa&ntilde;as"
          icons={tableIcons}
          columns={[
            { title: "Id", field: "id", hidden: true },
            { title: "TITULO", field: "title" },
            {
              title: "TIPO",
              field: "type.id",
              width: "5%",
              lookup: types,
            },
            { title: "MOSTRAR", field: "show_date", width: "4%" },
            { title: "OCULTAR", field: "hide_date", width: "4%" },
            { title: "INICIA", field: "date_from", width: "4%" },
            { title: "FINALIZA", field: "date_to", width: "4%" },
            { title: "HOME", field: "showing_home", width: "2%", lookup: {true: "SI"} },
            { title: "TAB", field: "showing_tab", width: "2%" , lookup: {true: "SI"} },
            {
              title: "LENGUAJES",
              field: "langs",
              filtering: false,
              render: (rowData) => <div>{rowData.langs}</div>,
            },
          ]}
          data={campaigns.data}

          options={{
            search: false,
            actionsColumnIndex: -1,
            filtering: true,
            pageSize: 20,
            headerStyle: {
              backgroundColor: "#596386",
              color: "#FFF",
              padding: "5px 10px",
            },
            cellStyle: {
              fontSize: ".8em",
              padding: "5px 10px",
            },
            filterCellStyle: { padding: "2px" },
          }}
          localization={{
            header: {
              actions: "Acciones",
            },
          }}
        />
      </div>
    </div>
  );
};

export default CampaignView;
