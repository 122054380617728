import React, { useEffect, useState } from "react";
import RosaryAddView from "./RosaryAddView";
import { useParams, useHistory } from "react-router-dom";
import {
  getRosaries,
  upsertRosary,
} from "services/services";
import { ROSARY, ROSARY_EDIT } from "../../../navigation/CONSTANTS";
import { humanizeProps } from "../../../helpers/humanizeResponse";
import crypto from 'crypto';


export function RosaryAddContainer() {
  const { lang, groupId } = useParams();
  const [rosary, setRosary] = useState({});
  const [message, setMessage] = useState({
    text: location.state?.message ? location.state.message : "",
  });
  const history = useHistory();

  useEffect(() => {
    if (groupId) {
      fetchRosary(groupId);
    }
  }, []);

  function fetchRosary(groupId) {
    getRosaries(groupId).then((data) => {
      if (!data) return history.push(ROSARY);
      const {show_date, moment} = data.data[0];
      setRosary({show_date, moment: moment.code, group: groupId});
    });
  }

  function handleBack() {
    history.push(ROSARY);
  }

  async function saveRosary(rosary) {
    rosary.language = lang;
    rosary.hide_date = rosary.show_date;
    rosary.is_published = false;
    rosary.group = groupId || crypto.randomBytes(20).toString('hex');//TODO: generate group?
    upsertRosary(rosary)
      .then((savedRosary) => {
        if (savedRosary) history.push(`${ROSARY_EDIT}/${savedRosary[0]}/${lang}`,
          { message: "Rosario básico creado. Puede continuar con la carga de contenido." });
      })
      .catch((err) =>
        setMessage({
          text: humanizeProps(err.response?.data?.message),
          msgType: "msgError",
        })
      );
  }

  return (
    <RosaryAddView
      currentRosary={rosary}
      message={message}
      handleBack={handleBack}
      saveRosary={saveRosary}
      language={lang}
    />
  );
}
