export function modalReducer(
  state = {
    error: { open: false, message: "" },
  },
  action
) {
  switch (action.type) {
    case "@modal/error":
      return { ...state, error: action.payload };
    default:
      return state;
  }
}
