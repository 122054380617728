import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "components/Button";

import { useStyles } from "./styles";
export function MultimediaStyled(props) {
  const { handleSubmit, filesProps = [], isAdmin, messageOK = '' } = props;
  const [files, setFiles] = useState([]);
  const [errorSubmit, setErrorSubmit] = useState({ isValid: true });
  useEffect(() => {
    setFiles(filesProps);
  }, [filesProps]);

  useEffect(()=> {
    setErrorSubmit( prev=> ({ ...prev, text: messageOK.split('$')[0] }))
  }, [messageOK])

  function handleChangeInput(event, type, id_replace) {
    setErrorSubmit( {text: "", isValid: true  });
    if (event.target.files[0].type === "image/svg+xml") {
      if (event.target.files[0]) {
        let reader = new FileReader();
        reader.onload = function () {
            const newFiles = files.map((file) => {
              if (file.id_type === type)
                return {
                  ...file,
                  url: URL.createObjectURL(event.target.files[0]),
                  file: event.target.files[0],
                  replace: id_replace,
                };
              else return file;
            });

          setFiles(newFiles);
          //TO DO: validate on publish
          //setDisabled(newFiles.find((file) => !file.url) !== undefined);
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    } else window.alert("Seleccione una imagen con formato .svg");
  }
  const classes = useStyles();
  return (
    <Grid
      className={classes.contentMultimedia}
      container
      data-testid="multimedias"
    >
      <Grid item xs={12} lg={12} md={12} sm={12}>
        <form className={classes.formContent}>
          <div>
            <div className={classes.contentButtonLabel}>
              <label>Home Web</label>
              { isAdmin && (
              <Button
                variant="contained"
                component="label"
                type="secondary"
                css={classes.buttons}
                icon={
                  <div>
                    Load Image Home Web
                    <input
                      data-testid="input_file_0"
                      type="file"
                      accept=".svg"
                      hidden
                      onChange={(event) =>
                        handleChangeInput(event, "IMG_INT_HME_WEB", files[0]?.id)
                      }
                    />
                  </div>
                }
              />
              )}
            </div>
            {files[0]?.url && (
              <div className={classes.image}>
                <img src={files[0].url} />
              </div>
            )}
          </div>
          <div>
            <div className={classes.contentButtonLabel}>
              <label>Home mobile</label>
              { isAdmin && (
              <Button
                variant="contained"
                component="label"
                type="secondary"
                css={classes.buttons}
                icon={
                  <div>
                    Load Image Home mobile
                    <input
                      data-testid="input_file_1"
                      type="file"
                      accept=".svg"
                      hidden
                      onChange={(event) =>
                        handleChangeInput(event, "IMG_INT_HME_MBL", files[1]?.id)
                      }
                    />
                  </div>
                }
              />
              )}
            </div>

            {files[1]?.url && (
              <div className={classes.image}>
                <img src={files[1].url} />
              </div>
            )}
          </div>
          <div>
            <div className={classes.contentButtonLabel}>
              <label>Monthly Web</label>
              { isAdmin && (
              <Button
                variant="contained"
                component="label"
                type="secondary"
                css={classes.buttons}
                icon={
                  <div>
                    Load Image Monthly Web
                    <input
                      data-testid="input_file_2"
                      type="file"
                      accept=".svg"
                      hidden
                      onChange={(event) =>
                        handleChangeInput(event, "IMG_INT_MON_WEB", files[2]?.id)
                      }
                    />
                  </div>
                }
              />
              )}
            </div>

            {files[2]?.url && (
              <div className={classes.image}>
                <img src={files[2].url} />
              </div>
            )}
          </div>

          <div>
            <div className={classes.contentButtonLabel}>
              <label>Monthly mobile</label>
              { isAdmin && (
              <Button
                variant="contained"
                component="label"
                type="secondary"
                css={classes.buttons}
                icon={
                  <div>
                    Load Image Monthly mobile
                    <input
                      data-testid="input_file_3"
                      type="file"
                      accept=".svg"
                      hidden
                      onChange={(event) =>
                        handleChangeInput(event, "IMG_INT_MON_MBL", files[3]?.id)
                      }
                    />
                  </div>
                }
              />
              )}
            </div>

            {files[3]?.url && (
              <div className={classes.image}>
                <img src={files[3].url} />
              </div>
            )}
          </div>
        </form>
      </Grid>
      { isAdmin && (
        <Grid item container >
          <Grid item xs={11} style={{color: errorSubmit.isValid ? "green": "red", marginTop: "2em", textAlign: "right"}}>
            {errorSubmit.text}
          </Grid>
          <Grid item xs={1} className={classes.contentButtonSubmit}>
            <Button
              data-testid="btn_submit_multimedias"
              //disabled={disabled}
              text="Guardar"
              onClick={() => handleSubmit(files)}
              type="primary"
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
