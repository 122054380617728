import React, { useEffect, useState } from "react";
import SchoolView from "./SchoolView";
import LANG from "global/lang";
import { getPrayersBO, saveSchool } from "../../services/services";

export function SchoolContainer() {
  const [section, setSection] = useState(setInitial());
  const [language, setLanguage] = useState(LANG[1].code);
  const [message, setMessage] = useState({text: "", msgType: ""});

  useEffect(async () => {
    resetData();
  }, [language]);

  function setInitial() {
    return [
      [{}, {}, {}, {}],
      [{}, {}, {}, {}, {}],
      [{}, {}, {}, {}, {}],
      [{}, {}, {}, {}, {}],
    ];
  }

  function resetData() {
    setSection(setInitial);
    fetchCampaigns(language, 0);
    fetchCampaigns(language, 1);
    fetchCampaigns(language, 2);
    fetchCampaigns(language, 3);
  }

  function fetchCampaigns(language, section) {
    getPrayersBO(language, section)
      .then(async (response) => {
        if (response) {
          setSections([response]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function setSections(schoolCards) {
    let arrCards = [...section];
    schoolCards.forEach((section) => {
      section.data.forEach((card) => {
        arrCards[section.section][card.order] = card;
      });
    });
    setSection(arrCards);
  }

  function handleLangChange(lang) {
    setSection(setInitial());
    setLanguage(lang);
    resetMessage();
  }

  function handleItemSelection(item, sectionIdx, order) {
    let arrCards = [...section];
    arrCards[sectionIdx][order] = item;
    setSection(arrCards);
    resetMessage();
  }

  function deleteCampaign(sectionIdx, order) {
    let arrCards = [...section];
    arrCards[sectionIdx][order].is_hidden = true;
    setSection(arrCards);
    resetMessage();
  }
  function resetMessage(){
    setMessage({
      text: "",
      msgType: "",
    })
  }
  function handlerSave() {
    let arrReq = [];
    section.forEach((sec, ind) => {
      let data = [];
      sec.forEach((s) => {
        if (s?.id) {
          data.push({
            id: s.id,
            type: s.type.code,
            order: s.order,
            is_hidden: s.is_hidden,
          });
        }
      });
      if (data.length > 0) {
        let oSection = {
          section: ind,
          language: language,
          data: data,
        };
        arrReq.push(saveSchool(oSection));
      }
    });

    Promise.all(arrReq).then((res) => {
      if (res) {
        setMessage({ text: "Datos guardados" });
      }
      resetData();
    }).catch((err) =>
      setMessage({
        text: err.response?.data?.message,
        msgType: "msgError",
      })
    );
  }

  return (
    <SchoolView
      langs={LANG.map((l) => ({ value: l.code, display: l.display }))}
      selectedLang={language}
      handleLangChange={(e) => handleLangChange(e.target.value)}
      message={message}
      sections={section}
      handleItemSelection={handleItemSelection}
      handlerSave={handlerSave}
      deleteCampaign={deleteCampaign}
    />
  );
}
