import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  viewBox: {
    marginTop: "10px"
  },
  sectionBox: {
    padding: "1em ",
    "& h2" : {
      color: "#4b4848"
    },
    "& h3" : {
      color: "#444C67"
    }
  },
  messageBox: {
    width: "50%",
    padding: "5px 15px",
    borderRadius: "5px",
    backgroundColor: "#d4edda",
    color: "#155724",
    position: "sticky",
    marginTop: "8px",
    right: 0,
    zIndex: 199,
  },
  msgError: {
    backgroundColor: "rgb(253, 236, 234)",
    color: "rgb(136, 37, 30)",
  },
  btnEdit: {
    backgroundColor: "#8bc34a",
  },
});
