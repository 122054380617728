import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  paper: {
    display: "flex",
    border: "1px solid #f5f5f5",
    padding: "0 10px",
    justifyContent: "center",
    backgroundColor: "#fff"
  },
  col1: {
    width: "40%",
  },
  col2: {
    width: "10%",
  },
  formSelect: {
    minWidth: "90%",
    marginTop: "15px",
  },
  btnClear: {
    margin: "30px 0 0 10px",
    cursor: "pointer",
    "& :hover": {
      color: "red",
    },
  },
});
