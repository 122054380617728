import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  root: {
    fontFamily: "Nunito Sans",
    width: "max-content",
    padding: "0.2em",
    borderRadius: "0.2rem",
    margin: "0.2rem",
  },
});
