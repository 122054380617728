import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import LanguageNavStyled from "../../../components/LanguageNav";
import Button from "../../../components/Button";
import { useStyles } from "./styles";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import moment from "moment";
import { CAMPAIGN_EDIT } from "../../../navigation/CONSTANTS";

export default function CampaignAddView(props) {
  const {
    type,
    groupId,
    saveCampaign,
    handleBack,
    message,
    lang,
    groupData,
  } = props;
  const today = moment().format("YYYY-MM-DD");
  const tomorrow = moment().add(1, "days").format("YYYY-MM-DD");
  const initialState = {
    title: "",
    description: "",
    date_from: groupData?.date_from || today,
    date_to: groupData?.date_to || tomorrow,
    show_date: groupData?.show_date || today,
    hide_date: groupData?.hide_date || tomorrow,
    showing_home: groupData.showing_home || false,
    showing_tab: groupData.showing_tab || false,
    moment: groupData.moment?.code || "MOR",
    multimedia: groupData.multimedia || [],
    link: "",
    language: lang,
    type: type?.code,
    group: groupId,
    otherLanguages: groupData.otherLanguages || [],
  };
  const [newCampaign, setCampaign] = useState(initialState);
  const [error, setError] = useState({ message });
  const [saving, setSaving] = useState(false);
  const [intentSave, setIntetntSave] = useState(false);

  const classes = useStyles();
  useEffect(() => {
    if (groupData) {
      setCampaign(initialState);
    }
  }, [groupData]);

  useEffect(() => {
    if (message) {
      setError(message);
      setSaving(false);
    }
  }, [message]);

  const handleInputChange = (event) => {
    setError("");
    setCampaign({
      ...newCampaign,
      [event.target.name]: event.target.value,
    });
  };

  const handlePickerChange = (name) => (date) => {
    setError("");
    setCampaign({
      ...newCampaign,
      [name]: moment(date).format("YYYY-MM-DD"),
    });
  };

  const handleSubmitCampaign = (camp) => {
    setSaving(true);
    setIntetntSave(false);
    setError({ text: "", msgType: "" });
    let msgError = "";
    if (camp.show_date >= camp.hide_date) {
      msgError = "Fecha de MOSTRAR debe ser anterior a OCULTAR";
    }

    if (camp.type === "SCH" && camp.date_from >= camp.date_to) {
      msgError = "Fecha de INICIO debe ser anterior a FINALIZA";
    }

    if (
      !camp.title ||
      !camp.description ||
      !camp.show_date ||
      !camp.hide_date
    ) {
      msgError = "Complete los campos requeridos";
    }
    if (msgError !== "") {
      setError({
        text: msgError,
        msgType: "msgError",
      });
      setSaving(false);
      setIntetntSave(true);
      return;
    }

    saveCampaign(camp);
  };

  return (
    <div>
      {error?.text && (
        <div className={`${classes.messageBox} ${classes[error.msgType]}`}>
          {error.text}
        </div>
      )}
      <Grid container spacing={4} className={classes.wrapper}>
        <Grid item xs={8}>
          <Grid container>
            <Grid item xs={2}>
              <h2>Campaña</h2>
            </Grid>
            <Grid item xs={8}>
              <LanguageNavStyled
                pathEdit={CAMPAIGN_EDIT}
                language={newCampaign.language}
                otherLanguages={newCampaign.otherLanguages}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <span className={classes.label}>Tipo:</span>{" "}
              {type?.description_es}
            </Grid>
          </Grid>
          <form>
            <div>
              <TextField
                label="Titulo"
                name="title"
                required
                variant="filled"
                size="small"
                fullWidth
                margin="normal"
                value={newCampaign.title || ""}
                error={!newCampaign.title && intentSave}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputProps: {
                    maxLength: 250
                  }
                }}
              />
            </div>
            <div>
              <TextField
                label="Descripcion"
                name="description"
                required
                variant="filled"
                size="small"
                fullWidth
                margin="normal"
                multiline
                rows={6}
                value={newCampaign.description || ""}
                error={!newCampaign.description && intentSave}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputProps: {
                    maxLength: 2500
                  }
                }}
              />
            </div>
            <div>
              <TextField
                label="Link"
                name="link"
                variant="filled"
                size="small"
                fullWidth
                margin="normal"
                value={newCampaign.link || ""}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputProps: {
                    maxLength: 250
                  }
                }}
              />
            </div>
          </form>
        </Grid>
        <Grid item xs={4} style={{ backgroundColor: "#F4F6F8" }}>
          <Button
            text={!saving ? "Guardar" : "Procesando...."}
            disabled={saving}
            type="primary"
            onClick={() => handleSubmitCampaign(newCampaign)}
            style={{ marginRight: 10 }}
          />
          <Button
            text="Volver"
            onClick={handleBack}
            id="btn_back_campaign"
            data-testid="btn_back_campaign"
            variant="outlined"
          />
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <DatePicker
                disabled={!!groupId}
                openTo="month"
                clearable={true}
                format="dd-MM-yyyy"
                label="Mostrar"
                views={["year", "month", "date"]}
                value={moment(newCampaign.show_date).toISOString() || null}
                onChange={handlePickerChange("show_date")}
                inputVariant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                name="show_date"
                style={{ width: "160px", marginRight: "10px" }}
              />

              <DatePicker
                disabled={!!groupId}
                openTo="month"
                clearable={true}
                format="dd-MM-yyyy"
                label="Ocultar"
                views={["year", "month", "date"]}
                value={moment(newCampaign.hide_date).toISOString() || null}
                onChange={handlePickerChange("hide_date")}
                inputVariant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                name="hide_date"
                style={{ width: "160px" }}
              />
            </MuiPickersUtilsProvider>
          </div>
          {newCampaign.type === "SCH" && (
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  disabled={!!groupId}
                  openTo="month"
                  clearable={true}
                  format="dd-MM-yyyy"
                  label="Inicia"
                  views={["year", "month", "date"]}
                  value={moment(newCampaign.date_from).toISOString() || null}
                  onChange={handlePickerChange("date_from")}
                  inputVariant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  name="date_from"
                  style={{ width: "160px", marginRight: "10px" }}
                />

                <DatePicker
                  disabled={!!groupId}
                  openTo="month"
                  clearable={true}
                  format="dd-MM-yyyy"
                  label="Finaliza"
                  views={["year", "month", "date"]}
                  value={moment(newCampaign.date_to).toISOString() || null}
                  onChange={handlePickerChange("date_to")}
                  inputVariant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  name="date_to"
                  style={{ width: "160px" }}
                />
              </MuiPickersUtilsProvider>
            </div>
          )}

          <div>
            <FormControl component="fieldset">
              <FormLabel component="legend" className={classes.label}>
                Momento del día
              </FormLabel>
              <RadioGroup
                row
                aria-label="moment"
                name="moment"
                value={newCampaign.moment || "MOR"}
                onChange={handleInputChange}
              >
                <FormControlLabel
                  value="MOR"
                  control={<Radio />}
                  label="Mañana"
                  disabled={!!groupId}
                />
                <FormControlLabel
                  value="AFT"
                  control={<Radio />}
                  label="Tarde"
                  disabled={!!groupId}
                />
                <FormControlLabel
                  value="EVE"
                  control={<Radio />}
                  label="Noche"
                  disabled={!!groupId}
                />
              </RadioGroup>
            </FormControl>
          </div>
          {newCampaign.multimedia[0] && (
            <div>
              <label className={classes.label}>
                <span>{newCampaign?.type !== "PRO" ? "Imagen" : "Icono"}</span>
              </label>
              <div>
                <img
                  src={newCampaign.multimedia[0]?.url}
                  className={classes.imageUpload}
                />
              </div>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
