import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import LanguageNavStyled from "../../../components/LanguageNav";
import CampaignItem from "../../../components/CampaignItem";
import Button from "../../../components/Button";
import { useStyles } from "./styles";
import Delete from "@material-ui/icons/Delete";
import DialogAlert from "../../../components/DialogAlert";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import moment from "moment";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import CAMPAIGN_TYPES from "../../../global/types_campaign";
import { CAMPAIGN_EDIT } from "../../../navigation/CONSTANTS";
import _ from "lodash";
import {validateIcon, validateImageFormat} from "../../../helpers/validate";

export default function CampaignEditView(props) {
  const {
    oldcampaign,
    saveCampaign,
    saveItem,
    handleBack,
    message,
    deleteCampaign,
    deleteImage
  } = props;
  const [currentCampaign, setCurrentCampaign] = useState({});
  const [files, setFiles] = useState([]);
  const [error, setError] = useState({ message });
  const [saving, setSaving] = useState(false);
  const [intentSave, setIntentSave] = useState(false);
  const [editing, setEditing] = useState({ changed: [], deleted: [] });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });
  const typeCode = CAMPAIGN_TYPES.find(
    (typeCamp) => typeCamp.code === currentCampaign.type?.code
  );
  const classes = useStyles();
  useEffect(() => {
    if (oldcampaign) {
      setCurrentCampaign(oldcampaign);
    }
  }, [oldcampaign]);
  useEffect(() => {
    if (message) {
      setError(message);
      setSaving(false);
    }
  }, [message]);

  const handleInputChange = (event) => updateData(event.target.name, event.target.value);

  const updateData = (name, value) => {
    setError("");
    setCurrentCampaign({
      ...currentCampaign,
      [name]: value
    });
  }

  const handlePickerChange = (name) => (date) => updateData(name, moment(date).format("YYYY-MM-DD"));

  const handleItemChangeDate = (order) => (date) => {
    let camps = [...currentCampaign.campaignPrayer];
    const dateFormated = moment(date).format("YYYY-MM-DD");
    let same = camps.find((itemCamp) => itemCamp.date === dateFormated);
    let outOfRange = dateFormated < moment(currentCampaign.show_date).format("YYYY-MM-DD") || dateFormated > moment(currentCampaign.hide_date).format("YYYY-MM-DD");
    setError({
      text: dateErrorMsg(same, outOfRange),
      msgType: "msgWarn",
    });

    camps[order - 1]["date"] = dateFormated;
    camps[order - 1]["edited"] = true;
    setCurrentCampaign({
      ...currentCampaign,
      campaignPrayer: camps,
    });
  };

  function dateErrorMsg(same, outOfRange) {
    if(same) {
      return "Aviso: Ya existe un item con la misma fecha";
    } else if(outOfRange) {
      return "La fecha debe estar dentro del rango de fechas de la campaña";
    } else {
      return "";
    }
  }

  const handleItemChange = (newData, order) => {
    if (!editing.changed.includes(order)) {
      setEditing({ ...editing, changed: [...editing.changed, order] });
    }

    setError("");
    let camps = [...currentCampaign.campaignPrayer];
    camps[order - 1] = newData;
    setCurrentCampaign({
      ...currentCampaign,
      campaignPrayer: camps,
    });
  };

  const handleSaveItem = (order) => {
    setIntentSave(false)
    let msgError = "";
    if (
      !validateItem(
        currentCampaign.campaignPrayer[order - 1],
        currentCampaign.type.id
      )
    ) {
      msgError = "Complete los campos requeridos en los items";
      setIntentSave(true);
    }
    setError({ text: msgError, msgType: msgError !== "" ? "msgError" : "" });
    if (msgError === "") {
      let camps = [...currentCampaign.campaignPrayer];
      camps[order - 1]["edited"] = false;
      setCurrentCampaign({
        ...currentCampaign,
        campaignPrayer: camps,
      });
      saveItem(currentCampaign.campaignPrayer[order - 1]);

      let arrChanged = [...editing.changed];
      const index = arrChanged.indexOf(order)
      if (index > -1) {
        arrChanged.splice(index, 1);
        setEditing({ ...editing, changed: arrChanged });
      }
    } else {
      setSaving(false);
    }
  };

  const handleImageChange = (event, type, owner) => {
    let isValidImage;
    if (currentCampaign.type.id === 4 && type === "CAM") {
      isValidImage = validateIcon(event.target.files[0])
    } else {
      isValidImage = validateImageFormat(event.target.files[0])
    }

    if (!isValidImage) {
      window.alert("Formato de imagen incorrecto");
      return false;
    }

    if (event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = function () {
        const url = URL.createObjectURL(event.target.files[0]);
        const newFile = {
          owner: owner,
          url: url,
          file: event.target.files[0],
          type: currentCampaign.type.id === 4 && type === "CAM" ? "IMG_ICON_CAMPAIGN" : "IMG",
        };

        setFiles([...files, newFile]);
        if (type === "CAM") {
          const origUrl = currentCampaign.multimedia[0]?.url;
          setCurrentCampaign({
            ...currentCampaign,
            multimedia: [
              { ...currentCampaign.multimedia[0], url: url, origUrl: origUrl, newFile },
            ],
          });
        } else if (type === "CAP") {
          let camps = [...currentCampaign.campaignPrayer];
          camps[owner - 1].multimedia[0] = {
            url: url,
            id: camps[owner - 1].multimedia[0]?.id,
            newFile,
          };
          camps[owner - 1].edited = true;
          setCurrentCampaign({
            ...currentCampaign,
            campaignPrayer: camps,
          });
        }
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleSubmitCampaign = (campaign) => {
    setSaving(true);
    setIntentSave(false);
    let msgError = "";
    if (!campaign.title || !campaign.description) {
      msgError = "TITULO y DESCRIPCION son requeridos ";
    }
    if (campaign.show_date >= campaign.hide_date) {
      msgError = "Fecha de MOSTRAR debe ser anterior a OCULTAR";
    }
    if (campaign.type?.code === "SCH" && campaign.date_from >= campaign.date_to) {
      msgError = "Fecha de INICIO debe ser anterior a FINALIZA";
    }
    if (msgError!=="") setIntentSave(true);

    setError({ text: msgError, msgType: msgError !== "" ? "msgError" : "" });
    if (msgError === "") {
      saveCampaign(campaign);
      setEditing({ ...editing, deleted: [] });
    } else {
      setSaving(false);
    }
  };

  const validateItem = (item, type) => {
    let isValid = true;
    if (item.title === "" && type !== 3) isValid = false;
    if (item.description.trim() === "") isValid = false;
    return isValid;
  };

  const handleDeleteCampaign = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    if (
      currentCampaign.language.code === "ES" &&
      currentCampaign.otherLanguages.length > 0
    ) {
      setError({
        text: "Elimine los otros idiomas antes de eliminar este ",
        msgType: "msgError",
      });
      return;
    }
    if (!id) {
      return;
    }
    deleteCampaign(id);
  };

  const handleDeleteImage = (campId, multimediaId) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    if (currentCampaign.multimedia[0].newFile) {
      let resetMultimedia = [];
      if (currentCampaign.multimedia[0].id) {
        resetMultimedia.push({...currentCampaign.multimedia[0], url: currentCampaign.multimedia[0].origUrl, newFile: undefined});
      } 
      setCurrentCampaign({
        ...currentCampaign,
        multimedia: resetMultimedia
      });       
    } else {
      deleteImage(campId, multimediaId);
    }    
  }

  const handleDeleteItem = (orderIndex) => {
    let camps = [...currentCampaign.campaignPrayer];
    const toDelete = !camps[orderIndex - 1]["delete"];
    camps[orderIndex - 1]["delete"] = toDelete;
    setCurrentCampaign({
      ...currentCampaign,
      campaignPrayer: camps,
    });

    if (!editing.deleted.includes(orderIndex)) {
      setEditing({ ...editing, deleted: [...editing.deleted, orderIndex] });
    } else if (!toDelete) {
      const deleted = editing.deleted.filter(item => item!== orderIndex);
      setEditing({ ...editing, deleted: deleted });
    }
  };

  const addItem = () => {
    let arrOrders = _.map(currentCampaign.campaignPrayer, "order");
    let nextOrder = arrOrders.length > 0 ? Math.max(...arrOrders) : arrOrders.length;

    let newItem = {
      description: "",
      multimedia: [],
      order: nextOrder + 1,
    };

    if (currentCampaign.type.id === 1) {
      newItem.date = currentCampaign?.date_from || "";
    }

    if (currentCampaign.type.id !== 3) {
      newItem.hashtag = "";
      newItem.title = "";
    }

    setCurrentCampaign({
      ...currentCampaign,
      campaignPrayer: [...currentCampaign.campaignPrayer, newItem],
    });
  };

  return (
    <div>
      {error?.text && (
        <div className={`${classes.messageBox} ${classes[error.msgType]}`}>
          {error.text}
        </div>
      )}
      <Grid
        container
        spacing={4}
        className={classes.wrapper}
        data-testid="campaign-edit-view"
      >
        <Grid item xs={8}>
          <Grid container>
            <Grid item xs={2}>
              <h2>Campaña</h2>
            </Grid>
            <Grid item xs={8}>
              <LanguageNavStyled
                language={currentCampaign.language}
                pathEdit={CAMPAIGN_EDIT}
                otherLanguages={currentCampaign.otherLanguages}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <span className={classes.label}>Tipo:</span>
              {typeCode?.description_es}
            </Grid>            
          </Grid>
          <form>
            <div>
              <TextField
                label="Titulo"
                name="title"
                required
                variant="filled"
                size="small"
                fullWidth
                margin="normal"
                error={!currentCampaign.title && intentSave}
                value={currentCampaign.title || ""}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputProps: {
                    maxLength: 250,
                  },
                }}
              />
            </div>
            <div>
              <TextField
                label="Descripcion"
                name="description"
                required
                variant="filled"
                size="small"
                fullWidth
                margin="normal"
                multiline
                rows={6}
                value={currentCampaign.description || ""}
                error={!currentCampaign.description && intentSave}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputProps: {
                    maxLength: 2500,
                  },
                }}
              />
            </div>
            <div>
              <TextField
                label="Link"
                name="link"
                variant="filled"
                size="small"
                fullWidth
                margin="normal"
                value={currentCampaign.link || ""}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
              />
            </div>
          </form>
        </Grid>
        <Grid item xs={4} style={{ backgroundColor: "#F4F6F8" }}>
          <Button
            text={!saving ? "Guardar" : "Procesando...."}
            disabled={saving}
            type="primary"
            onClick={() => handleSubmitCampaign(currentCampaign)}
            style={{ marginRight: 10 }}
            data-testid="btn-save-campaign-edit"
          />
          <Button
            text="Volver"
            onClick={handleBack}
            id="btn_back_campaign"
            data-testid="btn_back_campaign"
            variant="outlined"
          />
          <Button
            text="Eliminar"
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title: "¿Desea eliminar este registro?",
                subtitle: "Eliminar",
                onConfirm: () => {
                  handleDeleteCampaign(currentCampaign.id);
                },
              });
            }}
            id="btn_del_campaign"
            data-testid="btn_del_campaign"
            variant="text"
            color="secondary"
            icon={<Delete />}
            style={{ float: "right" }}
          />
          <div className={classes.notification}>
            {editing.changed.length > 0 && (
              <div
                title="Items sin guardar"
                className={classes.notificationChanged}
              >
                <EditIcon /> {editing.changed.length}
              </div>
            )}
            {editing.deleted.length > 0 && (
              <div
                title="Items seleccionados para borrar"
                className={classes.notificationDeleted}
              >
                <CancelIcon /> {editing.deleted.length}
              </div>
            )}
          </div>
          <div className={classes.label}>
            Datos comunes a todos los lenguajes
          </div>
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <DatePicker
                openTo="month"
                clearable={true}
                format="dd-MM-yyyy"
                label="Mostrar"
                views={["year", "month", "date"]}
                value={moment(currentCampaign.show_date).toISOString() || null}
                onChange={handlePickerChange("show_date")}
                inputVariant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                name="show_date"
                style={{ width: "160px", marginRight: "10px" }}
              />

              <DatePicker
                openTo="month"
                clearable={true}
                format="dd-MM-yyyy"
                label="Ocultar"
                views={["year", "month", "date"]}
                value={moment(currentCampaign.hide_date).toISOString() || null}
                onChange={handlePickerChange("hide_date")}
                inputVariant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                name="hide_date"
                style={{ width: "160px" }}
              />
            </MuiPickersUtilsProvider>
          </div>
          {currentCampaign.type?.code === "SCH" && (
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  openTo="month"
                  clearable={true}
                  format="dd-MM-yyyy"
                  label="Inicia"
                  views={["year", "month", "date"]}
                  value={
                    moment(currentCampaign.date_from).toISOString() || null
                  }
                  onChange={handlePickerChange("date_from")}
                  inputVariant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  name="date_from"
                  style={{ width: "160px", marginRight: "10px" }}
                />

                <DatePicker
                  openTo="month"
                  clearable={true}
                  format="dd-MM-yyyy"
                  label="Finaliza"
                  views={["year", "month", "date"]}
                  value={moment(currentCampaign.date_to).toISOString() || null}
                  onChange={handlePickerChange("date_to")}
                  inputVariant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  name="date_to"
                  style={{ width: "160px" }}
                />
              </MuiPickersUtilsProvider>
            </div>
          )}

          <div>
            <FormControl component="fieldset">
              <FormLabel component="legend" className={classes.label}>
                Momento del día
              </FormLabel>
              <RadioGroup
                row
                aria-label="moment"
                name="moment"
                value={currentCampaign.moment || "MOR"}
                onChange={handleInputChange}
              >
                <FormControlLabel
                  value="MOR"
                  control={<Radio />}
                  label="Mañana"
                />
                <FormControlLabel
                  value="AFT"
                  control={<Radio />}
                  label="Tarde"
                />
                <FormControlLabel
                  value="EVE"
                  control={<Radio />}
                  label="Noche"
                />
              </RadioGroup>
            </FormControl>
          </div>

          {currentCampaign.id && (
            <div className={classes.label}>
              {(currentCampaign.type?.code !== "PRO" && (
                <span>Imagen (Tamaño recomendado 1015 x 400 px )</span>
              )) || (
                <span>
                  Icono (Archivo .SVG - Tamaño recomendado 24 x 24 px )
                </span>
              )}

              {currentCampaign.multimedia[0] && (
                <div>
                  <img
                    src={currentCampaign.multimedia[0]?.url}
                    className={classes.imageUpload}
                  />
                </div>
              )}              
              <div>
              <input
                accept="image/*"
                className={classes.hide}
                id="icon-button-file"
                type="file"
                onChange={(event) =>
                  handleImageChange(event, "CAM", currentCampaign.id)
                }
                onClick={(event)=> { 
                  event.target.value = null
                }}
              />
              </div>
              <label htmlFor="icon-button-file">
                <Button
                  variant="contained"
                  color="default"
                  component="span"
                  size="small"
                  css={classes.btnUpload}
                  startIcon={<PhotoCamera />}
                >
                  Seleccionar imagen
                </Button>
              </label>
              {currentCampaign.multimedia[0] && !currentCampaign.multimedia[0].newFile && (                
                <Button
                text="Eliminar"
                onClick={() => {
                  setConfirmDialog({
                    isOpen: true,
                    title: "¿Desea eliminar la imagen?",
                    subtitle: "Eliminar",
                    onConfirm: () => {
                      handleDeleteImage(currentCampaign.multimedia[0].id, currentCampaign.id);
                    },
                  });
                }}
                id="btn_del_multimedia"
                data-testid="btn_del_multimedia"
                variant="text"
                color="secondary"
                icon={<Delete />}
                />                
              )}
              {currentCampaign.multimedia[0] && currentCampaign.multimedia[0].newFile && (                
                <Button
                text="Deshacer"
                onClick={() => {
                  handleDeleteImage(currentCampaign.multimedia[0].id, currentCampaign.id);
                }}
                id="btn_undo_multimedia"
                data-testid="btn_undo_multimedia"
                variant="text"
                color="secondary"                
                />  
              )}
            </div>
          )}
        </Grid>
        <DialogAlert
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        <Grid item xs={12}>
          <div>
            <h3>Items</h3>
            {currentCampaign.campaignPrayer?.map((prayer, index) => (
              <CampaignItem
                key={index}
                order={index + 1}
                handleItemChange={handleItemChange}
                handleImageChange={handleImageChange}
                handleItemChangeDate={handleItemChangeDate}
                prayer={prayer}
                type={oldcampaign.type?.code}
                deleted={prayer?.delete || false}
                handleDeleteItem={handleDeleteItem}
                edited={prayer?.edited || false}
                handleSaveItem={handleSaveItem}
              />
            ))}
          </div>
          <div>
            <Button onClick={addItem} css={classes.btnAdd}>
              + Agregar
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
