import _ from "lodash";
import React, { useEffect, useState } from "react";
import classnames from "classnames";
import RosaryView from "./RosaryView";
import { getRosaries } from "services/services";
import { useHistory } from "react-router-dom";
import { ROSARY_EDIT, ROSARY_ADD } from "../../navigation/CONSTANTS";
import LANG from "../../global/lang";
import Button from "../../components/Button";
import { useStyles } from "./styles";

export function RosaryContainer({ location }) {
  const [rosaries, setRosaries] = useState([]);
  const history = useHistory();
  const message = {
    text: location.state?.message || "",
  };

  function handleEdit(id) {
    history.push(`${ROSARY_EDIT}/${id}`);
  }

  function handleNew(lang, group) {
    history.push(`${ROSARY_ADD}/${lang}/${group}`);
  }
  const classes = useStyles();

  async function fechtRosaries() {
    const res = await getRosaries();
    const newData = [];
    const grouped = _.groupBy(res?.data, "group"); // arrays por cada group_key

    _.forEach(grouped, (rosarys, group) => {
      const rosarySpanish = rosarys.find(
        (rsy) => rsy.language?.code.toUpperCase() === "ES"
      );
      const rosaryToView = rosarySpanish || rosarys[0]; // si no hay español muestro el primero q haya

      // convierto todo el array de langs en un solo objeto de ids
      let otherLang = (rosaryToView.otherLanguages || []).concat({
        [rosaryToView.language.code.toUpperCase()]: rosaryToView.id,
      });
      otherLang = _.merge(...otherLang);

      const links = [];
      LANG.forEach((lang) => {
        const code = lang.code.toUpperCase();
        const existsId = otherLang[code];
        if (existsId) {
          links.push(
            <Button
              data-testid={`${code}_${existsId}`}
              css={classnames(classes.btnSmall, classes.btnEdit)}
              onClick={() => handleEdit(existsId)}
              key={lang.id}
            >
              {code}
            </Button>
          );
        } else {
          links.push(
            <Button
              data-testid={`${code}_0`}
              css={classes.btnSmall}
              onClick={() => handleNew(lang.code, group)}
              key={lang.id}
            >
              {code}
            </Button>
          );
        }
      });
      rosaryToView.langs = links;
      newData.push(rosaryToView);
    });
    setRosaries(newData);
  }

  useEffect(async () => {
    fechtRosaries();
  }, []);


  return (<div data-testid="rosary_list_container">
    <RosaryView
      rosaries={rosaries}
      handleNew={handleNew}
      message={message}
    />
  </div>);
}
