import React, { useEffect, useState } from "react";
import CampaignView from "./CampaignView";
import { getCampaigns } from "services/services";
import { useHistory } from "react-router-dom";
import { CAMPAIGN_EDIT, CAMPAIGN_ADD } from "../../navigation/CONSTANTS";
import LANG from "global/lang";
import Button from "../../components/Button";
import {useStyles} from "./styles";

export function CampaignContainer({ location }) {
  const [campaigns, setCampaigns] = useState({});
  const history = useHistory();
  const message = {
    text: location.state?.message || "",
  };

  function handleEdit(id) {
    history.push(`${CAMPAIGN_EDIT}/${id}`);
  }

  function handleNew(type, lang, group) {
    history.push(`${CAMPAIGN_ADD}/${type.code}/${lang}/${group}`);
  }
  const classes = useStyles();
  function fetchCampaigns() {
    getCampaigns()
      .then(async (response) => {
        response.data.forEach((camp) => {
          let links = [];
          LANG.forEach((lang) => {
            if (camp.otherLanguages) {
              camp.otherLanguages.push({["ES"]: camp.id})

              const langEdit = camp.otherLanguages.filter((ol) =>
                Object.keys(ol).includes(lang.code.toUpperCase())
              );
              if (langEdit.length > 0) {
                links.push(
                  <Button
                    css={`${classes.btnSmall} ${classes.btnEdit}`}
                    onClick={() => handleEdit(Object.values(langEdit[0])[0])}
                    key={lang.id}
                  >
                    {lang.code.toUpperCase()}
                  </Button>
                );
              } else {
                links.push(
                  <Button
                    css={classes.btnSmall}
                    onClick={() => handleNew(camp.type, lang.code, camp.group)}
                    key={lang.id}
                  >
                    {lang.code.toUpperCase()}
                  </Button>
                );
              }
            }
          });
          camp.langs = links;
        });
        setCampaigns(response);
      })
      .catch(console.warn);
  }

  useEffect(async () => {
    fetchCampaigns();
  }, []);

  function handleEditCampaign(e, rowData) {
    history.push(`${CAMPAIGN_EDIT}/${rowData.id}`);
  }

  return (
    <CampaignView
      campaigns={campaigns}
      handleEditCampaign={handleEditCampaign}
      handleEdit={handleEdit}
      handleNew={handleNew}
      message={message}
    />
  );
}
