import React from "react";
import moment from "moment";

import pkg from "../../../package.json";
import {Typography} from "@material-ui/core";


export function FooterStyled() {
    return (
        <div >
            <Typography component="span" style={{color: "#b5b5b5"}}>
                Copyright © {moment().year()} Pope Worldwide Prayer Network Click To Pray. v{pkg.version}
            </Typography>
        </div>
    );
}
