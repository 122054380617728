import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  messageBox: {
    width: "50%",
    padding: "5px 15px",
    borderRadius: "5px",
    backgroundColor: "#d4edda",
    color: "#155724",
  },
  msgError: {
    backgroundColor: "rgb(253, 236, 234)",
    color: "rgb(136, 37, 30)",
  },
  btnSmall: {
    padding: "5px",
    marginRight: "2px",
    minWidth: "45px",
  },
  btnEdit: {
    backgroundColor: "#8bc34a"
  },

});
