import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Search from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Clear from "@material-ui/icons/Clear";
import { useStyles } from "./styles";
import {Checkbox, FormControlLabel} from "@material-ui/core";

const MediationView = (props) => {
  const { reported, message, handleEdit, handleReportedStatus, reportedStatus} = props;

  const tableIcons = {
    Edit: EditIcon,
    Delete: DeleteIcon,
    SortArrow: KeyboardArrowUpIcon,
    Add: AddCircleIcon,
    PreviousPage: ChevronLeft,
    NextPage: ChevronRight,
    Search: Search,
    LastPage: LastPage,
    FirstPage: FirstPage,
    Filter: FilterList,
    Clear: Clear,
  };
  const [error, setError] = useState({ message });
  const classes = useStyles();

  useEffect(() => {
    if (message) {
      setError(message);
    }
  }, [message]);

  return (
    <div data-testid="mediate_view">
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          position: "absolute",
          zIndex: "100",
          right: 0
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={reportedStatus}
              onChange={handleReportedStatus}
              name="reported_status"
              color="default"
              size="small"
            />
          }
          label="Mostrar revisados"
        />
      </div>
      <div>
        {error?.text && (
          <div className={`${classes.messageBox} ${classes[error.msgType]}`}>
            {error.text}
          </div>
        )}
        <MaterialTable
          title="Mediaciones"
          icons={tableIcons}
          columns={[
            { title: "Id", field: "id", hidden: true },
            { title: "type", field: "type", hidden: true },
            { title: "Reportado", field: "date", width: "10%" },
            {
              title: "Propietario",
              field: "user",
              width: "25%",
            },
            { title: "Denunciante", field: "reporter", width: "25%" },
            { title: "Oración", field: "title", width: "20%" },
            { title: "Id Comentario", field: "commId", width: "5%" },
            {
              title: "Tipo",
              field: "typeItem",
              width: "10%",
              lookup: { commentPope: "COMENTARIO PAPA", commentComm: "COMENTARIO COMUNIDAD", communityPrayer: "ORACION" },
            },
            {
              title: "Revisado",
              field: "status",
              width: "5%",
              lookup: { unresolved: "No", resolved: "Si" },
            },
          ]}
          data={reported}
          actions={[
            {
              icon: tableIcons.Edit,
              tooltip: "Ver",
              onClick: (event, rowData) => handleEdit(rowData.id, rowData.type),
            },
          ]}
          options={{
            search: false,
            actionsColumnIndex: -1,
            filtering: true,
            pageSize: 50,
            headerStyle: {
              backgroundColor: "#596386",
              color: "#FFF",
              padding: "5px 10px",
            },
            cellStyle: {
              fontSize: ".9em",
              padding: "5px 10px",
            },
            filterCellStyle: { padding: "2px" },
          }}
          localization={{
            header: {
              actions: "Acciones",
            },
          }}
        />
      </div>
    </div>
  );
};

export default MediationView;
