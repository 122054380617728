
import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
    wrapper: {
        margin: "1em",
        textTransform: "uppercase"
    },
    label: {
        fontSize: "1.5em",
        marginRight: ".8em",
        textTransform: "uppercase",
        color: "#d5d5d5"
    },
    current: {
        color: "white",
        backgroundColor: "#E7C26D",
        borderRadius: "5px",
        padding: "0 5px",
    },
    link: {
        fontSize: "1.5em",
        marginRight: ".8em",
        textTransform: "uppercase",
        textDecoration: "none",
        color: "#8bc34a",
    },
});
