import React, { useEffect, useState } from "react";
import moment from "moment";
import LANGS from "global/lang";
import PopeProfileView from "./PopeProfileView";
import { getPrayers } from "services/services";
import { useHistory } from "react-router-dom";
import { POPE_EDIT } from "navigation/CONSTANTS";
import { Edit } from "@material-ui/icons";
import Button from "components/Button";

export function languagesSelectList() {
  return [{ value: 0, display: "Todos" }].concat(
    LANGS.sort((a, b) => a.order - b.order).map((l) => ({
      value: l.id,
      display: l.display,
    }))
  );
}

export function PopeProfileContainer() {
  const [prayers, setPrayers] = useState([]);
  const [language, setLanguage] = useState(0);
  const history = useHistory();
  const langIds = LANGS.map((l) => l.id);

  useEffect(() => {
    fetchPopePrayers(Number(language) ? [language] : langIds);
  }, [language]);

  function handleEdit(id) {
    history.push(`${POPE_EDIT}/${id}`);
  }

  function handleNew() {
    history.push(`${POPE_EDIT}/0`);
  }

  async function fetchPopePrayers(langs) {
    const res = await getPrayers({}, ["POP"], langs);
    if (res?.data) adjustData(res.data);
    setPrayers(res.data || []);
  }

  function adjustData(pyrs) {
    pyrs.forEach((prayer) => {
      prayer.show_date = moment(prayer.show_date).format("YYYY-MM-DD HH:MM");
      prayer.language = LANGS.find(
        (l) =>
          l.code.toUpperCase() === String(prayer.language?.code).toUpperCase()
      )?.display;
      prayer.actions = [
        <Button
          key={prayer.id}
          icon={<Edit />}
          onClick={() => handleEdit(prayer.id)}
          testid={`btn_edit_popePrayer_${prayer.id}`}
          data-testid={`btn_edit_popePrayer_${prayer.id}`}
          variant="text"
          color="primary"
        />,
      ];
    });
    return pyrs;
  }

  return (
    <div data-testid="pope_prayers_list_container">
      <PopeProfileView
        languages={languagesSelectList()}
        selectedLanguage={language}
        handlerChangeLanguage={(e) => setLanguage(String(e.target.value))}
        prayers={prayers}
        handleNew={handleNew}
      />
    </div>
  );
}
