/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ProfileView from "./ProfileView";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  handleModifyPassword,
} from "./profileFunctions";

import { useSelector } from "react-redux";
import {clearSession} from 'services/authService'

export function ProfileContainer() {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.auth.user);
  const history = useHistory();
  const mediaQueryMd = useMediaQuery("(max-width:960px)");
  const mediaQueryLg = useMediaQuery("(max-width:1294px)");


  return (
    <ProfileView
      user={user}
      isLg={mediaQueryLg}
      isMd={mediaQueryMd}
      handleModifyPassword={(value) => handleModifyPassword(value, dispatch)}
      handleCloseSession={() => {
        clearSession(dispatch);
        history.push("/");
      }}
    />
  );
}
