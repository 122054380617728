import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { LanguageNavStyled } from "../LanguageNav/LanguageNavStyled";
import Button from "../Button";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";
import { CropImageStyled } from "../CropImage/CropImageStyled";
import { useStyles } from "./styles";
import Delete from '@material-ui/icons/Delete';
import DialogAlert from "../DialogAlert";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {BLOG_EDIT} from "../../navigation/CONSTANTS";

export function BlogEditViewStyled(props) {
  const { post, lang, savePost, handleBackBlog, message, deleteBlog } = props;
  const [article, setArticle] = useState({
      id: post ? post.id : null,
      title: post ? post.title : "",
      blockquote: post ? post.author : "",
      body: post ? post.body : "",
      publish_date: post && post.publish_date ? moment(post.publish_date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
      author: post ? post.author : "",
      job_title: post ? post.job_title : "",
      multimedia: post ? post.multimedia : "",
      language: post ? post.language : { code: lang },
      group: post ? post.group : "",

  });
  const [error, setError] = useState({message});
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subtitle: '' })
  const [intentSave, setIntentSave] = useState(false);
  const [saving, setSaving] = useState(false);

  const classes = useStyles();
  useEffect(() => {
    if (post) {
      setArticle(post);
    }
  }, [post]);
  useEffect(() => {
    if (message) {
      setError(message);
      setSaving(false);
    }
  }, [message]);

  const handleInputChange = (event) => {
    setError("");
    setArticle({
      ...article,
      [event.target.name]: event.target.value,
    });
  };

  const handlePickerChange = (name) => (date) => {
    setError("");
    setArticle({
      ...article,
      [name]: moment(date).format("YYYY-MM-DD"),
    });
  };

  const handleEditor = (event, editor) => {
    if (Object.keys(article).length > 0) {
      setArticle({
        ...article,
        body: editor.getData(),
      });
      setError("");
    }
  };

  const handleImageChange = (url) => {
    setArticle({
      ...article,
      multimedia: [
        { ...article.multimedia[0], url: url },
      ],
    });
    setError({text: "No olvide Seleccionar recorte para guardar la imagen"});
  };

  const handleSubmitPost = (articlePost) => {
    setSaving(true);
    setIntentSave(false)
    setError({text: "", msgType: ""});
    if (!articlePost.title || !articlePost.body || !articlePost.publish_date || !articlePost.author) {
      setError({text: "Ingrese los campos son requeridos ", msgType: "msgError"});
      setSaving(false);
      setIntentSave(true);
      return;
    }
    savePost(articlePost);
  };

  const handleSaveImage = (files) => {
    setError("");
    setArticle({
      ...article,
      newMultimedia: files,
      multimedia: [
        { ...article.multimedia[0], url: URL.createObjectURL(files[0]) },
        { ...article.multimedia[1], url: URL.createObjectURL(files[1]) },
      ],
    });
  }

  const handleDeleteBlog = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false
    })

    if (!id) {
      return;
    }
    deleteBlog(article);
  };
  return (
    <div>
      {error?.text && (
        <div className={`${classes.messageBox} ${classes[error.msgType]}`}>
          {error.text}
        </div>
      )}
    <Grid container spacing={4} className={classes.wrapper}>
      <Grid item xs={8}>

        <Grid container>
          <Grid item xs={2}>
            <h2>Blog</h2>
          </Grid>
          <Grid item xs={8}>
            <LanguageNavStyled language={article?.language || lang} pathEdit={BLOG_EDIT} otherLanguages={article.otherLanguages}/>
          </Grid>
        </Grid>

        <form noValidate autoComplete="off">
          <div>
            <TextField
              label="Titulo"
              name="title"
              required
              variant="filled"
              size="small"
              fullWidth
              margin="normal"
              value={article.title || ""}
              error={!article.title && intentSave}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div>
            <TextField
              label="Subtitulo"
              name="blockquote"
              variant="filled"
              size="small"
              fullWidth
              margin="normal"
              value={article.blockquote || ""}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div style={{border: intentSave && !article.body ? '2px solid red': ''}}>
          <CKEditor
            editor={ClassicEditor}
            data={article.body || ""}
            config={{
              toolbar: [
                "bold",
                "italic",
                "blockQuote",
                "link",
                "undo",
              ],
              link: {
                addTargetToExternalLinks: true
              }
            }}            
            onReady={(editor) => {
              editor.editing.view.change((writer) => {
                writer.setStyle(
                  "height",
                  "300px",
                  editor.editing.view.document.getRoot()
                );
              });
            }}
            onChange={(event, editor) => handleEditor(event, editor)}
          />
          <span style={{fontSize: '.8em'}}>* Los links que comiencen con <b>http://</b> o <b>https://</b> se abren en ventana nueva</span>
          </div>
        </form>
      </Grid>
      <Grid item xs={4} style={{ backgroundColor: "#F4F6F8" }}>
        <Button
          text={!saving ? "Guardar" : "Procesando...."}
          disabled={saving}
          type="primary"
          onClick={() => handleSubmitPost(article)}
          style={{ marginRight: 10 }}
        />
        <Button
          text="Volver"
          onClick={handleBackBlog}
          id="btn_back_blog"
          data-testid="btn_back_blog"
          variant="outlined"
        />
        {article?.id && (
          <React.Fragment>
          <Button
            text="Eliminar"
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title: "¿Desea eliminar este registro?",
                subtitle: "Eliminar",
                onConfirm: () => { handleDeleteBlog(article.id) }
              })
            }}
            id="btn_del_blog"
            data-testid="btn_del_blog"
            variant="text"
            color="secondary"
            icon={<Delete />}
            style={{ float: "right" }}
          />
          </React.Fragment>
        )}
        <div>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <DatePicker
              openTo="month"
              clearable={true}
              format="dd-MM-yyyy"
              required
              label="Publicar"
              views={["year", "month", "date"]}
              value={article.publish_date ? moment(article.publish_date).toISOString() : null}
              error={!article.publish_date && intentSave}
              onChange={handlePickerChange("publish_date")}
              inputVariant="filled"
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              name="publish_date"
              emptyLabel="Seleccionar fecha"
              style={{ width: "160px", marginRight: "10px" }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div>
          <TextField
            label="Autor"
            required
            name="author"
            variant="filled"
            size="small"
            margin="normal"
            value={article.author || ""}
            error={!article.author && intentSave}
            onChange={handleInputChange}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div>
          <TextField
            label="T&iacute;tulo profesional del Autor"
            name="job_title"
            variant="filled"
            size="small"
            margin="normal"
            fullWidth
            value={article.job_title || ""}
            onChange={handleInputChange}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        {article.id && (
          <label className={classes.label}>
            Imagen (Tamaño recomendado 1015 x 400 px )
          </label>
        )}
        {article.multimedia && (
          <div>
            <img src={article.multimedia[0]?.url} className={classes.imageUpload} />
            <img src={article.multimedia[1]?.url} className={classes.imageUploadSm} />
          </div>
        )}
        {article.multimedia && !article.id && (<div>* Imagen del posteo original. Se puede cambiar luego</div>)}
        {article.id && (
          <CropImageStyled
            //submitImage={saveImage}
            submitImage={handleSaveImage}
            handleImageChange={handleImageChange}
          />
        )}
      </Grid>
      <DialogAlert
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Grid>
    </div>
  );
}
