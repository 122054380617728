import _ from "lodash";
import React, { useState, useEffect } from "react";
import { TextField, Grid } from "@material-ui/core";
import Table from "components/Table";
import { convertSimpleDataToObject } from "helpers/convertTable";

export function BishopExcelStyled(props) {
  const {
    css,
    countries = [],
    bishop = [],
    updateEntity,
    onUpdateTable,
    messageOK = "",
    isAdmin = false
  } = props;

  const [errorSubmit, setErrorSubmit] = useState({ isValid: true });

  useEffect(() => {
    setErrorSubmit((prev) => ({ ...prev, text: messageOK.split("$")[0] }));
  }, [messageOK]);

  const columnas = [
    {
      title: "País",
      field: "code",
      width: "20%",
      initialEditValue: "00",
      lookup: _.merge(
        { "00": "-- Seleccionar --" },
        _.transform(
          countries,
          (res, value) => (res[value.alpha2_code] = value.name),
          {}
        ) // output: { 'AR':'argentina' }
      ),
    },
    {
      title: "Descripción",
      field: "description",
      editComponent: (p) => (
        <TextField
          label="Conference"
          fullWidth
          multiline
          //disabled
          rows={7}
          defaultValue={p.value || ""}
          onChange={(e) => p.onChange(e.target.value)}
        />
      ),
    },
  ];

  async function validate(newData = {}, oldData = [], inx = false) {
    let isValid = true;
    setErrorSubmit({ isValid, text: "" });
    const code_country = String(newData.code);

    const existsConference = oldData.find(
      (b, i) => b.code === code_country && b.code !== "00" && i !== inx
    );

    if (existsConference) {
      isValid = false;
      setErrorSubmit({
        isValid: false,
        title: "Conferencia Existente",
        text: `Ya existe una Conferencia para "${existsConference.country?.name}". Por favor seleccione otro país.`,
      });
      newData.code = "00";
      return isValid;
    }

    if (!newData.code || newData.code === "00" || !newData.description) {
      setErrorSubmit({
        isValid: false,
        text: `Antes de guardar complete País y Descripción.`,
      });
    }

    return isValid;
  }

  async function prepareToAddToTable(tableName, newData, oldData = []) {
    const valid = validate(newData, oldData);
    if (!valid) return;

    const country = _.find(countries, ["alpha2_code", newData.code]);
    newData.country = country;
    newData.entity = "bishop";

    onUpdateTable(
      tableName,
      convertSimpleDataToObject([...oldData, newData]),
      oldData
    );
  }

  async function prepareToUpdateTable(tableName, newData, oldData, inxUpdate) {
    const valid = validate(newData[inxUpdate], oldData, inxUpdate);
    if (!valid) return;

    const country = _.find(countries, ["alpha2_code", newData[inxUpdate].code]);
    newData[inxUpdate].country = country;
    onUpdateTable(tableName, newData, oldData);
  }

  return (
    <div data-testid="bishop-excel" style={{ overflow: "auto", width: "100%" }}>
      <Table
        css={css}
        updateEntity={updateEntity}
        columnSchema={columnas}
        data={bishop}
        tableTitle={isAdmin ? "  " : undefined}
        onAddedRowTable={prepareToAddToTable}
        onUpdateTable={prepareToUpdateTable}
        onUpdateTableName="bishopTable"
      />
      <Grid container justify="flex-end" style={{ color: errorSubmit.isValid ? "green" : "red", marginTop: "1em" }}>
        {errorSubmit.text}
      </Grid>
    </div>
  );
}
