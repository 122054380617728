import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  wrapper: {
    textTransform: "uppercase"
  },
  messageBox: {
    width: "50%",
    padding: "5px 15px",
    borderRadius: "5px",
    backgroundColor: "#d4edda",
    color: "#155724",
    position: "sticky",
    top: "5px",
    right: 0,
    margin: "-14px 0 -30px 0"
  },
  msgError: {
    backgroundColor: "rgb(253, 236, 234)",
    color: "rgb(136, 37, 30)",
  },
  imageUpload: {
    maxWidth: "100%",
    maxHeight: "400px",
  },
  imageUploadSm: {
    maxWidth: "50%",
  },
  label: {
    fontSize: ".8rem",
    fontWeight: "normal",
    lineHeight: 1,
    marginRight: ".8em",
    textTransform: "uppercase",
    color: "rgba(0, 0, 0, 0.54)",
    marginTop: "1.5em"
  },
});
