import makeStyles from "@material-ui/core/styles/makeStyles";
export const useStyles = makeStyles({
  button: {
    margin: "0.2rem",
    marginLeft: "0.5em",
    "& span:first-child": {
      "& div": {
        display: "flex",
      },
    },
  },
  rootChip: {
    background: "#4292E0",
    color: "white",
    fontFamily: " Nunito Sans",
  },
  buttonSave: {
    margin: "1rem",
    float: "right",
  },
});
