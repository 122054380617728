import React from "react";
import Dashboard from "pages/Dashboard";
import Icon from 'components/Icon'
import { useStyles } from "./styles";
import {useSelector} from 'react-redux'
import LogoSimple from "assets/img/Logo_simple.svg";
const HomeView = () => {
  const classes = useStyles();
  const user= useSelector(store=>store.auth.user)
  return (
    <div  className={classes.contentHome} >
  
       {user? <Dashboard />:<div className={classes.welcomeMessage}>
           <Icon icon={LogoSimple} alt="Logo" css={classes.logo} />
         <h1>{`Bienvenido al backoffice de click to pray `}</h1></div>}
 
    </div>
  );
};

HomeView.propTypes = {};

export default HomeView;
