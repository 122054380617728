import React, { useState, useRef } from "react";
import { useStyles } from "./styles";
import Paper from "@material-ui/core/Paper";
import Popover from "@material-ui/core/Popover";
import InputBase from "@material-ui/core/InputBase";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Icon from "components/Icon";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import InfoIcon from "@material-ui/icons/Info";
import IconCalendar from "assets/img/calendar.svg";

export function InputStyled(props) {
  const [isViewPassword, setIsViewPassword] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const inputRef = useRef();
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const idPopover = open ? "simple-popover" : undefined;
  const {
    type = "text",
    value,
    name,
    label,
    placeholder,
    isError = false,
    isSucess = false,
    message,
    isRequired,
    messageInfo,
    id,
    idButtonPassword,
    css,
    onChange,
    onBlur,
    readOnly = false,
    multiline = false,
    rows,
  } = props;
  const classes = useStyles({ isError: isError, type: type });

  return (
    <div className={`${classes.contentInput} ${css}`}>
      <div>
        <label data-testid={`${id}_label`}>
          {label}
          {isRequired && <span data-testid={`${id}_required`}>*</span>}
        </label>
        {messageInfo && (
          <>
            <IconButton
              aria-describedby={idPopover}
              className={classes.buttonInfo}
              id="btn_info"
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              <InfoIcon />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div className={classes.messageInfoContent}>
                <p data-testid="txt_info">{messageInfo}</p>
              </div>
            </Popover>
          </>
        )}
      </div>
      <Paper
        classes={{
          elevation1: classes.elevation,
        }}
        component="div"
      >
        <div style={{ display: "flex" }}>
          {(isError || isSucess) && (
            <IconButton
              className={`${classes.buttons} ${
                isError ? classes.error : classes.sucess
              }`}
              aria-label="icon_status"
              id={`content_icons_error_sucess_${id}`}
              data-testid={`content_icons_error_sucess_${id}`}
            >
              {isError && <ErrorOutlineIcon data-testid={`txt_error_${id}`} />}
              {isSucess && (
                <CheckCircleOutlineIcon data-testid={`txt_sucess_${id}`} />
              )}
            </IconButton>
          )}
          <InputBase
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            type={type === "password" ? (isViewPassword ? "text" : type) : type}
            className={classes.input}
            placeholder={placeholder ? placeholder : null}
            id={id}
            inputProps={{ "data-testid": id }}
            aria-label={id}
            inputRef={inputRef}
            readOnly={readOnly}
            multiline={multiline}
            rows={rows}
          />
          {type === "date" && navigator?.userAgent?.indexOf("Firefox") > -1 && (
            <label style={{ height: "19px" }} htmlFor={id}>
              <Icon icon={IconCalendar} id={`icon_calendar_${id}`} />
            </label>
          )}
          {type === "password" && (
            <IconButton
              className={classes.buttonPassword}
              onClick={() => setIsViewPassword(!isViewPassword)}
              id={`btn_${idButtonPassword}`}
            >
              {isViewPassword ? (
                <VisibilityOffIcon data-testid={`icon_visibility_off-${id}`} />
              ) : (
                <VisibilityIcon data-testid={`icon_visibility_${id}`} />
              )}
            </IconButton>
          )}
        </div>
      </Paper>
      {message && (isError || isSucess) && (
        <div className={` ${isError ? classes.error : classes.sucess}`}>
          <p data-testid={`${id}_error`}>{message}</p>
        </div>
      )}
    </div>
  );
}
