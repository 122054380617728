import React from "react";
import MaterialTable from "material-table";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Search from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Clear from "@material-ui/icons/Clear";
import { useStyles } from "./styles";
import Select from "components/Select";
import Button from "components/Button";
import { FormControl, InputLabel } from "@material-ui/core";

const PopeProfileView = (props) => {
  const {
    prayers,
    languages,
    selectedLanguage,
    handlerChangeLanguage,
    handleNew,
  } = props;

  const tableIcons = {
    Edit: EditIcon,
    Delete: DeleteIcon,
    SortArrow: KeyboardArrowUpIcon,
    Add: AddCircleIcon,
    PreviousPage: ChevronLeft,
    NextPage: ChevronRight,
    Search: Search,
    LastPage: LastPage,
    FirstPage: FirstPage,
    Filter: FilterList,
    Clear: Clear,
  };

  const classes = useStyles();

  return (
    <div data-testid="rosary_list_view">
      <div className={classes.btnNewContainer}>
        <Button text="Nuevo" onClick={() => handleNew()} />
      </div>
      <div>
        <FormControl margin="dense" variant="standard">
          <InputLabel htmlFor="my-input">Idioma</InputLabel>
          <Select
            items={languages}
            handlerOnChange={handlerChangeLanguage}
            defaultValue={selectedLanguage}
          />
        </FormControl>
        <MaterialTable
          title="Oraciones especiales del Papa"
          icons={tableIcons}
          columns={[
            { title: "id", field: "id", hidden: true },
            {
              title: "Titulo",
              field: "title",
              width: "20%",
              render: (rowData) => (
                <div style={{ maxWidth: "470px", overflow: "hidden" }}>
                  {rowData.title}
                </div>
              ),
            },
            {
              title: "Descripción",
              field: "description",
              render: (rowData) => (
                <>
                  <div
                    style={{
                      maxWidth: "750px",
                      overflow: "hidden",
                      maxHeight: "6em",
                    }}
                  >
                    {rowData.description}
                  </div>
                  <span>...</span>
                </>
              ),
            },
            { title: "Lenguaje", field: "language", width: "5%" },
            { title: "Grupo", field: "group", width: "5%" },
            { title: "Fecha publicación", field: "show_date", width: "10%" },
            {
              title: "Acciones",
              field: "actions",
              filtering: false,
              render: (rowData) => <div>{rowData.actions}</div>,
              width: "6%",
            },
          ]}
          data={prayers}
          options={{
            search: false,
            actionsColumnIndex: -1,
            filtering: true,
            pageSize: 20,
            headerStyle: {
              backgroundColor: "#596386",
              color: "#FFF",
              padding: "5px 10px",
            },
            cellStyle: {
              fontSize: ".8em",
              padding: "5px 10px",
            },
            filterCellStyle: { padding: "2px" },
          }}
          localization={{
            header: {
              actions: "Acciones",
            },
          }}
        />
      </div>
    </div>
  );
};

export default PopeProfileView;
