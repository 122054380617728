import React, {useEffect, useState} from "react";
import BlogView from "./BlogView";
import {getPosts} from "services/services";
import { useHistory } from "react-router-dom";
import {BLOG_ADD, BLOG_EDIT} from "../../navigation/CONSTANTS";
import LANG from "../../global/lang";
import Button from "../../components/Button";
import {useStyles} from "../Campaign/styles";

export function BlogContainer(){
  const [posts, setPosts] = useState({});
  const history = useHistory();
  const classes = useStyles();
  function handleEdit(id, lang) {
    history.push(`${BLOG_EDIT}/${id}/${lang}`);
  }

  function handleNew(lang, group) {
    history.push(`${BLOG_ADD}/${lang}/${group}`);
  }
  function fetchPosts(){
    getPosts().then(async (response) => {
      if (response) {
        response.data.forEach((article) => {
          article.image = article.multimedia.find((pic) => pic.type.code==="IMG_POS_CROP");
          let links = [];

          LANG.forEach((lang) => {
            if (article.otherLanguages) {
              article.otherLanguages.push({["ES"]: article.id})
              const langEdit = article.otherLanguages.filter((ol) =>
                Object.keys(ol).includes(lang.code.toUpperCase())
              );
              if (langEdit.length > 0) {
                links.push(
                  <Button
                    css={`${classes.btnSmall} ${classes.btnEdit}`}
                    onClick={() => handleEdit(Object.values(langEdit[0])[0], lang.code)}
                    key={lang.id}
                  >
                    {lang.code.toUpperCase()}
                  </Button>
                );
              } else {
                links.push(
                  <Button
                    css={classes.btnSmall}
                    onClick={() => handleNew(lang.code, article.id)}
                    key={lang.id}
                  >
                    {lang.code.toUpperCase()}
                  </Button>
                );
              }
            }
          });
          article.langs = links;
        })
      }
      setPosts(response);
    })
  .catch(console.warn)
  }

  useEffect(async () => {
    fetchPosts();
  }, []);

  return (
    <BlogView posts={posts} handleEdit={handleEdit} handleNew={handleNew}/>
  );
}
