import makeStyles from "@material-ui/core/styles/makeStyles";
import IconCalendar from "assets/img/calendar.svg";
export const useStyles = makeStyles({
  contentInput: {
    "&>div:first-child": {
      margin: "0px 0px 8px 0px",
      "&>label": {
        fontFamily: "Nunito Sans",
        fontSize: "14px",
        color: "#444C67",
        fontWeight: 400,
        "&>span": {
          color: "red",
        },
      },
    },
  },
  elevation: (props) => {
    let style = {
      boxShadow: "none !important",
      border: "1px solid #DAE3EC",
      padding: "13.50px 16px",
      borderRadius: "6px",
    };
    if (props.isError || props.type === "password") {
      style = {
        ...style,
        padding: "13px 16px",
      };
    }
    return style;
  },
  input: {
    width: "100%",
    "& .MuiInputBase-input": {
      padding: 0,
      fontFamily: "Nunito Sans",
      fontSize: "16px",
      color: "#5D688E",
      fontWeight: 400,
      "&:hover": {
        fontWeight: 600,
      },
      "&:focus": {
        fontWeight: 600,
        "&::placeholder": {
          color: "#C1D7E3",
          fontWeight: 400,
          opacity: 1,
        },
      },
      "&::placeholder": {
        color: "#C1D7E3",
        fontWeight: 400,
        opacity: 1,
      },
    },
    "&>[type=date]": {
      "&::-webkit-calendar-picker-indicator": {
        backgroundImage: `url(${IconCalendar})`,
        opacity: 1,
        display: "block",
        width: "20px",
        height: "20px",
        borderWidth: "thin",
        cursor: "pointer",
      },
    },
  },
  buttons: {
    cursor: "auto",
    padding: "0 !important",
    margin: "0px 10px 0px 0px",
    height: "fit-content",
  },
  error: {
    "&>p": {
      margin: "6px 0px 0px 16px",
      color: "#ECA25E",
      fontFamily: "Mulish",
      fontWeight: "bold",
      fontSize: "12px",
    },
    "&>span:first-child": {
      "&>svg": {
        fill: "#ECA25E",
        width: "20px",
        height: "20px",
      },
    },
  },
  sucess: {
    "&>p": {
      margin: "6px 0px 0px 16px",
      color: "#71AF7B",
      fontFamily: "Mulish",
      fontWeight: "bold",
      fontSize: "12px",
    },
    "&>span:first-child": {
      "&>svg": {
        fill: "#71AF7B",
        width: "20px",
        height: "20px",
      },
    },
  },
  buttonPassword: {
    padding: "0 !important",
    "&>span:first-child": {
      "&>svg": {
        fill: "#414C58",
        width: "20px",
        height: "20px",
      },
    },
  },
  buttonInfo: {
    padding: "0 !important",
    margin: "0px 0px 0px 16px",
    "&>span:first-child": {
      "&>svg": {
        fill: "#444C67",
        width: "20px",
        height: "20px",
      },
    },
  },
  messageInfoContent: {
    padding: "10px !important",
    "&>p": {
      fontFamily: "Nunito Sans",
      fontSize: "12px",
      color: "#444C67",
      whiteSpace: "pre-wrap",
    },
  },
});
