import React from "react";
import { useStyles } from "./styles";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Search from "@material-ui/icons/Search";

export function SchoolCardCustomStyled(props) {
  const { card, searchCampaign, sectionIdx, order, deleteCampaign } = props;
  const classes = useStyles();

  return (
    <div className={classes.box}>
      <div className={`${classes.item}`}>
        {card && (
          <>
            <h4>{card?.title || ""}</h4>
            <p>
              <span>Mostrar:</span> {card?.show_date || ""}
            </p>
            <p>
              <span>Ocultar:</span> {card?.hide_date || ""}
            </p>
            {card?.type?.id === 1 && (
              <>
                <p>
                  <span>Desde:</span> {card?.date_from || ""}
                </p>{" "}
                <p>
                  <span>Hasta:</span> {card?.date_to || ""}
                </p>
              </>
            )}
          </>
        )}
      </div>
      <div className={classes.btnActions}>
        {card?.id ? (
          <div>
            <Edit className={classes.btnEdit} onClick={() => searchCampaign(sectionIdx, order)}/>
            <Delete className={classes.btnEdit} onClick={() => deleteCampaign(sectionIdx, order)}/>
          </div>
        ) : (
          <div>
            <Search className={classes.btnEdit} onClick={() => searchCampaign(sectionIdx, order)}/>
          </div>
        )}
      </div>
    </div>
  );
}
