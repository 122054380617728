export default [
  {
    id: 1,
    code: "SCH",
    description: "Scheduled",
    code_es: "CAL",
    description_es: "Calendarizada",
  },
  {
    id: 2,
    code: "UNS",
    description: "Unscheduled",
    code_es: "NOC",
    description_es: "No Calendarizada",
  },
  {
    id: 3,
    code: "TIP",
    description: "Tips",
    code_es: "TIP",
    description_es: "Consejo",
  },
  {
    id: 4,
    code: "PRO",
    description: "Project",
    code_es: "PRO",
    description_es: "Proyecto",
  },
];
