import React from "react";
import { Button, Typography } from "@material-ui/core";
import { useStyles } from "./styles";

export function ButtonStyled(props) {
  const {
    text,
    type,
    iconLeft,
    iconRight,
    icon,
    variant,
    color,
    disabled,
    css,
    component,
    ...rest
  } = props;

  const classes = useStyles({ type });

  return (
    <Button
      {...rest}
      variant={variant || "contained"}
      color={color || "default"}
      disabled={disabled}
      component={component || "button"}
      className={`${classes.button} ${classes.type} ${css}`}
    >
      {icon ? (
        icon
      ) : (
        <div>
          {iconLeft ? iconLeft : null}
          <Typography component="span">{text || props.children}</Typography>
          {iconRight ? iconRight : null}
        </div>
      )}
    </Button>
  );
}
