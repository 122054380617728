import React from "react";
import MaterialTable from "material-table";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Search from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Clear from "@material-ui/icons/Clear";
import defaultImage from "assets/img/no_photo.png";
import { BLOG_ADD } from "../../navigation/CONSTANTS";

const BlogView = (props) => {
  const { posts } = props;

  const tableIcons = {
    Edit: EditIcon,
    Delete: DeleteIcon,
    SortArrow: KeyboardArrowUpIcon,
    Add: AddCircleIcon,
    PreviousPage: ChevronLeft,
    NextPage: ChevronRight,
    Search: Search,
    LastPage: LastPage,
    FirstPage: FirstPage,
    Filter: FilterList,
    Clear: Clear,
  };
  return (
    <div data-testid="blog_view">
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "10px 20px",
        }}
      >
        <a
          href={`${BLOG_ADD}/es`}
          data-testid="btn_add_post"
          style={{
            padding: "0.75em 1em",
            borderRadius: "0.375em",
            backgroundColor: "#e0e0e0",
            color: "rgba(0, 0, 0, 0.87)",
            textDecoration: "none",
            fontSize: ".9em"
          }}
        >
          Nuevo
        </a>
      </div>
      <div>
        <MaterialTable
          title="Art&iacute;culos del Blog"
          icons={tableIcons}
          columns={[
            { title: "Id", field: "id", hidden: true },
            {
              title: "IMAGEN",
              field: "image",
              width: "10%",
              filtering: false,
              render: (rowData) => (
                <img src={rowData.image?.url || defaultImage} style={{ width: "70px" }} />
              ),
            },
            { title: "TITULO", field: "title", render: (rowData) => (
              <div style={{ maxWidth: "470px", overflow: "hidden" }} >{rowData.title}</div>
            )},
            { title: "AUTOR", field: "author" , width: "15%", render: (rowData) => (
              <div style={{ maxWidth: "200px", overflow: "hidden" }} >{rowData.author}</div>
            )},
            { title: "PUBLICACION", field: "publish_date", width: "6%", render: (rowData) => (
              <div style={{ maxWidth: "max-content", overflow: "hidden" }} >{rowData.publish_date}</div>
            )},
            {
              title: "LENGUAJES",
              field: "langs",
              filtering: false,
              render: (rowData) => <div style={{ width: "max-content" }}>{rowData.langs}</div>,
            },
          ]}
          data={posts?.data}
          options={{
            search: false,
            actionsColumnIndex: -1,
            filtering: true,
            pageSize: 20,
            headerStyle: {
              backgroundColor: "#596386",
              color: "#FFF",
              padding: "5px 10px",
            },
            filterCellStyle: {padding: "2px"},
            doubleHorizontalScroll: true
          }}
        />
      </div>
    </div>
  );
};

export default BlogView;
