import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  wrapper: {
    margin: "1em 0",
    padding: "10px",
    textTransform: "uppercase",
  },
  wrapperDel: {
    margin: "1em 0",
    padding: "10px",
    textTransform: "uppercase",
    backgroundColor: "#b35e5e",
    border: "1px solid red"
  },
  label: {
    fontSize: ".8rem",
    fontWeight: "normal",
    lineHeight: 1,
    marginRight: ".8em",
    textTransform: "uppercase",
    color: "rgba(0, 0, 0, 0.54);",
    marginBottom: "5px;"
  },
  current: {
    color: "white",
    backgroundColor: "#E7C26D",
    borderRadius: "5px",
    padding: "0 5px",
  },
  imageUpload: {
    maxWidth: "95%",
    maxHeight: "150px",
  },
  noImage: {
    width: "95%",
    height: "150px",
    backgroundColor: "#f2f2f2",
    borderRadius: "2%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "10px"
  },
  noImageIcon: {
    color: "#999"
  },
  hide: { display: "none" },
  btnUpload: {
    padding: "7px 10px",
  },
  order: {
    margin: "40% 10px",
  }
});
