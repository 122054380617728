import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import MediationEditView from "./MediationEditView";
import {getReportedById, postMediate} from "../../../services/services";
import { MEDIATION } from "../../../navigation/CONSTANTS";

export function MediationEditContainer() {
  const { id, type } = useParams();
  const [mediate, setMediate] = useState({});
  const history = useHistory();

  useEffect(() => {
    if (id && type) fetchReported(id, type);
  }, [id]);

  function fetchReported(reportedId, reportedType) {
    getReportedById(reportedId, reportedType)
      .then((data) => {
        if (!data)
          return history.push(MEDIATION, {
            message: "Problema al cargar la información",
          });
        setMediate(data);
      })
      .catch(() =>
        history.push(MEDIATION, {
          message: "Problema al cargar la información",
        })
      );
  }

  function handleSave(itemId, body) {
    if (itemId) {
      let msg = body?.deleted ? "Borrado exitoso" : "Revisado exitosamente";
      postMediate(itemId, body)
        .then(() => {
          history.push(MEDIATION, {
            message: msg,
          });
        })
        .catch((err) =>
          history.push(MEDIATION, {
            message: `Error ${err.response?.data?.message}`,
          })
        );
    }
  }

  function handleBack() {
    history.push(MEDIATION);
  }

  return (
    <div style={{ padding: 20 }}>
      <MediationEditView
        handleBack={handleBack}
        reported={mediate}
        type={type}
        //message={message}
        handleSave={handleSave}
      />
    </div>
  );
}
