import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  paper: {
    position: "absolute",
    width: "70%",
    backgroundColor: "white",
    border: "2px solid #000",
    padding: "0.5em",
    top: "40%",
    left: "50%",
    transform: "translate(-40%, -40%)",
  },
  messageBox: {
    width: "50%",
    padding: "5px 15px",
    borderRadius: "5px",
    backgroundColor: "rgb(253, 236, 234)",
    color: "rgb(136, 37, 30)",
    marginTop: "15px",
    right: 0,
    zIndex: 1000,
  },
});
