import React from "react";
import User from "./UserView";

export function UserContainer() {

  return (
    <>
      <User />
    </>
  );
}
