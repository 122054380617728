import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  paper: {
    position: "absolute",
    width: "max-content",
    backgroundColor: "white",
    border: "1px solid #000",
    padding: "0.5em",
    top: "30%",
    left: "50%",
    transform: "translate(-40%, -40%)",
    display: "flex",
    flexDirection: "column",
    height: "max-content",
    maxHeight: "90vh",
    minWidth: "50%",
    maxWidth: "90vw",
    flexWrap: "wrap"
},
  col1: {
    width:"35%",
  },
  col2: {
    width:"65%"
  },
  col3: {
    flex: "1 100%",
    paddingTop:"20px",
    textAlign: "right",
    "& button": {
      marginRight: 20,
    },
  },
  wrapImage: {
    "& img": {
      maxWidth: "80%",
      maxHeight: "300px",
    }
  },
  current: {
    color: "white",
    backgroundColor: "#E7C26D",
    borderRadius: "5px",
    padding: "0 5px",
  },
  imageUpload: {
    maxWidth: "95%",
    maxHeight: "150px",
  },
  noImage: {
    width: "95%",
    height: "150px",
    backgroundColor: "#f2f2f2",
    borderRadius: "2%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "10px"
  },
  noImageIcon: {
    color: "#999"
  },
  hide: { display: "none" },
  btnUpload: {
    padding: "7px 10px",
    margin: 7,
  },
  order: {
    margin: "40% 10px",
  }
});
