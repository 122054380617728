import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  sectionBox: {
    margin: 0,
    padding: 0,
    "& li:nth-child(odd)": {
      backgroundColor: "#eff5ef",
    },
  },
  section: {
    margin: "0",
    padding: "10px 20px 50px 20px",

  },
  sectionBtn: {
    textAlign: "center",
    marginTop: "10px",
    "& button": {
      backgroundColor: "#e7c26d",
      padding: "5px 15px 3px 15px",
      marginRight: "5px",
      lineHeight: "1.5em",
    },
  },
  row: {
    margin: "5px 0",
    position: "relative",
    width: "99%",
  },
  colName: {
    marginBottom: "0.5em",
    textAlign: "center",
    color: "#c3c2c1",
  },
  messageBox: {
    width: "50%",
    padding: "5px 15px",
    borderRadius: "5px",
    backgroundColor: "#d4edda",
    color: "#155724",
    position: "sticky",
    top: "5px",
    right: 0,
    margin: "8px 0 -30px 15px",
    zIndex: 1000,
  },
  msgError: {
    backgroundColor: "rgb(253, 236, 234)",
    color: "rgb(136, 37, 30)",
  },
  msgWarn: {
    backgroundColor: "rgb(255 165 0 / 44%)",
    color: "#611111",
  },
  clearCustom: {
    position: "absolute",
    right: "-25px",
    top: "20px",
    cursor: "pointer",
    "& :hover": {
      color: "red",
    },
  },
  label: {
    fontSize: ".8rem",
    fontWeight: "normal",
    lineHeight: 1,
    marginRight: ".8em",
    color: "rgba(0, 0, 0, 0.54)",
    marginTop: "1.5em"
  },
});
