import request from "./http";

export function getCountries() {
  return request({
    url: `/backoffice/countries`,
  });
}

//---- prayers
export function getPrayerById(id) {
  return request({
    url: `/backoffice/prayers/${id}`,
  });
}

export function getPrayers({ date_from, date_to }, type = [], language = []) {
  return request({
    url: `/backoffice/prayers/BO`,
    params: {
      per_page: 99999,
      date_from,
      date_to,
      language: language.join(),
      type: type.join(),
    },
  });
}

export function editPrayer({ id, show_date, language, description, title = "", group = "" }) {

  return request({
    url: `/backoffice/prayers/${id}`,
    method: "PUT",
    data: { show_date, language, description, title, group },
  });
}

export function addPrayer({
  description,
  language,
  show_date,
  type,
  title = "isRequeride?",
  group = ""
}) {
  return request({
    url: `/backoffice/prayers/`,
    method: "POST",
    data: {
      title,
      description,
      show_date,
      language,
      type,
      group
    },
  });
}

export function upsertPrayers(array) {
  const data = array
    .map(
      ({ id, title, description, show_date, intention, type, language }) => ({
        id,
        description,
        show_date,
        intention,
        type,
        language,
        title: title || "default",
      })
    )
    .filter((p) => p.description);

  return request({
    url: `/backoffice/prayers/upsert`,
    method: "POST",
    data: data,
  });
}

export function deletePrayer(id) {
  return request({
    url: `/backoffice/prayers/${id}`,
    method: 'DELETE',
  });
}

export function getPrayersBO(language = [], section = 0) {
  return request({
    url: `/backoffice/prayers-school/BO`,
    params: {
      language: language,
      section: section
    },
  });
}

export function getPrayersBOList(language = [], type = '') {
  return request({
    url: `/backoffice/prayers-school/BO/list`,
    params: {
      language: language,
      type: type
    },
  });
}

export function resetPrayersCache() {
  return request({
    url: `/backoffice/prayers/refresh`,
  });
}

export function saveSchool(data) {
  return request({
    url: `/backoffice/prayers-school/BO`,
    method: "POST",
    data: data,
  });
}
//----- intentions
export function getIntentionByLanguage({ date_from, date_to }, language = []) {
  return request({
    url: `/backoffice/intentions/BO`,
    params: { date_from, date_to, language: language.join() },
  });
}

export function editIntention({ id, title, description }) {
  return request({
    url: `/backoffice/intentions/${id}`,
    method: "PUT",
    data: { title, description },
  });
}

export function addIntention({
  title,
  description,
  language,
  date_from,
  date_to,
  type = "mon",
}) {
  return request({
    url: `/backoffice/intentions/`,
    method: "POST",
    data: {
      title,
      description,
      date_from,
      date_to,
      language,
      type,
    },
  });
}

export function upsertIntentions(array) {
  const data = array
    .map(({ id, title, description, date_from, date_to, language, type, video_url }) => ({
      id,
      title,
      description,
      date_from,
      date_to,
      language,
      type,
      video_url
    }))
    .filter((i) => i.title && i.description);

  return request({
    url: `/backoffice/intentions/upsert`,
    method: "POST",
    data: data,
  });
}

export function resetIntentions() {
  return request({
    url: `/backoffice/intentions/cache`,
  });
}

// ------ attitudes
export function getAttitudes(id_intention) {
  return request({
    url: `/backoffice/intentions/${id_intention}/attitudes`,
  });
}

export function editAttitude({ id, description, order }) {
  return request({
    url: `/backoffice/attitudes/${id}`,
    method: "PUT",
    data: { description, order },
  });
}

export function addAttitude({ description, intention, order }) {
  return request({
    url: `/backoffice/attitudes/`,
    method: "POST",
    data: { description, intention, order },
  });
}

export function upsertAttitude(array) {
  const data = array
    .map(({ id, description, intention, order }) => ({ id, description, intention, order }))
    .filter((a) => a.intention && a.description);

  return request({
    url: `/backoffice/attitudes/upsert`,
    method: "POST",
    data: data,
  });
}

//------ bishop
export function getBishopConferences({ date_from, date_to }) {
  return request({
    url: `/backoffice/bishop-conferences`,
    params: { date_from, date_to },
  });
}

export function editBishopConference({ id, description, date_from, date_to }) {
  return request({
    url: `/backoffice/bishop-conferences/${id}`,
    method: "PUT",
    data: { description, date_from, date_to },
  });
}

export function addBishopConference({
  date_from,
  date_to,
  description,
  country,
}) {
  return request({
    url: `/backoffice/bishop-conferences`,
    method: "POST",
    data: {
      date_from,
      date_to,
      description,
      country,
    },
  });
}

export function upsertBishopConference(array) {
  const data = array
    .map(({ id, date_from, date_to, description, country }) => ({
      id,
      date_from,
      date_to,
      description,
      country,
    }))
    .filter((c) => c.country);

  return request({
    url: "/backoffice/bishop-conferences/upsert",
    method: "POST",
    data: data,
  });
}

export function setPublishStatus(id, newStatus) {
  return request({
    url: `/backoffice/intentions/${id}/status`,
    data: { status: newStatus },
    method: "PUT",
  });
}

export function addOneMultimedia(body) {
  return request({
    headers: { "Content-Type": "multipart/form-data" },
    url: "/multimedia/multimedia",
    method: "POST",
    data: body,
  });
}

export function addMultimedias(body) {
  return request({
    headers: { "Content-Type": "multipart/form-data" },
    url: "/multimedia/multimedia/new",
    method: "POST",
    data: body,
  });
}

export function deleteMultimedia(body) {
  return request({
    url: "/multimedia/multimedia/delete",
    method: "POST",
    data: body,
  });
}

export function associateMultimedia(body) {
  return request({
    url: "/multimedia/multimedia/associate",
    method: "POST",
    data: body,
  });
}

//Blog
export function getPosts() {
  return request({
    url: `/blog/posts/BO`,
    params: { ignore_language: false, other_languages: true, per_page: 99999 },
    headers: { "Accept-Language": "es" },
  });
}

export function getPostById(id, lang) {
  return request({
    url: `/blog/posts/${id}?other_languages=true`,
    headers: { "Accept-Language": lang },
  });
}

export function addPost({
  title,
  blockquote,
  body,
  author,
  publish_date,
  language,
  group,
  job_title
}) {
  return request({
    url: `/blog/posts`,
    method: "POST",
    data: { title, blockquote, body, author, publish_date, language, group, job_title },
  });
}

export function editPost({
                           id,
                           title,
                           blockquote,
                           body,
                           author,
                           publish_date,
                           job_title
                         }) {
  return request({
    url: `/blog/posts/upsert`,
    method: "POST",
    data: [{
      id,
      title,
      blockquote,
      body,
      author,
      publish_date,
      job_title
    }],
    headers: { "Accept-Language": "ES" },
  });
}

export function deletePostById(id) {
  return request({
    url: `/blog/posts/${id}`,
    method: "DELETE",
  });
}

//Campaigns
export function getCampaigns() {
  return request({
    url: `/backoffice/campaigns?ignore_language=false&other_languages=true&per_page=9999`,
    headers: { "Accept-Language": "es" },
  });
}

export function getCampaignsByGroup(groupId) {
  return request({
    url: `/backoffice/campaigns?ignore_language=true`,
    params: { group: groupId },
  });
}

export function getBaseCampaign(groupId) {
  return request({
    url: `/backoffice/campaigns?ignore_language=false&include_multimedia=true`,
    headers: { "Accept-Language": "es" },
    params: { group: groupId },
  });
}

export function getCampaignById(id) {
  return request({
    url: `/backoffice/campaigns/${id}?other_languages=true`,
  });
}

export function editCampaign(array) {
  const data = array.map(
    ({
      id,
      title,
      description,
      moment,
      link,
      date_from,
      date_to,
      show_date,
      hide_date,
      showing_home,
      showing_tab,
      language,
      type,
      group,
    }) => ({
      id,
      title,
      description,
      moment,
      link,
      date_from,
      date_to,
      show_date,
      hide_date,
      showing_home,
      showing_tab,
      language,
      type,
      group,
    })
  );
  return request({
    url: `/backoffice/campaigns/upsert`,
    method: "POST",
    data: data,
  });
}

export function upsertCampaignPrayers({
  id,
  title,
  description,
  hashtag,
  order,
  campaign,
  date,
}) {
  return request({
    url: `/backoffice/campaign-prayers/upsert`,
    method: "POST",
    data: [{
      id,
      title,
      description,
      hashtag,
      order,
      campaign,
      date,
    }],
  });
}

export function upsertCampaignTips({ id, description, campaign, language, date }) {
  return request({
    url: `/backoffice/tips/upsert`,
    method: "POST",
    data: [{
      id,
      description,
      campaign,
      language,
      date,
    }],
  });
}

export function deleteCampaignById(id) {
  return request({
    url: `/backoffice/campaigns/${id}`,
    method: "DELETE",
  });
}

export function deleteCampaignPrayer(id) {
  return request({
    url: `/backoffice/campaign-prayers/${id}`,
    method: "DELETE",
  });
}

export function deleteCampaignTip(id) {
  return request({
    url: `/backoffice/tips/${id}`,
    method: "DELETE",
  });
}

export function getRosaries(groupId) {
  return request({
    url: `/backoffice/eRosary`,
    method: "GET",
    headers: { "Accept-Language": "es" },
    params: {
      ignore_language: true,
      other_languages: true,
      per_page: 9999,
      group: groupId || undefined,
    }
  });
}


export function getTemplate(id, language) {
  return request({
    url: `/backoffice/eRosary/template`,
    method: "GET",
    params: {
      id_rosary: id || undefined,
      other_languages: true,
      language: language,
    }
  });
}

export function upsertRosary({ id, description, show_date, group, language, title, moment, is_published, showing_home, showing_tab }) {
  return request({
    url: `/backoffice/eRosary/upsert`,
    method: "POST",
    data: [{ id, description, show_date, group, language, title, moment, is_published, showing_home, showing_tab }],
  });
}

export function upsertMysteries(arrMysteries) {
  return request({
    url: `/backoffice/mysteries/upsert`,
    method: "POST",
    data: arrMysteries,
  });
}

export function upsertSteps(arrSteps) {
  return request({
    url: `/backoffice/eRosary/steps`,
    method: "POST",
    data: arrSteps,
  });
}
export function upsertMysteryGroups(arrGroups) {
  return request({
    url: `/backoffice/mysteries/group/upsert`,
    method: "POST",
    data: arrGroups,
  });
}

export function getStandarPrayers(language) {
  return request({
    url: `/backoffice/eRosary/standard-prayers`,
    params: { language, per_page: 9999 },
  });
}

export function deleteRosaryById(id) {
  return request({
    url: `/backoffice/eRosary/${id}`,
    method: "DELETE",
  });
}


export function postLogin(data) {
  return request({
    url: "user/auth/sign-in/backoffice",
    method: "POST",
    data,
  });
}

export function refreshToken(token) {
  return request({
    url: `user/auth/refresh-token/${token}/backoffice`,
    method: "GET",
  });
}

export function updatePassword(data) {
  return request({
    url: "user/users/modify-password",
    method: "PUT",
    data,
  });
}

export function getUsers(page_size = 20, page = 1, {first_name, last_name, email, id_rol}) {
  return request({
    url: "/user/users",
    method: "GET",
    params: { page: page, per_page: page_size, first_name: first_name, last_name: last_name, email: email, id_role: id_rol },
  });
}

export function banUser(id) {
  return request({
    url: `/user/users/${id}/ban`,
    method: "PUT"
  });
}

export function unbanUser(id) {
  return request({
    url: `/user/users/${id}/unban`,
    method: "PUT"
  });
}

export function getReported(language = [], status) {
  return request({
    url: `/community/mediate`,
    params: {
      language: language.join(),
      status: status
    },
  });
}

export function getReportedById(id, type) {
  return request({
    url: `/community/mediate/${id}?reported_type=${type}`,
  });
}

export function postMediate(id, body) {
  return request({
    url: `/community/mediate/${id}`,
    method: "POST",
    data: body,
  });
}

export function postSignUp(data) {
  return request({
    url: 'user/users/sign-up/backoffice',
    method: 'POST',
    data,
  });
}
