import React from "react";
import { useStyles } from "./styles";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import Button from "components/Button";
import Input from "components/Input";
import { Fade, Backdrop, Modal } from "@material-ui/core";
import Icon from "components/Icon";
import IconClose from "assets/img/Close.svg";

export function AuthFormLogin(props) {
  const loginScheme = Yup.object().shape({
    email: Yup.string()
      .email('You must enter a valid email')
      .required('Email Required'),
    password: Yup.string()
      .min(6, `Must be at least 6 characters`)
      .required(`Password Required`),
  });
  const initialValueLogin = {
    email: "",
    password: "",
  };
  const {
    isOpen,
    isMd = false,
    isLg = false,
    handleClose,
    handleSubmitLogin,
  } = props;
  const classes = useStyles({
    mediaQueryMd: isMd,
    mediaQueryLg: isLg,
  });

  const loginContent = () => {
    return (
      <div className={`${classes.contentLogin}`}>
        <div className={classes.AuthFormLogin}>
          <div className={classes.closeIconContent}>
            <Icon
              onClick={() => handleClose()}
              icon={IconClose}
              alt="close"
              id="ico_authForm_close_modal"
            />
          </div>
          <div className={classes.wrapperLogin}>
            <div className={classes.titleContent}>
              <h1 data-testid={`txt_title_authForm`}>
              ¡Hola, de nuevo!
              </h1>
              <p data-testid={`txt_description_authForm`}>
              Inicie sesión para acceder a los contenidos exclusivos cuidadosamente preparados para aquellos que buscan crecer en comunión.
              </p>
            </div>
            <div className={classes.contentText}>
              <p data-testid="txt_authForm_message">
              
              </p>
            </div>
            <Formik
              validationSchema={loginScheme}
              initialValues={initialValueLogin}
            >
              {({
                dirty,
                isValid,
                errors,
                values,
                handleChange,
                handleBlur,
                touched,
              }) => {
                return (
                  <Form>
                    <Grid container>
                      <Grid
                        className={classes.rowInput}
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <Input
                          label='Email'
                          name="email"
                          id="txt_email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          message={errors.email}
                          isError={errors.email && touched.email}
                          isSucess={!errors.email && touched.email}
                          isRequired
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Input
                          name="password"
                          label='Contraseña'
                          type="password"
                          id="txt_password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          message={errors.password}
                          isError={errors.password && touched.password}
                          isSucess={!errors.password && touched.password}
                          isRequired
                        />
                      </Grid>
                      <Grid
                        className={classes.contentRecoverPassword}
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                      </Grid>
                      <Grid
                        className={classes.rowButtons}
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <Button
                          data-testid="btn_login"
                          id="btn_login"
                          onClick={() => handleSubmitLogin(values)}
                          disabled={!(isValid && dirty)}
                          type="primary"
                          text='Inicio de sesión'
                        />
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Modal
        open={isOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className={classes.loginModal}
        data-testid="login-content_modal"
      >
        <Fade in={isOpen}>{loginContent()}</Fade>
      </Modal>
    </>
  );
}
