import _ from "lodash";
import React, { useEffect, useState } from "react";
import RosaryEditView from "./RosaryEditView";
import { useParams, useHistory } from "react-router-dom";
import {
  getTemplate,
  upsertMysteries,
  upsertMysteryGroups,
  upsertRosary,
  upsertSteps,
  associateMultimedia,
  deleteMultimedia,
  deleteRosaryById,
} from "services/services";

import { ROSARY } from "../../../navigation/CONSTANTS";
import { humanizeProps } from "../../../helpers/humanizeResponse";

export function RosaryEditContainer({ location }) {
  const { id, lang } = useParams();
  const [rosary, setRosary] = useState({});
  const [message, setMessage] = useState({
    text: location.state?.message ? location.state.message : "",
    msgType: "msgWarn",
  });
  const ctgy = ["joyful", "sorrowful", "glorious", "luminous"];
  const history = useHistory();

  useEffect(() => {
    if (id) fetchRosary(id, lang);
  }, []);

  function fetchRosary(id, lang) {
    getTemplate(id, lang).then((data) => {
      if (!data) return history.push(ROSARY);
      data.moment = data.moment.code;
      if (data?.otherLanguages.length > 0) {
        //TODO: remove self language from otherLangs on service
        data.otherLanguages = data.otherLanguages.filter(
          (langs) => Object.keys(langs)[0] != data.language.code
        );
      }
      setRosary(data);
    });
  }

  function handleBack() {
    history.push(ROSARY);
  }

  async function saveRosary(rosary, steps, multimedias) {
    let arrReq = [];
    const rosId = Number(id);
    const rosaryLang = rosary.language?.code || lang;
    let rosaryUpdate = { ...rosary };
    delete rosaryUpdate.language;
    arrReq.push(upsertRosary(rosaryUpdate));

    let arrMystery = [],
      arrPrayers = [];
    if (steps) {
      steps.forEach((step, section) => {
        if (step.mystery.length > 0) {
          let mysteries = step.mystery.map((mysteri) => {
            let m = {};
            if (mysteri.is_standard) {
              m = {
                title: mysteri.title,
                short_title: mysteri.title,
                description: mysteri.description,
                category: mysteri.category,
                rosary: rosId ,
                order: mysteri.order,
                language: rosaryLang,
              };
              multimedias.mystery.push(
                ...mysteri.multimedia.map((m) => ({
                  multimedia_id: m.id,
                  oldMultimedia: [],
                  ownerType: "MYS",
                  section: mysteri.order,
                  index: ctgy.findIndex((c) => c === mysteri.category),
                }))
              );
            } else {
              m = {
                id: mysteri.id,
                title: mysteri.title,
                short_title: mysteri.title,
                description: mysteri.description,
                order: mysteri.order,
                category: mysteri.category,
                language: !mysteri.id ? rosaryLang : undefined,
                rosary: rosId,
              };
            }

            return m;
          });
          arrMystery = [...arrMystery, ...mysteries];
        }

        if (step.prayers.length > 0) {
          let prayers = step.prayers.map(
            ({ id, title, description, quantity, category, is_standard }) => ({
              id,
              title,
              description,
              quantity,
              language: is_standard || id ? undefined : rosaryLang,
              id_rosary: rosId,
              section: section,
              category,
              is_standard,
            })
          );
          arrPrayers = [...arrPrayers, ...prayers];
        }
      });

      let groupsToApi = [];
      steps.group.forEach((grp) => {
        let g = {};
        if (grp.is_standard) {
          g = {
            title: grp.title,
            description: grp.description,
            category: grp.category,
            rosary: grp.id ? rosId : undefined,
            video_url: grp.video_url || '',
            language: rosaryLang,
          };

          multimedias.group.push(
            ...grp.multimedia
              .filter((m) => m.id)
              .map((m) => ({
                index: g.category,
                multimedia_id: m.id,
                oldMultimedia: [],
                ownerType: "MYG",
              }))
          );
        } else {
          g = {
            id: grp.id,
            title: grp.title,
            description: grp.description,
            category: grp.category,
            video_url: grp.video_url || '',
          };
        }

        groupsToApi.push(g);
      });
      arrReq.push(upsertMysteryGroups(groupsToApi));
      arrReq.push(upsertMysteries(arrMystery));
      arrReq.push(upsertSteps(arrPrayers));
    }

    Promise.all(arrReq)
      .then((res) => {
        if (res) {
          res.push(steps.group);
          res.push(arrMystery);
          setMessage({ text: "Datos guardados" });
          saveMultimedias(res, multimedias);
        }
        fetchRosary(id);
      })
      .catch((err) =>
        setMessage({
          text: humanizeProps(err.response?.data?.message),
          msgType: "msgError",
        })
      );
  }

  function saveMultimedias(datas, multimedias) {
    setMessage({ text: "Guardando imagenes y audios..." });

    const groups = datas[1] || [];
    const arrGrops = datas[4] || [];
    const mysterys = datas[2] || [];
    const arrMyst = datas[5] || [];
    const steps = datas[3] || [];
    multimedias.prayers.forEach((m) => {
      let stp = steps.find(
        (stp) => stp.section === m.section && stp.section === m.index
      );
      if (stp) m.owner = stp.id;
    });
    mysterys.forEach((mm) => {
      let mys = arrMyst.find(
        (m) =>
          m.id === mm.id ||
          (m.title === mm.title && m.description === mm.description)
      );
      mm.section = mm.order;
      mm.index = ctgy.findIndex((c) => c === mys.category);
    });
    multimedias.mystery.forEach((m) => {
      let stp = mysterys.find(
        (stp) => stp.order === m.section && stp.index === m.index
      );
      if (stp) m.owner = stp.id;
    });
    groups.forEach((gg) => {
      let group = arrGrops.find(
        (g) =>
          g.id === gg.id ||
          (g.title === gg.title && g.description === gg.description)
      );
      gg.category = group.category;
    });
    multimedias.group.forEach((m) => {
      let stp = groups.find((g) => g.category === m.index);
      if (stp) m.owner = stp.id;
    });

    const toApi = [
      ...multimedias.mystery,
      ...multimedias.prayers,
      ...multimedias.group,
    ];
    const asociate = toApi
      .filter((m) => m.owner && m.multimedia_id)
      .map((m) => ({
        multimedia_id: m.multimedia_id,
        owners: [{ owner: m.owner, ownerType: m.ownerType }],
      }));
    const oldMultimedias = _.concat(
      ...toApi.map((m) =>
        m.oldMultimedia.map((id) => ({
          multimedia_id: id,
          owner: m.owner,
          ownerType: m.ownerType,
        }))
      )
    );
    associateMultimedia(_.uniqWith(asociate, _.isEqual)).then(() =>
      deleteMultimedia(oldMultimedias)
    ); // si estas queys rompen, no pasa naaada ;)

    setMessage({ text: "Datos guardados." });
  }

  function deleteRosary(id) {
    if (id) {
      deleteRosaryById(id)
        .then((res) => {
          history.push(ROSARY, {
            message: `Borrado exitoso de ${res.title}`,
          });
        })
        .catch((err) =>
          setMessage({
            text: humanizeProps(err.response?.data?.message),
            msgType: "msgError",
          })
        );
    }
  }

  return (
    <RosaryEditView
      currentRosary={rosary}
      message={message}
      handleBack={handleBack}
      saveRosary={saveRosary}
      deleteRosary={deleteRosary}
    />
  );
}
