/* eslint-disable */
import {
  updatePassword,

} from "services/services";

export async function handleModifyPassword(value, dispatch) {
  let newPassword = {
    old_password: value.currentPassword,
    new_password: value.newpassword,
  };

  try {
    await updatePassword(newPassword);
  } catch (error) {
    dispatch({
      type: "@modal/error",
      payload: {
        open: true,
        message: { name: error?.name, messageError: error?.message },
      },
    });
  }
}
export async function handleEditUser(person, dispatch, user) {
  let updatePerson = {
    first_name: person.name,
    last_name: person.surname,
    id_country: person?.country?.id.toString(),
    borned_date: person?.dateOfBirth,
    id_gender: person?.gender?.id,
  };
  try {
    await putUser(updatePerson, user.id);

    await dispatch({
      type: "@auth/user",
      payload: {
        ...user,
        person: {
          ...updatePerson,
        },
      },
    });
  } catch (error) {
    dispatch({
      type: "@modal/error",
      payload: {
        open: true,
        message: { name: error?.name, messageError: error?.message },
      },
    });
  }
}

export async function handleEditorAvatar(image, dispatch, user) {
  // if (image?.props?.image) {
  //   let owner = [
  //     {
  //       name: image?.props?.image.name,
  //       type: "IMG",
  //       owners: [{ owner: user.id, ownerType: "USR" }],
  //     },
  //   ];
  //   let formData = new FormData();

  //   try {
  //     formData.append("", image?.props?.image);
  //     formData.append("metadata", JSON.stringify(owner));

  //     await putAvatarUser(formData);
  //     await dispatch({
  //       type: "@auth/user",
  //       payload: {
  //         ...user,
  //         avatar: image.getImageScaledToCanvas().toDataURL(),
  //       },
  //     });
  //   } catch (error) {
  //     dispatch({
  //       type: "@modal/error",
  //       payload: {
  //         open: true,
  //         message: { name: error?.name, messageError: error?.message },
  //       },
  //     });
  //   }
  // }
}

export async function handleCloseAccountUser(dispatch, user) {
  // try {
  //   await deleteUser(user.id);
  // } catch (error) {
  //   dispatch({
  //     type: "@modal/error",
  //     payload: {
  //       open: true,
  //       message: { name: error?.name, messageError: error?.message },
  //     },
  //   });
  // }
}

export async function getGenders(setGenders, t) {
  // let response = await genders();
  // if (response)
  //   response = response.map((gender) => {
  //     return { ...gender, value: t(`components.signUp.${gender.description}`) };
  //   });
  // setGenders(response);
}
