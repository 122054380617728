import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  messageBox: {
    width: "50%",
    padding: "5px 15px",
    borderRadius: "5px",
    backgroundColor: "#d4edda",
    color: "#155724",
  },
  msgError: {
    backgroundColor: "rgb(253, 236, 234)",
    color: "rgb(136, 37, 30)",
  },
  btnSmall: {
    padding: "5px 10px",
    marginRight: "2px",
    minWidth: "45px",
  },
  languageSelect: {
    margin: "0.7em",
  },
  paper: {
    padding: 20,
    height: "max-content",
    margin: "auto",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  confirmation: {
    width: "max-content",
  },
  btnRow: {
    display: "flex",
    justifyContent: "center",
    "& button": {
      margin: 10,
    },
  },
});
