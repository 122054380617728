
import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
    label: {
        fontSize: ".8em",
        marginRight: ".8em",
        textTransform: "uppercase"
    },
});
