import { postLogin } from "./services";

const USER_KEY = "user_backoffice";
const ACCESSTOKEN_KEY = "access_token_backoffice";
const EXP_KEY = "exp_backoffice";
const REFRESH_TOKEN = "refresh_backoffice";

export function setSession(user, access_token, expiration, refreshToken) {
  if (user.avatar.length > 0) {
    user.avatar = user.avatar[0]?.url;
  } else user.avatar = null;
  if (user.person.gender) {
    user.person.id_gender = user.person.gender.id;
  }

  sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  sessionStorage.setItem(ACCESSTOKEN_KEY, JSON.stringify(access_token));
  sessionStorage.setItem(EXP_KEY, JSON.stringify(expiration));
  sessionStorage.setItem(REFRESH_TOKEN, JSON.stringify(refreshToken));
}

export function setRefreshToken(access_token, expiration, refreshToken) {
  sessionStorage.setItem(ACCESSTOKEN_KEY, JSON.stringify(access_token));
  sessionStorage.setItem(EXP_KEY, JSON.stringify(expiration));
  sessionStorage.setItem(REFRESH_TOKEN, JSON.stringify(refreshToken));
}

export function getUser() {
  return JSON.parse(sessionStorage.getItem(USER_KEY));
}

export function getAccessToken() {
  return JSON.parse(sessionStorage.getItem(ACCESSTOKEN_KEY));
}

export function getExpirationTime() {
  const expiration = JSON.parse(sessionStorage.getItem(EXP_KEY));
  return parseInt(expiration) * 1000;
}

export function getRefreshToken() {
  return JSON.parse(sessionStorage.getItem(REFRESH_TOKEN));
}

export function updateReducer(dispatch) {
  const user = sessionStorage.getItem(USER_KEY);
  const token = sessionStorage.getItem(ACCESSTOKEN_KEY);
  const exp = sessionStorage.getItem(EXP_KEY);
  const refresh = sessionStorage.getItem(REFRESH_TOKEN);

  dispatch({ type: "@auth/user", payload: JSON.parse(user) });
  dispatch({ type: "@auth/token", payload: token });
  dispatch({ type: "@auth/exp", payload: exp });
  dispatch({ type: "@auth/refresh", payload: refresh });
}

export function clearSession(dispatch) {
  sessionStorage.removeItem(USER_KEY);
  sessionStorage.removeItem(ACCESSTOKEN_KEY);
  sessionStorage.removeItem(EXP_KEY);
  sessionStorage.removeItem(REFRESH_TOKEN);
  dispatch({ type: "@auth/user", payload: null });
  dispatch({ type: "@auth/token", payload: null });
  dispatch({ type: "@auth/exp", payload: null });
}

export function logout(dispatch) {
  clearSession(dispatch);
}

export function singIn({ email, password, dispatch }) {
  /* eslint-disable no-async-promise-executor */
  return new Promise(async (resolve, reject) => {
    try {
      const res = await postLogin({
        email: email,
        password: password,
      });
      res.user.isRSS = false;
      setSession(res.user, res.access_token, res.expiration, res.refresh_token);
      updateReducer(dispatch);
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}

export function getUserStorage() {
  return JSON.parse(sessionStorage.getItem(USER_KEY));
}
