
const PROPS = {
    job_title: "Título profesional del Autor",
    title: "Título",
    description: "Descripción",
    link: "URL",
    date_from: "fecha 'INICIA'",
    date_to: "fecha 'FINALIZA'",
    show_date: "fecha 'MOSTRAR'",
    hide_date: "fecha 'OCULTAR'",
    order: "orden",
    intention: "Intención",
    country: "país",
    date: "Fecha",
    campaign: "Campaña",
    language: "Lenguaje",
    type: "Tipo",
    moment: "Momento",
    showing_home: "mostrar en Home",
    showing_tab: "mostrar en Escuela de Oración",
    video_url: "URL del video",
    rosary: "Rosario",
    category: "Categoría",
    quantity: "Cantidad",
    array: "",
};

export const humanizeProps = (message) => {
    if(!message) return "Error. Intente luego por favor";
    
    for (let prop in PROPS) {
        message = message.replace(prop, PROPS[prop])
    }

    message = message.replace(/(\[([0-9]+)\]\.)/gi, '');
    message = message.replace(/(ref:)/gi, '');
    return message;
}