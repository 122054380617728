
import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
    wrapper: {
        marginTop: "1.2em",
        paddingRight: "10px",
    },
    wrapperItem: {
        marginRight: "10px",
        display: "flex"
    },
    label: {
        fontSize: "1.3em",
        marginRight: "5px",
        textTransform: "uppercase",
        color: "#d5d5d5"
    },
    status: {
        fontSize: "0.87rem",
        marginRight: ".4em",
        color: "#333",
        letterSpacing: "0.02857em",
        borderRadius: "0.375em",
        backgroundColor: "#a7cd7e",
        padding: "0.2em .8em",
        fontFamily: "Nunito Sans",
        fontWeight: 600,
        lineHeight: "1em",
        textTransform: "none",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    inactive: {
        backgroundColor: "#f2f2f2",
    },
    draft: {
        backgroundColor: "#f2f2f2",
    },
    validated: {
        backgroundColor: "#f3d882",
    },

});
