import moment from "moment";
import YouTubeUrl from 'youtube-url';

const FORMAT = {
  svg: "image/svg+xml",
  jpg: "image/jpeg",
  png: "image/png",
};

export const validateImageFormat = (file) => {
  const validFormat = Object.values(FORMAT);
  const validExtension = Object.keys(FORMAT);
  return validFormat.includes(file.type) && validExtension.includes(file.name.split('.').pop().toLowerCase());
};

export const validateIcon = (file) => {
  return FORMAT.svg === file.type && file.name.split('.').pop().toLowerCase() === "svg";
};

export const validateNumberMinMax = (value, min, max) => {
  if (isNaN(value)) {
    return false;
  } else {
    if (value < min || value > max) return false;
  }
  return true;
};

export const validateLength = (value, max) => {
  return value.length >= max;
};

export const validateIsDate = (date) => {
  return moment(date).isValid();
}
export const youtubeValidUrl = (url) => {
  return YouTubeUrl.valid(url)
}

export const youtubeEmbedUrl = (url) => {
  if (!YouTubeUrl.valid(url)) return '';
  const ID = YouTubeUrl.extractId(url);
  return `https://www.youtube.com/embed/${ID}`;
}
