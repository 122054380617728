import React from "react";
import LANG from "global/lang";
import Grid from "@material-ui/core/Grid";
import { useStyles } from "./styles";

export function LanguageNavStyled(props) {
  const { language, otherLanguages, pathEdit } = props;
  const classes = useStyles();
  const selectedLang = language?.code || language;
  let langNav = LANG.map((lang, key) => {
    let otherLangs = [];
    if (otherLanguages) {
      otherLangs = otherLanguages.filter((ol) =>
        Object.keys(ol).includes(lang.code.toUpperCase())
      );
    }

    if (otherLangs.length > 0) {
      return (
        <a
          href={`${pathEdit}/${Object.values(otherLangs[0])[0]}/${lang.code}`}
          key={key}
          className={classes.link}
        >
          {lang.code.toUpperCase()}
        </a>
      );
    } else {
      return (
        <div
          key={key}
          className={`${classes.label} ${
            selectedLang?.toLowerCase() === lang.code ? classes.current : ""
          }`}
        >
          {String(lang.code)}
        </div>
      );
    }
  });

  return (
    <Grid container justify="center" className={classes.wrapper} data-testid="component_lang_nav">
      {langNav}
    </Grid>
  );
}
