import moment from "moment";
import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import * as Yup from "yup";
import classnames from "classnames";
import Input from "components/Input";
import Button from "components/Button";
import Select from "components/Select";
import LANGS from "global/lang";
import {
  getPrayerById,
  addPrayer,
  editPrayer,
  deleteMultimedia,
  addMultimedias,
  deletePrayer,
} from "services/services";
import { languagesSelectList } from "../PopeProfileContainer";
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { useStyles } from "./styles";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  Grid,
  Tooltip,
  TextField,
} from "@material-ui/core";
import DialogAlert from "../../../components/DialogAlert";
import Delete from "@material-ui/icons/Delete";
import { POPE_EDIT, POPE_PROFILE } from "navigation/CONSTANTS";
import { humanizeProps } from "helpers/humanizeResponse";
import { getUser } from "services/authService";

export function PopePrayerEdit() {
  const { id } = useParams();
  const initialPrayerValue = {
    title: "",
    description: "",
    show_date: moment().toISOString(),
    type: "POP",
    language: 0,
    isValid: false,
    group: ""
  };
  const schemaPrayer = Yup.object().shape({
    title: Yup.string()
      .required()
      .test("required", "es requerido", (val) => !!val?.trim().length),
    description: Yup.string()
      .required()
      .test("required", "es requerido", (val) => !!val?.trim().length),
    language: Yup.string()
      .required()
      .test("required", "es requerido", (val) => String(val).length === 2),
    show_date: Yup.date().required(),
    group: Yup.string()
    .matches(/^[a-zA-Z0-9\s]+$/, {message:"Grupo solamente admite letras y números ", excludeEmptyString:true}).max(50, ' 50 caracteres maximo'),
  });

  const [prayerEdit, setPrayerEdit] = useState({});
  const [prayerLang, setPrayerLang] = useState(0);
  const [saving, setSaving] = useState(false);
  const [multimedia, setMultimedia] = useState({ name: "" });
  const [error, setError] = useState({ msgType: "", message: "", show: false });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });

  const user = getUser();
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (Number(id)) fetchPrayer(id);
    else setPrayerEdit(initialPrayerValue);
  }, [id]);

  const languages = languagesSelectList();
  languages[0].display = "--Selecionar--";
  const isEditing = !!Number(id);
  const isAdmin = user?.role.description === "ADMIN";

  async function fetchPrayer(idPrayer) {
    const res = await getPrayerById(idPrayer);
    if (res) {
      const p = {
        id: res.id,
        title: res.title,
        description: res.description,
        language: res.language.code,
        show_date: moment(res.show_date).toISOString(),
        isValid: true,
        group: res.group,
      };
      setPrayerEdit(p);
      setPrayerLang(res.language.id);
      if (res.multimedia[0]) {
        setMultimedia({
          id: res.multimedia[0].id,
          name: res.multimedia[0].original_name,
          type: res.multimedia[0].type.code,
          url: res.multimedia[0].url,
        });
      }
    }
  }

  function handleBack() {
    history.push(POPE_PROFILE);
  }

  function onChangeForm({ name, value }) {
    const edit = { ...prayerEdit, [name]: value, isValid: true };
    schemaPrayer
      .validate(edit)
      .catch((e) => {
        edit.isValid = false;
        setError({
          message: humanizeProps(`${e.path} ${e.message}`),
          msgType: "msgError",
        });
      })
      .finally(() => {
        setPrayerEdit(edit);
      });
  }

  function onChangeLang(e) {
    const lang = LANGS.find((l) => l.id == e.target.value) || { id: 0, code: "" };
    setPrayerLang(lang.id);
    onChangeForm({ name: "language", value: lang.code });
  }
  const handlePickerChange = (date) => {
    onChangeForm({ name: "show_date", value: moment(date).toISOString() });
  };

  function handlerUploadFile(event) {
    const file = event.target.files[0];
    setMultimedia((prev) => ({ ...prev, name: file.name, file }));
  }

  async function onSavePrayer() {
    const service = isEditing ? editPrayer : addPrayer;
    if (!prayerEdit.isValid) setError((er) => ({ ...er, show: true }));
    else {
      setSaving(true);
      let result = { show: true, message: "Datos guardados" };
      let id_prayer = 0;
      service(prayerEdit)
        .then((data) => {
          id_prayer = isEditing ? data.id : data;
          setError({
            show: true,
            message: "Guardando imagenes... Por favor espere.",
          });
          return multimedia.file || multimedia.delete
            ? saveMultimedias(id_prayer)
            : Promise.resolve();
        })
        .catch((e) => {
          result.message = humanizeProps(e.message);
          result.msgType = "msgError";
        })
        .finally(() => {
          setSaving(false);
          setError(result);
          if (id_prayer) history.push(`${POPE_EDIT}/${id_prayer}`);
        });
    }
  }

  async function saveMultimedias(owner, ownerType = "PRA") {
    const formData = new FormData();
    const metadata = {
      name: multimedia.name,
      type: "IMG",
      owners: [{ owner, ownerType }],
    };

    if (!isEditing && multimedia.file) {
      formData.append(multimedia.name, multimedia.file);
      formData.append("metadata", JSON.stringify([metadata]));

      return addMultimedias(formData);
    } else if (multimedia.file) {
      metadata.replace = multimedia.id;
      formData.append(multimedia.name, multimedia.file);
      formData.append("metadata", JSON.stringify([metadata]));

      return addMultimedias(formData);
    } else if (multimedia.delete) {
      return deleteMultimedia([
        { multimedia_id: multimedia.id, owner, ownerType },
      ]);
    }
  }

  return (
    <div data-testid="modal_pope_prayer">
      <Grid container data-testid="prayerEdit-edit-view">
        {error?.show && (
          <div className={`${classes.messageBox} ${classes[error.msgType]}`}>
            {error.message}
          </div>
        )}
        <Grid item xs={9} style={{ padding: 15 }}>
          <Grid container>
            <Grid item xs={6}>
              <div>
                <h2>Oraciones Especiales del Papa</h2>
                {!isEditing && <h5>Nueva oración</h5>}
              </div>
            </Grid>
            <Grid item xs={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  openTo="month"
                  format="dd-MM-yyyy"
                  label="Mostrar (Fecha)"
                  views={["year", "month", "date"]}
                  value={moment(prayerEdit.show_date).toISOString()}
                  onChange={handlePickerChange}
                  inputVariant="filled"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  name="show_date"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <TimePicker
                  todayLabel="now"
                  label="Mostrar (Hora)"
                  inputVariant="filled"
                  value={moment(prayerEdit.show_date).toISOString()}
                  name="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  minutesStep={5}
                  onChange={handlePickerChange}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>

          <FormControl variant="filled" size="small" margin="normal">
            <InputLabel>Idioma</InputLabel>
            <Select
              items={languages}
              handlerOnChange={onChangeLang}
              defaultValue={prayerLang}
              disabled={!isAdmin}
            />
          </FormControl>
          <Input
            isSucess={!!prayerEdit.title}
            isError={!prayerEdit.title}
            name="title"
            label="Título"
            isRequired
            id="txt_title_community"
            onChange={(e) => onChangeForm(e.target)}
            value={prayerEdit.title || ""}
          />
          <Input
            isSucess={!!prayerEdit.description}
            isError={!prayerEdit.description}
            name="description"
            label="Descripción"
            isRequired
            id="txt_description_popePrayer"
            onChange={(e) => onChangeForm(e.target)}
            value={prayerEdit.description || ""}
            multiline
            rows={10}
          />
          <Input
            // isSucess={!!prayerEdit.group}
            // isError={!prayerEdit.group}
            name="group"
            label="Grupo"
            id="txt_group_popePrayer"
            onChange={(e) => onChangeForm(e.target)}
            value={prayerEdit.group || ""}
          />

          <FormControl variant="filled" size="small" margin="normal" fullWidth>
            <label>Archivo Multimedia</label>
            <TextField
              id="input_multimedia_pope_prayer"
              name="file"
              value={multimedia.name}
              variant="outlined"
              size="small"
              margin="dense"
              fullWidth
              InputProps={{
                readOnly: true,
                classes: {
                  adornedStart: classes.adornedStart,
                  adornedEnd: classes.adornedEnd,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <label htmlFor="input_file">
                      <Button
                        variant="contained"
                        component="span"
                        text="Subir archivo"
                        css={classes.btnInput}
                      />
                    </label>
                  </InputAdornment>
                ),
                endAdornment:
                  multimedia.name && isAdmin ? (
                    <InputAdornment position="end">
                      <Tooltip title="Eliminar">
                        <Button
                          onClick={() =>
                            setMultimedia({
                              ...multimedia,
                              name: "",
                              delete: true,
                              file: null,
                            })
                          }
                          id="btn_quitar_multimedia_popPrayer"
                          data-testid="btn_quitar_multimedia_popPrayer"
                          variant="text"
                          color="secondary"
                          icon={<Delete />}
                          css={classnames(classes.btnInput, classes.btnQube)}
                        />
                      </Tooltip>
                    </InputAdornment>
                  ) : null,
              }}
            />
          </FormControl>
          <input
            accept=".png, .jpg, .svg"
            id="input_file"
            name="input_file"
            hidden
            type="file"
            onChange={(event) => {
              handlerUploadFile(event);
              event.target.value = "";
            }}
          />
        </Grid>
        <Grid item xs={3} style={{ padding: "30px 0" }}>
          <Button
            text={!saving ? "Guardar" : "Procesando...."}
            disabled={saving}
            type="primary"
            onClick={onSavePrayer}
            style={{ marginRight: 10 }}
            data-testid="btn-save-prayerEdit-edit"
          />
          <Button
            text="Volver"
            onClick={handleBack}
            id="btn_back_prayerEdit"
            data-testid="btn_back_prayerEdit"
            variant="outlined"
          />
          {isEditing && isAdmin && (
            <Button
              text="Eliminar"
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: "¿Desea eliminar este registro?",
                  subtitle: "Eliminar",
                  onConfirm: () => {
                    deletePrayer(id);
                    history.push(POPE_PROFILE);
                  },
                });
              }}
              id="btn_del_popePrayer"
              data-testid="btn_del_popePrayer"
              variant="text"
              color="secondary"
              icon={<Delete />}
              style={{ float: "right" }}
            />
          )}
        </Grid>
        <DialogAlert
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
      </Grid>
    </div>
  );
}
