import http from "./interceptor";

const request = ({
  url = "",
  method = "GET",
  headers = {},
  params = {},
  data = {},
}) => {
  const comHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };
  switch (method) {
    case "GET":
      return http.get(url, { headers: comHeaders, params, data });
    case "POST":
      return http.post(url, data, { headers: comHeaders, params });
    case "PUT":
      return http.put(url, data, { headers: comHeaders, params });
    case "DELETE":
      return http.delete(url, { headers: comHeaders, params, data });
    default:
      break;
  }
};

export default request;
