import makeStyles from "@material-ui/core/styles/makeStyles";
// import BackgroundProfile from "assets/img/background_pope_profile.svg";

export const useStyles = makeStyles({
  profileContainer: (props) => {
    let style = {
      height: "100%",
      // backgroundPosition: "center top",
      backgroundColor: "#F4F6F8",
      // backgroundImage: `url(${BackgroundProfile})`,
      // backgroundSize: "cover",
      // backgroundRepeat: "no-repeat",
      padding: "216px 108px 125px",
    };

    if (props.mediaQueryLg) {
      style = {
        ...style,
        padding: "216px 90px 125px",
      };
    }
    if (props.mediaQueryMd) {
      style = {
        ...style,
        padding: "88px 20px 48px",
      };
    }
    return style;
  },
  profile: (props) => {
    let style = {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      flexDirection: "row",
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      };
    }
    return style;
  },
  profileContentUser: (props) => {
    let style = {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      flexDirection: "column",
      width: "100%",
      maxWidth: "500px",
      "&>div:first-child": {
        height: "151px",
      },
    };
    if (props.mediaQueryLg) {
      style = {
        ...style,
        maxWidth: "400px",
      };
    }
    if (props.mediaQueryMd) {
      style = {
        ...style,
        maxWidth: "100%",
        alignItems: "center",
        justifyContent: "center",
      };
    }
    return style;
  },
  form: () => {
    let style = {
      width: "100%",
    };

    return style;
  },
  profileForm: (props) => {
    let style = {
      padding: "78px 0px 0px 128px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
    };
    if (props.mediaQueryLg) {
      style = {
        ...style,
        padding: "78px 0px 0px 90px",
      };
    }
    return style;
  },
  profileInputs: () => {
    let style = {
      width: "100%",
      margin: "0px 0px 16px 0px",
    };

    return style;
  },
  profileInputsLeft: (props) => {
    let style = {
      width: "100%",
      margin: "0px 16px 16px 0px",
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        margin: "0px 0px 16px 0px",
      };
    }
    return style;
  },
  profileContainerForm: () => {
    let style = {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      flexDirection: "column",
      width: "100%",
    };

    return style;
  },
  profileContainerFormInput: (props) => {
    let style = {
      width: "100%",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      flexDirection: "row",
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        flexDirection: "column",
      };
    }
    return style;
  },
  input: (props) => {
    let style = {
      margin: "0px 16px 0px 0px",
    };
    if (props.mediaQueryLg) {
      style = {
        ...style,
        margin: 0,
      };
    }
    return style;
  },
  select: () => {
    let style = {
      border: "1px solid #DAE3EC",
      borderRadius: "6px",
      backgroundColor: "white",
      "&>div:first-child": {
        "& button:first-child": {
          padding: "0px 0px 0px 13px",
        },
        "& button:last-child": {
          padding: "13.50px 16px",
          "&>span": {
            "&>div": {
              height: "21px",
              width: "100%",
            },
          },
        },
      },
    };

    return style;
  },
  label: {
    margin: "0px 0px 8px 0px",
    "&>label": {
      fontFamily: "Nunito Sans",
      fontSize: "14px",
      color: "#444C67",
      fontWeight: 400,
      "&>span": {
        color: "red",
      },
    },
  },
  profileAvatar: {
    width: "151px",
    height: "151px",
  },
  profileUser: (props) => {
    let style = {
      "&>h1": {
        fontFamily: "Libre Baskerville",
        fontWeight: "normal",
        fontSize: "44px",
        lineHeight: "56px",
        color: "#444C67",
        margin: "48px 0px 16px 0px",
        textAlign: "start",
      },
      "&>p": {
        display: "block",
        fontFamily: "Nunito Sans",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#444C67",
        margin: "0px 0px 56px 0px",
      },
    };
    if (props.mediaQueryMd) {
      style["&>h1"] = {
        ...style["&>h1"],
        fontSize: "28px",
        lineHeight: "40px",
        margin: "16px 0px 48px 0px",
        textAlign: "center",
      };
      style["&>p"] = {
        display: "none",
      };
    }

    return style;
  },
  profileButton: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: "0px 0px 48px 0px",
    "&>button": {
      "&:first-child": {

      },
      "&>span": {
        "&>div": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        },
      },
    },
  },
  buttonCloseAccount: {
    margin: "16px 0px 0px",
  },
  buttonUpdate: (props) => {
    if (props.mediaQueryMd) {
      return {
        margin: 0,
      };
    } else {
      return {
        margin: "32px 0px 0px",
      };
    }
  },
  buttonLogOutAccount: (props) => {
    if (props.mediaQueryMd) {
      return {
        margin: "56px 0px 0px",
      };
    }
  },
  contentAccordion: {
    background: "transparent",
    boxShadow: "0px -1px 0px #E7ECF0",
  },
  accordionFormUser: {
    margin: "0px 0px 16px",
  },
  contentSumary: {
    "& h1": {
      fontFamily: "Nunito Sans",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "24px",
      color: " #444C67",
      margin: 0,
    },
  },
  contentDetails: {
    "& form": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
  },
  "@keyframes addImage": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  "@global": {
    "@keyframes addImage": {
      "0%": {
        opacity: 0,
      },
      "100%": {
        opacity: 1,
      },
    },
  },
  visibilityAddImage: (props) => {
    let style = {
      top: "292px",
      position: "absolute",
      background: "rgba(218, 227, 236, 0.9)",
      width: "151px",
      height: "75.5px",
      borderRadius: "0 0 100px 100px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      animation: "addImage 0.5s ease",
      visibility: "visible",
      cursor: "pointer",
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,

        top: "165px",
      };
    }
    return style;
  },
  hiddenAddImage: {
    visibility: "hidden",
  },
  buttonContent: (props) => {
    let style = {};
    if (props.mediaQueryMd) {
      style = {
        ...style,
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      };
    }
    return style;
  },
  passFormat: {
    fontSize: ".8em",
    margin: "0px 0px 10px 0px",

  },
});
