import makeStyles from "@material-ui/core/styles/makeStyles";
export const useStyles = makeStyles({
  button: {
    margin: "0.2rem",
    "&:last-child": {
      margin: "1rem",
      float: "right",
    },
  },
  msgBox: {
    width: "70%",
    padding: "5px 15px",
    borderRadius: "5px",
    backgroundColor: "#d4edda",
    color: "#155724",
    margin: "5px 25px 0 0",
    position: "relative",
    float: "right",
  },
  msgError: {
    backgroundColor: "rgb(253, 236, 234)",
    color: "rgb(136, 37, 30)",
  },
  msgClose: {
    position: "absolute",
    right: "10px",
    top: "5px",
    cursor: "pointer",
    padding: "0 10px "
  },
});
