import { humanizeProps } from "helpers/humanizeResponse";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  editCampaign,
  addMultimedias,
  deleteCampaignById,
  deleteCampaignPrayer,
  deleteCampaignTip,
  getCampaignById,
  upsertCampaignPrayers,
  upsertCampaignTips,
  deleteMultimedia,
} from "services/services";
import { CAMPAIGN } from "../../../navigation/CONSTANTS";
import CampaignEditView from "./CampaignEditView";

export function CampaignEditContainer({ location }) {
  const { id } = useParams();
  const [campaign, setCampaign] = useState({});
  const [message, setMessage] = useState({
    text: location.state?.message ? location.state.message : "",
  });
  const history = useHistory();

  useEffect(() => {
    if (id) fetchCampaign(id);
  }, [id]);

  function fetchCampaign(campaignId) {
    getCampaignById(campaignId)
      .then((data) => {
        if (!data || data.group == null)
          return history.push(CAMPAIGN, {
            message: "Problema al cargar la información",
          });
        data.moment = data.moment.code;
        setCampaign(data);
      })
      .catch(() =>
        history.push(CAMPAIGN, { message: "Problema al cargar la información" })
      );
  }

  function saveItem(campItem) {
    let oItem = {
      id: campItem.id,
      title: campItem.title || "",
      description: campItem.description,
      date: (campaign.type.id === 1 || campaign.type.id === 4 || campaign.type.id === 2 ||campaign.type.id === 3 ) ? campItem.date : undefined,
      order: campaign.type.id !== 1 ? campItem.order : undefined,
      hashtag: campItem.hashtag || "",
      campaign: campaign.id,
      //multimedia: campItem.multimedia,
      language: campaign.language.code,
    };
    if (campItem.id) {
      delete oItem.language;
    }

    if (campaign.type.id === 3) {
      upsertCampaignTips(oItem)
        .then((savedItem) => {
          let msg = "Item guardado";
          if (!oItem?.id) {
            updateItem(savedItem[0], campItem);
          }
          if (campItem.multimedia[0]?.newFile) {
            msg = msg + ". Subiendo imagen...";
            saveImage(campItem.multimedia[0], [
              { owner: campItem.id || savedItem[0], ownerType: "tip" },
            ]);
          }
          setMessage({
            text: msg,
          });
        })
        .catch((err) =>
          setMessage({
            text: humanizeProps(err.response?.data?.message),
            msgType: "msgError",
          })
        );
    } else {
      upsertCampaignPrayers(oItem)
        .then((response) => {
          if (response.length > 0) {
            let msg = "Item guardado";
            if (!oItem?.id) {
              updateItem(response[0], campItem);
            }
            if (campItem.multimedia[0]?.newFile && !isNaN(response[0])) {
              msg = msg + ". Subiendo imagen...";
              saveImage(campItem.multimedia[0], [
                { owner: response[0], ownerType: "cap" },
              ]);
            }
            setMessage({
              text: msg,
            });
          }
        })
        .catch((err) =>
          setMessage({
            text: humanizeProps(err.response?.data?.message),
            msgType: "msgError",
          })
        );
    }
  }

  function updateItem(itemId, item) {
    item.id = itemId;
    setCampaign({ ...campaign, campaignPrayer: [...campaign.campaignPrayer, item] });
  }

  async function saveCampaign(editedCampaign) {
    if (editedCampaign?.id) {
      const dataCampaign = {
        ...editedCampaign,
        type: editedCampaign.type.code,
        //language: editedCampaign.language.code,
        link: editedCampaign.link || "",
      };
      if (editedCampaign.type.id !== 1) {
        delete dataCampaign.date_to;
        delete dataCampaign.date_from;
      }
      delete dataCampaign.language;
      delete dataCampaign.group;
      if (campaign.showing_home === editedCampaign.showing_home) delete dataCampaign.showing_home;
      if (campaign.showing_tab === editedCampaign.showing_tab) delete dataCampaign.showing_tab;

      let campaignArr = [];
      let campaignMultimediaOwners = [];

      //Update common fields for all langs
      if (dataCampaign.otherLanguages.length > 0) {
        dataCampaign.otherLanguages.forEach((otherLang) => {
          const otherId = Object.values(otherLang)[0];
          let otherCampaign = {
            id: otherId,
            type: dataCampaign.type,
            moment: dataCampaign.moment,
            date_from: dataCampaign.date_from,
            date_to: dataCampaign.date_to,
            show_date: dataCampaign.show_date,
            hide_date: dataCampaign.hide_date
          }
          if ("showing_home" in dataCampaign) otherCampaign.showing_home = dataCampaign.showing_home;
          if ("showing_tab" in dataCampaign) otherCampaign.showing_tab = dataCampaign.showing_tab;

          campaignArr.push(otherCampaign);
          if (dataCampaign.multimedia[0]?.newFile) {
            campaignMultimediaOwners.push({
              owner: otherId,
              ownerType: "cam",
            });
          }
        });
      }
      if (dataCampaign.multimedia[0]?.newFile) {
        campaignMultimediaOwners.push({
          owner: dataCampaign.id,
          ownerType: "cam",
        });
      }
      campaignArr.unshift(dataCampaign);
      let reqArr = [editCampaign(campaignArr)];

      dataCampaign.campaignPrayer.forEach((campItem) => {
        if (campItem.delete) {
          if (campItem?.id) {
            if (dataCampaign.type === "TIP") {
              reqArr.push(deleteCampaignTip(campItem.id));
            } else {
              reqArr.push(deleteCampaignPrayer(campItem.id));
            }
          }
        }
      });

      Promise.all(reqArr)
        .then((responses) => {
          let msgResponse = { text: "Datos guardados exitosamente" };
          if (!responses[0] || responses[0]?.length < 1) {
            msgResponse = {
              text: "Error. Intente luego por favor",
              msgType: "msgError",
            };
          } else {
            if (dataCampaign.multimedia[0]?.newFile) {
              msgResponse = { text: "Datos procesados. Subiendo imagen..." };
              saveImage(dataCampaign.multimedia[0], campaignMultimediaOwners);
            } else {
              fetchCampaign(id);
            }
          }

          //Deleted items
          if (responses?.length > 1) {
            for (let i = 1; i <= responses.length - 1; i++) {
              if (!responses[i] || !responses[i]?.id) {
                msgResponse = {
                  text: "Error al eliminar items. Intente luego por favor",
                  msgType: "msgError",
                };
                break;
              }
            }
          }

          setMessage(msgResponse);
        })
        .catch((err) => {
          let errorShowing = err?.error === "showing_home" || err?.error === "showing_tab" ? err.error.split("_")[1] : "";
          setMessage({
            text: errorShowing ? `Hay muchos elementos en ${errorShowing}. Quite alguno para poder activar este.` : humanizeProps(err.response?.data?.message),
            msgType: "msgError",
          });
        }
        );
    }
  }

  function saveImage(multimedia, owners) {
    const formData = new FormData();
    const metadatas = [];
    const fileName = multimedia.newFile.file.name;
    formData.append(fileName, multimedia.newFile.file);
    let oMetadata = {
      name: fileName,
      type: multimedia.newFile.type,
      owners: owners,
    };

    //Replace current multimedia
    if (multimedia?.id) {
      oMetadata.replace = multimedia.id;
    }
    metadatas.push(oMetadata);
    formData.append("metadata", JSON.stringify(metadatas));
    addMultimedias(formData)
      .then((response) => {
        if (response.inserted) {
          setMessage({ text: "Proceso completado correctamente" });
        } else {
          setMessage({
            text: "Error al subir imagen.",
            msgType: "msgError",
          });
        }
        fetchCampaign(id);
      })
      .catch((err) =>
        setMessage({ text: humanizeProps(err.response?.data?.message), msgType: "msgError" })
      );
  }

  function deleteCampaign(idCamp) {
    if (idCamp) {
      deleteCampaignById(idCamp)
        .then((res) => {
          history.push("/campaign", {
            message: `Borrado exitoso de ${res.title}`,
          });
        })
        .catch((err) =>
          setMessage({
            text: humanizeProps(err.response?.data?.message),
            msgType: "msgError",
          })
        );
    }
  }

  function deleteImage(multimedia, owner) {
    if (multimedia && owner) {
      deleteMultimedia([{ "multimedia_id": multimedia, "owner": owner, "ownerType": "CAM" }])
        .then(() => {
          setMessage({
            text: "Imagen eliminada",          
          })
          
          setCampaign({...campaign, multimedia: []});
        })
        .catch((err) =>
          setMessage({
            text: humanizeProps(err.response?.data?.message),
            msgType: "msgError",
          })
        );
    }
  }

  function handleBack() {
    history.push("/campaign");
  }

  return (
    <div style={{ padding: 20 }}>
      <CampaignEditView
        oldcampaign={campaign}
        saveCampaign={saveCampaign}
        handleBack={handleBack}
        saveImage={saveImage}
        message={message}
        deleteCampaign={deleteCampaign}
        saveItem={saveItem}
        deleteImage={deleteImage}
      />
    </div>
  );
}
