import React from "react";
import {Avatar, Grid} from "@material-ui/core";
import PropTypes from "prop-types";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Icon from "components/Icon";
import { useStyles } from "./styles";
import LogoSimple from "assets/img/Logo_simple.svg";
import {BLOG, ROOT, CAMPAIGN, ROSARY, POPE_PROFILE, MEDIATION, SCHOOL, USER} from "../../navigation/CONSTANTS";
import {useSelector} from 'react-redux'
import { useHistory } from "react-router";

export function HeaderStyled(props) {
  const { mode, handleLogin } = props;
  const mediaQuerySm = useMediaQuery("(max-width:960px)");
  const classes = useStyles({ mode, mediaQuerySm: mediaQuerySm });
   const user = useSelector(store=>store.auth.user)
  const history=useHistory()
  return (
    <Grid
      data-testid="header"
      container
      className={`${classes.mode} ${classes.container}`}
    >
      <Grid xs={8} item>
        <a href="/" className={classes.logo}>
          <Icon icon={LogoSimple} alt="Logo"  />
        </a>
       {user && <nav>
          <ul>
            <li>
              <a href={ROOT}>Home</a>
            </li>
            {user?.role?.description==='ADMIN'&&
            <>
            <li>
              <a href={BLOG}>Blog</a>
            </li>
            <li>
              <a href={CAMPAIGN}>Campañas</a>
            </li>
            <li>
              <a href={ROSARY}>Rosarios</a>
            </li>
            <li>
              <a href={POPE_PROFILE}>Perfil del Papa</a>
            </li>
            <li>
              <a href={MEDIATION}>Mediaciones</a>
            </li>
            <li>
              <a href={SCHOOL}>Tarjetas Escuela</a>
            </li>
            <li>
              <a href={USER}>Usuarios</a>
            </li>
            </>}
          </ul>
        </nav>}
      </Grid>
      <Grid xs={4} item >
        <Grid container justify="flex-end"  alignItems="center">
          {user ? (
            <div className={classes.avatar} onClick={()=>history.push('/profile')}>
                 <p data-testid="txt_role">
              {user?.person?.first_name}
            </p>
               <Avatar src={user?.avatar} />
            </div>

          ) : (
            <p data-testid="button-login"
               onClick={handleLogin}>
              Acceder
            </p>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

HeaderStyled.propTypes = {
  //mode: PropTypes.string.isRequired,
  avatar: PropTypes.string,
};
