export const ROOT = "/";
export const DEFAULT = "*";
export const BLOG = "/blog";
export const BLOG_EDIT = "/blog/edit";
export const BLOG_ADD = "/blog/add";
export const CAMPAIGN = "/campaign";
export const CAMPAIGN_EDIT = "/campaign/edit";
export const CAMPAIGN_ADD = "/campaign/add";
export const ROSARY = "/rosary";
export const ROSARY_ADD = "/rosary/add";
export const ROSARY_EDIT = "/rosary/edit";
export const PROFILE = "/profile";
export const POPE_PROFILE = "/pope";
export const POPE_EDIT = "/pope/edit";
export const MEDIATION = "/mediation";
export const MEDIATION_EDIT = "/mediation/edit";
export const SCHOOL = "/school";
export const USER = "/user";
export const USER_ADD = "/useradd";
export const TRANSLATOR = "/translator";
