import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "../../../components/Button";
import { useStyles } from "./styles";
import Delete from "@material-ui/icons/Delete";
import moment from "moment";

export default function MediationEditView(props) {
  const { handleBack, message, reported, type = "", handleSave } = props;
  const [currentReported, setCurrentReported] = useState({});
  const [saving, setSaving] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    if (reported) {
      setCurrentReported(reported);
    }
  }, [reported]);
  useEffect(() => {
    if (message) {
      setSaving(false);
    }
  }, [message]);

  const handleDelete = () => {
    handleSave(reported.id, {
      deleted: true,
      reported_type: type,
      status: "unresolved",
    });
  };

  const handleStatus = () => {
    handleSave(reported.id, { status: "resolved", reported_type: type });
  };

  return (
    <div>
      <Grid
        container
        spacing={4}
        className={classes.wrapper}
        data-testid="mediate-edit-view"
      >
        <Grid item xs={8}>
          <h2>Reporte de: {type === "comment" ? Number(reported.prayer_type) === 1 || Number(reported.prayer_type) === 8
            ? "Comentario de Perfil del Papa "
            : "Comentario de Comunidad" : "Oración de Comunidad"}</h2>
          <div>
            <label className={classes.label}>TITULO:</label>{" "}
            {currentReported?.prayer
              ? currentReported?.prayer.title
              : currentReported?.title}
          </div>
          <div>
            <label className={classes.label}>DESCRIPCION:</label>{" "}
            {currentReported?.description}{" "}
            <Button
              text="Eliminar"
              onClick={handleDelete}
              id="btn_del_mediate"
              data-testid="btn_del_mediate"
              variant="text"
              color="secondary"
              icon={<Delete />}
            />
          </div>
          <div>
            <label className={classes.label}>Creado:</label>{" "}
            {moment(currentReported?.created_at).format("DD-MM-YYYY HH:mm")}
          </div>
          <div>
            <label className={classes.label}>Escrito por:</label>{" "}
             {currentReported?.user?.person?.first_name}{" "}
            {currentReported?.user?.person?.last_name} (id: {currentReported?.user?.id})
            <div className={classes.txt}>{currentReported?.user?.email}</div>
          </div>
        </Grid>
        <Grid item xs={4} style={{ backgroundColor: "#F4F6F8" }}>
          <Button
            text={!saving ? "Marcar como revisado" : "Procesando...."}
            disabled={saving}
            type="primary"
            onClick={handleStatus}
            style={{ marginRight: 10 }}
            data-testid="btn-save-mediate-edit"
          />
          <Button
            text="Volver"
            onClick={handleBack}
            id="btn_back_mediate"
            data-testid="btn_back_mediate"
            variant="outlined"
          />
          <div>
            <label className={classes.label}>Reportado:</label>
            {moment(currentReported?.reported_at).format("DD-MM-YYYY HH:mm")}
          </div>

          <div>
            <label className={classes.label}>Denunciado por:</label>
            {currentReported?.reported_by?.person?.first_name}{" "}
            {currentReported?.reported_by?.person?.last_name} (id: {currentReported?.reported_by?.id})
            <div className={classes.txt}>{currentReported?.reported_by?.email}</div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
