import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import { Modal } from "@material-ui/core";
import MaterialTable from "material-table";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Search from "@material-ui/icons/Search";
import LastPage from "@material-ui/icons/LastPage";
import FirstPage from "@material-ui/icons/FirstPage";
import FilterList from "@material-ui/icons/FilterList";
import Clear from "@material-ui/icons/Clear";
import { getPrayersBOList } from "../../services/services";

export function SearchCampaignStyled(props) {
  const { search, handleSearchClose, selectItem } = props;
  const tableIcons = {
    Edit: EditIcon,
    Delete: DeleteIcon,
    SortArrow: KeyboardArrowUpIcon,
    Add: AddCircleIcon,
    PreviousPage: ChevronLeft,
    NextPage: ChevronRight,
    Search: Search,
    LastPage: LastPage,
    FirstPage: FirstPage,
    Filter: FilterList,
    Clear: Clear,
  };
  const types = {SCH: "Calendarizada", UNS: "No Calendarizada" , PRO: "Proyecto", TIP: "Consejo", ROS: "Rosario", ROT: "Campaña Especial"}
  const [list, setList] = useState();
  const classes = useStyles();
  useEffect(() => {
    fetchCampaignList(search.lang, "");
  }, [search.lang]);

  function fetchCampaignList(lang, type) {
    getPrayersBOList(lang, type)
      .then(async (res) => {
        setList(res);
      })
      .catch(console.warn);
  }

  return (
    <>
      <Modal
        open={search.open}
        onClose={handleSearchClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modalBox}>
          <MaterialTable
            title=""
            icons={tableIcons}
            columns={[
              { title: "Id", field: "id", hidden: true },
              { title: "TITULO", field: "title" },
              {
                title: "TIPO",
                field: "type.code",
                width: "8%",
                lookup: types,
              },
              { title: "MOSTRAR", field: "show_date", width: "4%" },
              { title: "OCULTAR", field: "hide_date", width: "4%" },
              { title: "INICIA", field: "date_from", width: "4%" },
              { title: "FINALIZA", field: "date_to", width: "4%" },
            ]}
            data={list}
            actions={[
              {
                icon: tableIcons.Add,
                onClick: (event, rowData) => selectItem(rowData),
              },
            ]}
            options={{
              search: false,
              toolbar: false,
              filtering: true,
              pageSize: 10,
              headerStyle: {
                backgroundColor: "#596386",
                color: "#FFF",
                padding: "5px 10px",
                fontSize: ".8em"
              },
              filterCellStyle: { padding: "2px", fontSize: ".4em" },
              doubleHorizontalScroll: true,
              cellStyle: {
                fontSize: ".9em",
                padding: "1px",
              },
            }}
            localization={{
              header: {
                actions: "AGREGAR",
              },
            }}
          />
        </div>
      </Modal>
    </>
  );
}
