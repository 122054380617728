import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  hide: {
    display: 'none',
  },
  cropper: {
    maxHeight: '300px',
  },
  btnUpload:{
    marginBottom: '5px',
    backgroundColor: "blueviolet"
  },
  box: {
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'inherit',
    height: '100%',
  },
  line: {
    margin: '15px 0px 15px 0px',
  },
  avatar: {
    width: '300px',
    height: '280px',
    overflow: "hidden",
    border: "1px solid grey"
  },
});
