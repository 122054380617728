import makeStyles from "@material-ui/core/styles/makeStyles";
export const useStyles = makeStyles({
  formContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between  ",
    alignItems: "start",
    height: "100%",
    "& >label": {
      marginBottom: "5px",
      "&:last-child": {
        marginBottom: 0,
      },
    },
  },
  contentButtonSubmit: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "20px",
  },
  buttons: {
    marginBottom: "20px",
    "&:last-child": {
      margin: 0,
    },
  },
  contentMultimedia: {
    padding: "20px",
  },
  image: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "10px",
    "& img": {
      width: "100px",
      height: "100px",
    },
  },
  contentImage: {
    display: "flex",
    height: "100%",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  contentButtonLabel: {
    display: "flex",
    flexDirection: "column",
  },
});
