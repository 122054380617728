import React from "react";
import Translator from "./TranslatorView";

export function TranslatorContainer() {

  return (
    <>
      <Translator />
    </>
  );
}
