import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  signUpFormContent: (props) => {
    let style = {
      width: "792px",
      outline: "none",
      height: "auto",
    };
    if (props.mediaQueryLg) {
      style = {
        ...style,
        height: "600px",
      };
    }
    if (props.mediaQueryMd) {
      style = {
        ...style,
        width: "100%",
        height: "100%",
      };
    }
    return style;
  },

  wrapperSignUpForm: (props) => {
    let style = {
      margin: "24px 122px 0px 122px",
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        margin: "0px",
      };
    }
    return style;
  },
  titleContent: (props) => {
    let style = {
      textAlign: "center",
      "&>h1": {
        fontFamily: "Libre Baskerville",
        fontWeight: "normal",
        fontSize: "32px",
        color: "#444C67",
        margin: "0px 0px 24px 0px",
      },
      "&>p": {
        fontFamily: "Nunito Sans",
        fontSize: "16px",
        color: "#5D688E",
        margin: "0px 0px  32px 0px",
      },
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
        margin: "24px 0px 0px 0px",
      };
    }
    return style;
  },
  inputs: (props) => {
    let style = {
      margin: "0px 16px 0px 0px",
    };
    if (props.mediaQueryMd) {
      style = {
        margin: 0,
      };
    }
    return style;
  },
  rowInput: {
    margin: "0px 0px 16px 0px ",
  },
  gridForm: {
    alignItems: "flex-start",
  },
  contentRegister: (props) => {
    let style = {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      "&>button:first-child": {
        margin: "0px 0px 16px 0px",
        "&>span": {
          "&>div": {
            "&>span": {
              "&>span": {
                textDecoration: "underline",
                margin: "0px 0px 0px 4px",
              },
            },
          },
        },
      },
      "&>button:last-child": {
        margin: "24px 0px 0px 0px",
        "&>span": {
          "&>div": {
            "&>span": {
              "&>span": {
                textDecoration: "underline",
                margin: "0px 0px 0px 4px",
              },
            },
          },
        },
      },
    };
    if (props.mediaQueryMd) {
      style = {
        ...style,
      };
    }
    return style;
  },
  select: () => {
    let style = {
      border: "1px solid #DAE3EC",
      borderRadius: "6px",
      "&>div:first-child": {
        "& button:first-child": {
          padding: "0px 0px 0px 13px",
        },
        "& button:last-child": {
          padding: "13.50px 16px",
          "&>span": {
            "&>div": {
              height: "21px",
            },
          },
        },
      },
    };

    return style;
  },
  label: {
    margin: "0px 0px 8px 0px",
    fontFamily: "Nunito Sans",
    fontSize: "14px",
    color: "#444C67",
    fontWeight: 400,
    "&>span": {
      color: "red",
    },

  },
  labelChecks: {
    margin: "0 10px 0 0",
    "&>input": {
      margin: "0 1px 0 0"
    },
  },
  error: {
    "&>p": {
      margin: "6px 0px 0px 16px",
      color: "#ECA25E",
      fontFamily: "Mulish",
      fontWeight: "bold",
      fontSize: "12px",
    },
  },
  sucess: {
    "&>p": {
      margin: "6px 0px 0px 16px",
      color: "#71AF7B",
      fontFamily: "Mulish",
      fontWeight: "bold",
      fontSize: "12px",
    },
  },
  optionsCountry: {
    overflowY: "hidden",
  },
  messageBox: {
    padding: "5px 15px",
    borderRadius: "5px",
    backgroundColor: "#d4edda",
    color: "#155724",
  },
  msgError: {
    backgroundColor: "rgb(253, 236, 234)",
    color: "rgb(136, 37, 30)",
  },
});
