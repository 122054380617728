import makeStyles from "@material-ui/core/styles/makeStyles";
export const useStyles = makeStyles({
  contentHome: {
    margin: "auto",
    width: "100%",
    height: "100%",
  },
  welcomeMessage:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    textAlign:'center',
    flexDirection:'column',
    width: "100%",
    height: "100vh",
    '&>h1':{
        fontFamily: "Libre Baskerville",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "44px",
        color: " #444C67",
        margin: '20px 0px 0px',
    }
  },
  logo:{
    width:'20%',
    height:'20%'
  }
});
