import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import BlogEditView from "../../../components/Blog";
import { addPost, getPostById, associateMultimedia } from "services/services";
import moment from "moment";
import { BLOG, BLOG_EDIT } from "../../../navigation/CONSTANTS";
import { humanizeProps } from "helpers/humanizeResponse";

export function BlogAddContainer() {
  const { lang, id } = useParams();
  const history = useHistory();
  const [message, setMessage] = useState({});
  const [basePost, setBasePost] = useState({});

  useEffect(() => {
    if (id) fetchPost(id);
  }, [id]);

  //Get base post (ES)
  function fetchPost(postId) {
    getPostById(postId, "es")
      .then((data) => {
        if (!data) return history.push(BLOG);
        delete data.id;
        delete data.title;
        delete data.body;
        delete data.blockquote;
        data.otherLanguages.push({"ES": postId});
        data.language = { code: lang };
        setBasePost(data);
      })
      .catch(() => history.push(BLOG));
  }

  function savePost(article) {
    if (article.publish_date) {
      article = {
        ...article,
        publish_date: moment(article.publish_date).format("YYYY-MM-DD"),
      };
    } else {
      delete article.publish_date;
    }
    article = {
      ...article,
      language: lang,
    };

    addPost(article)
      .then((newId) => {
          history.push(`${BLOG_EDIT}/${newId}/${article.language}`, {
            message: "ARTICULO CREADO",
          });
          copyMultimedia(newId, article.multimedia);
      })
      .catch((err) =>
        setMessage({
          text: humanizeProps(err?.message ? err?.message : err.response?.message ? err.response?.message : undefined),
          msgType: "msgError",
        })
      );
  }

  function copyMultimedia(idOwner, arrMultimedia) {
    if (arrMultimedia.length > 0) {
      let arrNew = arrMultimedia.map((multimedia) => {
        return {
          multimedia_id: multimedia.id,
          owners: [ {owner: idOwner, ownerType: "POS"}]
        }
      });
      associateMultimedia(arrNew)
        .then((total) => {
          console.log(total);
        })
        .catch(console.warn);
    }
  }

  function handleBackBlog() {
    history.push("/blog");
  }

  return (
    <div style={{ padding: 20 }}>
      <BlogEditView
        post={basePost}
        savePost={savePost}
        handleBackBlog={handleBackBlog}
        message={message}
        lang={lang}
      />
    </div>
  );
}
