import makeStyles from "@material-ui/core/styles/makeStyles";
export const useStyles = makeStyles({
  grids: {
    width: '100%',
    backgroundColor: "white",
    border: '1px solid #999',
    borderCollapse: 'collapse',
    tableLayout: "fixed",
    '& td': {
      border: '1px solid #E0E0E0',
      width: "10%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    '& th': {
      border: '1px solid #E0E0E0',
      fontSize: '.9em',
    }
  },
  partial: {
    textAlign: "right",
    paddingRight: "10px",
  },
  rootChip: {
    background: "#4292E0",
    color: "white",
    fontFamily: " Nunito Sans",
  },
  paper: {
    position: "absolute",
    width: "max-content",
    backgroundColor: "white",
    border: "1px solid #000",
    padding: "0.5em",
    top: "30%",
    left: "50%",
    transform: "translate(-40%, -40%)",
    display: "flex",
    flexDirection: "column",
    height: "max-content",
    maxHeight: "90vh",
    minWidth: "50%",
    maxWidth: "90vw",
    flexWrap: "wrap"
  },
  active: {
    color: "#4292E0",
    cursor: "pointer"
  },
  media: {
    color: "#8d9295",
    cursor: "pointer"
  },
  hide: { display: "none" },
  btnUpload: {
    padding: "7px 10px",
    margin: 7,
  },
  messageBox: {
    width: "50%",
    padding: "5px 15px",
    borderRadius: "5px",
    backgroundColor: "#d4edda",
    color: "#155724",
    position: "sticky",
    top: "5px",
    right: 0,
    margin: "0"
  },
  msgError: {
    backgroundColor: "rgb(253, 236, 234)",
    color: "rgb(136, 37, 30)",
  },
});
