import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  associateMultimedia,
  editCampaign,
  getBaseCampaign,
} from "services/services";
import { CAMPAIGN, CAMPAIGN_EDIT } from "../../../navigation/CONSTANTS";
import CampaignAddView from "./CampaignAddView";
import CAMPAIGN_TYPES from "global/types_campaign";
import { humanizeProps } from "helpers/humanizeResponse";

export function CampaignAddContainer({ location }) {
  const { type, lang, groupId } = useParams();
  const [message, setMessage] = useState({
    text: location.state?.message || "",
  });
  const [groupData, setGroupData] = useState({});
  const history = useHistory();

  useEffect(() => {
    if (groupId) fetchBaseCampaign(groupId);
  }, [groupId]);

  const typeCode = CAMPAIGN_TYPES.find((typeCamp) => typeCamp.code === type);

  function fetchBaseCampaign(grId) {
    getBaseCampaign(grId)
      .then((response) => {
        //Get campaigns for other languages
        if (response.data.length > 0) {
          response.data[0].otherLanguages = response.data[0]?.otherLanguages
            ? [...response.data[0].otherLanguages, { ES: response.data[0].id }]
            : [{ ES: response.data[0].id }];
          setGroupData(response.data[0]);
        }
      })
      .catch(console.warn);
  }

  function saveCampaign(campaign) {
    if (campaign.type !== "SCH") {
      delete campaign.date_to;
      delete campaign.date_from;
    }

    editCampaign([campaign])
      .then(async (res) => {
        setMessage({ text: "Datos guardados exitosamente" });
        if (campaign.multimedia.length > 0) {
          await copyMultimedia(res[0], campaign.multimedia[0].id);
        }
        history.push(`${CAMPAIGN_EDIT}/${res[0]}`, {
          message: "Campaña creada",
        });
      })
      .catch((err) =>
        setMessage({
          text: humanizeProps(err.response?.data?.message),
          msgType: "msgError",
        })
      );
  }

  function copyMultimedia(id, idMedia) {
    let arrNew = {
      multimedia_id: idMedia,
      owners: [{ owner: id, ownerType: "CAM" }],
    };
    associateMultimedia([arrNew])
      .then()
      .catch(console.warn);
  }

  function handleBack() {
    history.push(CAMPAIGN);
  }

  return (
    <div style={{ padding: 20 }}>
      <CampaignAddView
        type={typeCode}
        lang={lang}
        groupId={groupId}
        saveCampaign={saveCampaign}
        handleBack={handleBack}
        message={message}
        groupData={groupData}
      />
    </div>
  );
}
