import React, {useState} from "react";
import LANG from "global/lang";
import Grid from "@material-ui/core/Grid";
import { useStyles } from './styles';

export function ColumnSelectorStyled(props) {
    const {
        handlerOnChange,
    } = props;

    const [checkedState, setCheckedState] = useState(
        new Array(LANG.length).fill(true)
    );
    const classes = useStyles();
    const handleOnChangeStatus = (e, position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);
        handlerOnChange(updatedCheckedState);
    };

    return (
        <Grid container justify="flex-end">
            {LANG && LANG.map((lang,key)=>
                <label key={key} className={classes.label}>
                    <input key={key}
                       type="checkbox"
                       checked={checkedState[key]}
                       onChange={(e) => handleOnChangeStatus(e, key)}
                    /> {String(lang.code)}
                </label>
            )}
        </Grid>
    );
}
